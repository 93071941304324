import React, { useState } from 'react';
import { Layout} from 'antd';
import { Switch, Route, Redirect } from 'react-router-dom';

import Routes from '../routing/routes';
import PublicRoute from '../routing/PublicRoute';
import PrivateRoute from '../routing/PrivateRoute';
import Sidebar from "../components_new/sidebar";
import AppHeader from '../components_new/header';
import authStore from '../store/authStore';

import Dashboard from './dashboard';
import constants from '../config/constants'
import Login from './login';
import TermsService from '../pages/terms-service';
import Password from "../pages/password";
import ReminderAlert from '../pages/reminder-alert';
// import PasswordReset from '../components/passwordresetmodal';
// import Systems from './systems/Systems' 
import SystemsDetail from './systems/Systems-detail';
import Users from '../pages/users/index'
import inspections from '../pages/inspections/inspection-checklist/index'
import inspecionForm from '../pages/inspections/inspection-form/index'
import DeviceStatus from '../pages/device-status';
import IssueTracker from './issue-tracker-listing';
import ReportGenerator from './report-generator-listing';
import SoftSavings from './softsavings'
import IssueTrackerDetails from './issue-tracker-details';
import sensorData from '../pages/sensorData';
import sensorGroup from '../pages/group/Groups'
import Settings from '../pages/settings';
import Roles from '../pages/settings/role/Role';
import systemConfig from '../pages/settings/system-config/SystemConfig';
import PinConfig from '../pages/settings/pin-config/PinConfiguration';
import Organizations from '../pages/settings/organisation/Organisation';
import AlgorithmConfigs from '../pages/settings/algorithm-config/AlgorithmConfig';
import sensorRawData from '../pages/sensorRawData';
import soapSensorData from '../pages/soapSensorData';

const { Content , Footer } = Layout;

const MainComponent = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const toggleCollapseHandler = () => setIsCollapsed(prev => !prev);

  const redirectRoutes = () => {
      if(constants.Permissions['Dashboard/Assets & Details'].map((p: any) => p.permission).some((p :any)=> authStore?.currentUser?.access?.includes(p))){
        return (
          <Redirect to={Routes.dashboard} />
        )
      }else if(constants.Permissions['Dashboard/Assets & Details'].map((p: any) => p.permission).some((p :any)=> authStore?.currentUser?.access?.includes(p))){
        return (
          <Redirect to={Routes.systems} />
        )
      }
      else if(constants.Permissions['Raw Data'].map((p: any) => p.permission).some((p :any)=> authStore?.currentUser?.access?.includes(p))){
        return (
          <Redirect to={Routes.sensorrawdata} />
        )
      }
      else if(constants.Permissions['Field Inspection'].map((p: any) => p.permission).some((p :any)=> authStore.currentUser?.access?.includes(p))){
        return (
          <Redirect to={Routes.inspectionchecklist} />
        )
      }else if(constants.Permissions['System Status'].map((p: any) => p.permission).some((p :any)=> authStore.currentUser?.access?.includes(p))){
        return (
          <Redirect to={Routes.devicestatus} />
        )
      }else if(constants.Permissions['Users'].map((p: any) => p.permission).some((p :any)=> authStore.currentUser?.access?.includes(p))){
        return (
          <Redirect to={Routes.users} />
        )
      }else if(constants.Permissions['SOAP'].map((p: any) => p.permission).some((p :any)=> authStore.currentUser?.access?.includes(p))){
        return (
          <Redirect to={Routes.soapSensorData} />
        )
      }
      else if(constants.Permissions['Settings'].map((p: any) => p.permission).some((p :any)=> authStore.currentUser?.access?.includes(p))){
        return (
          <Redirect to={Routes.role} />
        )
      }
  }


  return (
    <Layout
    style={{ minHeight: '100vh' }}
    >
      <Sidebar collapsed={isCollapsed} />
      <Layout className="site-layout overflow-hidden bg-white" >
        <AppHeader collapseToggle={toggleCollapseHandler} collapsed={isCollapsed} />
        <Content className="site-layout-background overflow-y-auto overflow-x-hidden">
          <Switch>
            <Route path={Routes.home} exact>
              {redirectRoutes}
            </Route>
            <Route path={Routes.dashboard} component={Dashboard} exact />
            <Route path={Routes.systems} component={sensorData} exact />
            <Route path={Routes.systemsdetails} component={SystemsDetail} exact />
            <Route path={Routes.users} component={Users}  exact/>
            <Route path={Routes.inspectionchecklist} component={inspections}  exact/>
            <Route path={Routes.inspectionform} component={inspecionForm}  exact/>
            <Route path={Routes.devicestatus} component={DeviceStatus}  exact/>
            <Route path={Routes.issuetracker} component={IssueTracker}  exact/>
            <Route path={Routes.reportGenerator} component={ReportGenerator}  exact/>
            <Route path={Routes.issuetrackerdetails} component={IssueTrackerDetails}  exact/>
            <Route path={Routes.groups} component={sensorGroup}  exact/>
            <Route path={Routes.settings} component={Settings}  exact/>
            <Route path={Routes.role} component={Roles}  exact/>
            <Route path={Routes.systemconfig} component={systemConfig}  exact/>
            <Route path={Routes.pinconfig} component={PinConfig}  exact/>
            <Route path={Routes.softsavings} component={SoftSavings}  exact/>
            <Route path={Routes.organization} component={Organizations}  exact/>
            <Route path={Routes.algorithmconfig} component={AlgorithmConfigs}  exact/>
            <Route path={Routes.sensorrawdata} component={sensorRawData}  exact/>
            <Route path={Routes.soapSensorData} component={soapSensorData}  exact/>
          </Switch>
        </Content>
        <Footer className="text-center copyright-text py-3 bg-white">Copyright © 2022 HVACIntel Corporation</Footer>
      </Layout>
    </Layout>
  )
}

const AppHomeComponent = () => {
  return (
    <Switch>
      <PublicRoute path={Routes.login} component={Login} restricted exact />
      <PublicRoute path={Routes.termsservice} component={TermsService} />
      <PublicRoute path={Routes.password} component={Password}/>
      <PublicRoute path={Routes.reminderalert} component={ReminderAlert}/>
      <PrivateRoute path={Routes.home} component={MainComponent} />
    </Switch>      
  );
}

export default AppHomeComponent;
