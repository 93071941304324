import Api from '../api'
import constants from '../config/constants'
import authStore from '../store/authStore'
import userStore from '../store/userStore'

class SensorService {

    async getAllSensorBoxes(currentPage : number, search :number ){
        let response = await  Api.search(`/sensorboxes/` +
                                        `parent/${this.getParentId()}/?`+
                                        `skip=${(currentPage - 1) * constants.maxLimit}&`+
                                        `limit=${constants.maxLimit}&`+
                                        `filter=${JSON.stringify({search})}`)
            return response.data
    }  

    getParentId(){
 
        if(authStore.currentUser.role === constants.role["Contractor"]){
            return authStore.currentUser.uid    //Contractor can see users under him, Parent id will be his id
        }else if(authStore.currentUser.role > constants.role["Contractor"]){
            return userStore.profileDetials ? userStore.profileDetials.assigned_to?._id : '' //Field Engineer can see users under contractor in which he is assigned to, Parent id 
        }else{
            return ' '
        }

    }


    async sendReport(sensorBoxName : string, startDate? : Date, endDate? : Date ,orgId? : any ){

        let response = await Api.get(`/report/sensorbox?sensorboxname=${sensorBoxName}` +
                                        `${startDate ? '&startDate='+ startDate.getTime() : ' '}` +
                                        `${endDate ? '&endDate='+  endDate.getTime() : ' '} &orgid=${orgId}`)
        return response.data.message
    }

    async sendGroupReport(groupId : string, startDate? : Date, endDate? : Date,orgId? : any){

        let response = await Api.get(`/report/group?groupid=${groupId}` +
                                        `${startDate ? '&startDate='+ startDate.getTime() : ' '}` +
                                        `${endDate ? '&endDate='+  endDate.getTime() : ' '} &orgid=${orgId}`)
        return response.data.message
    }
    
    async getSensorBoxes(currentPage? : number, maxLimit? : number,contractor?:any, searchFilter? : string, deviceSort?: string, statusSort?: string, orgid? : string, system_id ? : any , status ? : any, show_deleted? : boolean, deployment_status? : any){
        let searchText = ''
        if(searchFilter) {
            searchText = encodeURIComponent(searchFilter)
        }
        let queryParams = `/sensorboxes-list-new?page=${currentPage}&limit=${maxLimit}&deviceSort=${deviceSort}&statusSort=${statusSort}&filter=${JSON.stringify({search : searchText})}&show_deleted=${show_deleted}`
        if(contractor){
            queryParams += `&contractor=${contractor._id}`
        }
        if(orgid){
            queryParams += `&orgid=${orgid}`
        }

        if(system_id){
            queryParams += `&system_id=${system_id}`
        }

        if(status){
            queryParams += `&status=${status}`
        }

        if(deployment_status){
            queryParams += `&deployment_status=${deployment_status}`
        }

        let response = await Api.cancelPendingAndGet(queryParams)                           
        return response.data
    }
async getSensorBoxesForDemo(currentPage? : number, maxLimit? : number,contractor?:any, searchFilter? : string, deviceSort?: string, statusSort?: string, orgid? : string, system_id ? : any , status ? : any, show_deleted? : boolean, deployment_status? : any){
        let searchText = ''
        if(searchFilter) {
            searchText = encodeURIComponent(searchFilter)
        }
        let queryParams = `/sensorboxes-list-demo?page=${currentPage}&limit=${maxLimit}&deviceSort=${deviceSort}&statusSort=${statusSort}&filter=${JSON.stringify({search : searchText})}&show_deleted=${show_deleted}`
        if(contractor){
            queryParams += `&contractor=${contractor._id}`
        }
        if(orgid){
            queryParams += `&orgid=${orgid}`
        }

        if(system_id){
            queryParams += `&system_id=${system_id}`
        }

        if(status){
            queryParams += `&status=${status}`
        }

        if(deployment_status){
            queryParams += `&deployment_status=${deployment_status}`
        }

        let response = await Api.cancelPendingAndGet(queryParams)
        return response.data
    }

    async getSensorBoxNames(){
        let response = await Api.get(`/sensorboxnames/${this.getParentId()}`)
        return response.data
    }

    async checkSensorBoxExist(id:string,sensorBoxName:string) {       
        let response = await Api.get(`/checksensorboxname/${id}/${sensorBoxName}`)
        return response.data.result
    }
   
    
    async enableSensorBox(id:string){
        let response = await Api.post(`/sensorboxes/${id}`,{id}) 
        return response.data.message
    }

    async moveSensorboxToOtherOrganization(datas? : any){
        let response = await Api.post(`/move-sensorbox/${datas?.id}`,datas);
        return response.data.message
    }

    async getGraphData(sensorBoxName : string, startDate? : Date, endDate? : Date, chunkLastTime?: string | null, version? : Number){
        let response = await Api.get(`/graph/sensorbox?sensorboxname=${sensorBoxName}&` +
                                        `startDate=${startDate ? startDate.getTime():''}&` +
                                        `endDate=${endDate ? endDate.getTime() : ''}&` +
                                        `lastTime=${chunkLastTime ? chunkLastTime : ''}&` +
                                        `version=${version ? version : '0'}`)
         return response.data && response.data.records ? response.data.records : []
    }

    getSensorRawData = async (sensor_name: string, orgid? : string) => {
        console.log(sensor_name !== '')
        let queryParams = `/rawsensordata?` + ( sensor_name !== ''? `sensor_name=${sensor_name}` : '')

        if(orgid){
            queryParams += `&orgid=${orgid}`
        }

        const response = await Api.get(queryParams)
        return response.data.result
    }

    async getSensorBoxConfigHistory(sensorBoxName:string) {       
        let response = await Api.get(`/sensorbox-history/${sensorBoxName}`)
        return response.data.result
    }
    async getSensorBoxConfigHistoryForAsset(asset_id:string) {
        let response = await Api.get(`/sensorbox-history-group/${asset_id}`)
        return response.data.result
    }

    async getAlertLogs(sensorbox_name:string,pageNumer:Number,limit:Number){
        let response = await Api.get(`/alert-logs/${sensorbox_name}?pageNumber=${pageNumer}&limit=${limit}&type=health-status`)
        return response
    }

    async getLatestAlertLogs(sensorbox_name:string){
        let response = await Api.get(`/latest-alert-logs/${sensorbox_name}?type=health-status`)
        return response
    }

    async getDeviceOfflineLogs(sensorbox_name:string,pageNumer:Number,limit:Number){
        let response = await Api.get(`/alert-logs/${sensorbox_name}?pageNumber=${pageNumer}&limit=${limit}&type=offline-alert-status`)
        return response
    }

    async getValidRangeLogs(sensorbox_name:string,pageNumer:Number,limit:Number){
        let response = await Api.get(`/alert-logs/${sensorbox_name}?pageNumber=${pageNumer}&limit=${limit}&type=invalid-range`)
        return response
    }

    async getGroupAlertLogs(sensorbox_group:string,pageNumer:Number,limit:Number){
        let response = await Api.get(`/group-alert-logs/${sensorbox_group}?pageNumber=${pageNumer}&limit=${limit}&type=health-status`)
        return response
    }

    async getGroupDeviceOfflineLogs(sensorbox_group:string,pageNumer:Number,limit:Number){
        let response = await Api.get(`/group-alert-logs/${sensorbox_group}?pageNumber=${pageNumer}&limit=${limit}&type=offline-alert-status`)
        return response
    }

    async getDeviceConfigLog(sensorbox_name: string, sno?: string) {
        let response = await Api.get(`/device-config-logs/${sensorbox_name}?sno=${sno}`);
        return response;
    }

    async setOfflineReminder(params : any) {
        let response = await Api.post('/set-offline-reminder',params);
        return response;
    }

    async getPowerGraphData(id : any, frequencyType? : any, systemType? : any){
        let response = await Api.get(`/graph/power-system?systemId=${id}&` +
                                    `type=${systemType}&` +
                                    `frequencyType=${frequencyType}`)
        return response.data ? response.data : []
    }

    async getWeather(lat : any, lon? : any){
        let response = await Api.get(`/get-weatherinfo?lat=${lat}&` +
                                    `lon=${lon}`)
        return response.data ? response.data : []
    }
    async getPowerConsumptionData(id : any, systemType : any, startDate:any, endDate:any,){
        let response = await Api.get(`/graph/power-consumption-data?systemId=${id}&` +
            `type=${systemType}&` +
            `startDate=${startDate.getTime()}&`+
            `endDate=${endDate.getTime()}`
        )
        return response.data ? response.data : []
    }

    async getRev7aComponentsTypesMapping(){
        let response = await Api.get(`/getr7atypescomponentsmapping`)
        return response.data ? response.data : []
    }
}

const sensorService = new SensorService()
export default sensorService