import React, { Component } from "react";
import ReactDOM from "react-dom"
import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";
import 'antd/dist/antd.less';
import Routes from "./pages/Routes";
import AppHome from "./pages/AppHome";
import ReminderAlert from './pages/reminder-alert';
import Login from './pages/home';
import Services from './pages/services';
import Dashboard from './pages/dashboard';
import Users from './pages/users';
import SensorData from './pages/sensorData';
import soapSensorData from "./pages/soapSensorData";
import ContactUs from "./pages/contactUs";
import TermsService from "./pages/terms-service";
import Password from "./pages/password";
import authStore from './store/authStore';
import './commonStyles.css';
import './assets/Less/hvac.less'
import userService from './services/userService';
import userStore from './store/userStore';
import toast from './components/toast'
import eventStore from "./store/eventStore";
import sensorService from "./services/sensorService";
import sensorRawData from "./pages/sensorRawData";
import settings from './pages/settings';
import CacheBuster from './components/hoc/CacheBuster';
import Groups from './pages/group/Groups';
import DeviceStatus from './pages/device-status/index';
import InspectionChecklist from './pages/inspections/inspection-checklist/index';
import InspectionForm from './pages/inspections/inspection-form/index'
import constants from './config/constants';

import AppRouter from './screens/AppHome'

interface AppState {

  user  : object;
 }

interface AppProps { }

class App extends Component<AppProps, AppState> {


  state = {
    user  : {} as any
  };

  componentDidMount() {

    authStore.onAuthStateChanged( async(user : any) => {
      if(user){
        await eventStore.connectEventSource()
        let profileDetails = sessionStorage.getItem("profile")
        if(profileDetails){
          userStore.profileDetials = JSON.parse(profileDetails)
        }else{
          userStore.profileDetials = await this.getProfileDetails()
        }
      }else{
        
        sessionStorage.removeItem('profile')
      }
      this.setState({user})
    });
  }

  isAccessible(permissions: Array<number>) {
    const { user } = this.state;
    return permissions.some(p => user?.access?.includes(p))
}

  async getSensorBoxes(){
 
    let sensorboxes = await sensorService.getSensorBoxNames()
    let sensor_box_name = sensorboxes.result.map((sensorbox : any)=>{
      return sensorbox.sensor_box_name
    })
 
    return sensor_box_name.toString()

  }

  redirectRoutes() {
    const { user } = this.state;
      if(constants.Permissions['Dashboard/Assets & Details'].map((p: any) => p.permission).some((p :any)=> user?.access?.includes(p))){
        return (
          <Redirect to={Routes.dashboard} />
        )
      }else if(constants.Permissions['System Status'].map((p: any) => p.permission).some((p :any)=> user?.access?.includes(p))){
        return (
          <Redirect to={Routes.devicestatus} />
        )
      }
      else if(constants.Permissions['Field Inspection'].map((p: any) => p.permission).some((p :any)=> user?.access?.includes(p))){
        return (
          <Redirect to={Routes.inspectionchecklist} />
        )
      }
      else if(constants.Permissions['Users'].map((p: any) => p.permission).some((p :any)=> user?.access?.includes(p))){
        return (
          <Redirect to={Routes.users} />
        )
      }else if(constants.Permissions['Dashboard/Assets & Details'].map((p: any) => p.permission).some((p :any)=> user?.access?.includes(p))){
        return (
          <Redirect to={Routes.sensordata} />
        )
      }else if(constants.Permissions['Raw Data'].map((p: any) => p.permission).some((p :any)=> user?.access?.includes(p))){
        return (
          <Redirect to={Routes.sensorrawdata} />
        )
      }else if(constants.Permissions['Settings'].map((p: any) => p.permission).some((p :any)=> user?.access?.includes(p))){
        return (
          <Redirect to={Routes.settings} />
        )
      }else if(constants.Permissions['SOAP'].map((p: any) => p.permission).some((p :any)=> user?.access?.includes(p))){
        return (
          <Redirect to={Routes.soapSensorData} />
        )        
    }
  }

  async getProfileDetails() {

    try{    

        let profileDetails = await userService.getProfileDetails()
        sessionStorage.setItem("profile",JSON.stringify(profileDetails))
        return profileDetails
    }catch(error){
        toast.error(error.response.data.message)
    }
}


  _renderInitialComponent = () => {

     if(this.state.user && this.state.user?.access){
     return (
      <AppHome>
        <Switch>
          {
            this.isAccessible(constants.Permissions['Dashboard/Assets & Details'].map((p: any) => p.permission)) &&
            <Route path={Routes.dashboard} component={Dashboard} />
          }
          {
            this.isAccessible(constants.Permissions['Dashboard/Assets & Details'].map((p: any) => p.permission)) &&
            <Route path={Routes.groups} component={Groups} />
          }
          {
            this.isAccessible(constants.Permissions['System Status'].map((p: any) => p.permission)) &&
            <Route path={Routes.devicestatus} component={DeviceStatus} />
          }
          {
            this.isAccessible(constants.Permissions['Field Inspection'].map((p: any) => p.permission)) &&
            <Route path={Routes.inspectionchecklist} component={InspectionChecklist} />
          }
          {
            this.isAccessible(constants.Permissions['Users'].map((p: any) => p.permission)) &&
            <Route path={Routes.users} component={Users} />
          }
          {
            this.isAccessible(constants.Permissions['Dashboard/Assets & Details'].map((p: any) => p.permission)) &&
            <Route path={Routes.sensordata} component={SensorData} />
          }
          {
            this.isAccessible(constants.Permissions['Raw Data'].map((p: any) => p.permission)) &&
            <Route path={Routes.sensorrawdata} component={sensorRawData} />
          }
          {
            this.isAccessible(constants.Permissions['SOAP'].map((p: any) => p.permission)) &&
            <Route path={Routes.soapSensorData} component={soapSensorData} />            
          }
          {
           this.isAccessible(constants.Permissions['Settings'].map((p: any) => p.permission)) &&
            <Route path={Routes.settings} component={settings} />
          }
            <Route path={Routes.inspectionform} component={InspectionForm} />
          {this.redirectRoutes()}
        </Switch>
      </AppHome>
      )
     }else {
      return (
        <AppHome>
          <Switch>
            <Route path={Routes.home} component={Login} exact />
            {/* <Route path={Routes.login} component={Login} exact /> */}
            <Route path={Routes.services} component={Services} />
            <Route path={Routes.contactus} component={ContactUs} />
            <Route path={Routes.password} component={Password}/>
            <Route path={Routes.reminderalert} component={ReminderAlert}/>
            <Route path={Routes.termsservice} component={TermsService}/>
            <Redirect to={Routes.home} />
          </Switch>
        </AppHome>
      )
     }

      // if(this.state.user && this.state.user.role){

      //   if(this.state.user.role === 4){
      //     return (
      //       <AppHome>
      //         <Switch>
      //           <Route path={Routes.sensordata} component={SensorData} />
      //           <Redirect to={Routes.sensordata} />
      //         </Switch>
      //       </AppHome>
      //     )
      //   }else{
      //     return (
      //       <AppHome>
      //         <Switch>
      //           <Route path={Routes.dashboard} component={Dashboard} />
      //           <Route path={Routes.groups} component={Groups} />
      //           <Route path={Routes.users} component={Users} />
      //           <Route path={Routes.sensordata} component={SensorData} />
      //           {
      //             this.state.user.role === 1 &&
      //             <Route path={Routes.sensorrawdata} component={sensorRawData} />
      //           }
      //           {
      //             this.state.user.role === 1 &&
      //             <Route path={Routes.settings} component={settings} />
      //           }
      //           <Redirect to={Routes.dashboard} />
      //         </Switch>
      //       </AppHome>
      //     )
      //   }

      // }else{

        
      //   return (
      //     <AppHome>
      //       <Switch>
      //         <Route path={Routes.home} component={Home} exact />
      //         <Route path={Routes.services} component={Services} />
      //         <Route path={Routes.contactus} component={ContactUs} />
      //         <Route path={Routes.password} component={Password}/>
      //         <Redirect to={Routes.home} />
      //       </Switch>
      //     </AppHome>
      //   )
     
      // }
   
  }

  render() {

    return (  
      <CacheBuster>
        {({ loading, isLatestVersion }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            console.log('Clearing cache and hard reloading...')
            if (caches) {
              // Service worker cache should be cleared with caches.delete()
              caches.keys().then(function(names) {
                for (let name of names) caches.delete(name);
              });
            }
            // delete browser cache and hard reload
            window.location.reload(true);
          }

          return (
            // <div className="container-scroller h-100">
            //   <BrowserRouter>
            //     <Fragment>
            //       {this._renderInitialComponent()}
            //     </Fragment>
            //   </BrowserRouter>
            // </div>

            <BrowserRouter>
              <AppRouter />
            </BrowserRouter>

          );
        }}
      </CacheBuster>
    );

 
  }
}



const RootDiv = document.getElementById("root");

ReactDOM.render(<App />, RootDiv);