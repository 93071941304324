import React, {Fragment, PureComponent, useEffect, useState} from "react";
import BoardDiagram from 'react-img-mapper';

import pinImage from "../../assets/rev7apins.png";
import onBoardSensorsImage from "../../assets/on_board_sensors.png";
import './Rev7aSensorsTab.css'
import _ from "lodash";
import {Button} from "antd";
import {PORT_TYPE} from "../../config/constants";

const PINS: any[] = [
    {
        name: "port1_pin1",
        title: "1",
        shape: "circle",
        coords: [39, 50, 10],
        preFillColor: "white",
        center: [39, 50],
        href: "#/",
        port: 1,
        pin: 1
    },
    {
        name: "port1_pin2",
        title: "2",
        shape: "circle",
        coords: [63, 50, 10],
        preFillColor: "white",
        center: [63, 50],
        href: "#/",
        port: 1,
        pin: 2
    },
    {
        name: "port1_pin3",
        title: "3",
        shape: "circle",
        coords: [86, 50, 10],
        preFillColor: "white",
        center: [86, 50],
        href: "#/",
        port: 1,
        pin: 3
    },
    {
        name: "port1_pin4",
        title: "4",
        shape: "circle",
        coords: [109, 50, 10],
        preFillColor: "white",
        center: [109, 50],
        href: "#/",
        port: 1,
        pin: 4
    },
    {
        name: "port1_pin5",
        title: "5",
        shape: "circle",
        coords: [39, 22, 10],
        preFillColor: "white",
        center: [39, 22],
        href: "#/",
        port: 1,
        pin: 5
    },
    {
        name: "port1_pin6",
        title: "6",
        shape: "circle",
        coords: [63, 22, 10],
        preFillColor: "white",
        center: [63, 22],
        href: "#/",
        port: 1,
        pin: 6
    },
    {
        name: "port1_pin7",
        title: "7",
        shape: "circle",
        coords: [86, 22, 10],
        preFillColor: "grey",
        center: [86, 22],
        href: "#/",
        port: 1,
        pin: 7,
        disabled: true
    },
    {
        name: "port1_pin8",
        title: "8",
        shape: "circle",
        coords: [109, 22, 10],
        preFillColor: "grey",
        center: [109, 22],
        href: "#/",
        port: 1,
        pin: 8,
        disabled: true
    },

    {
        name: "port2_pin1",
        title: "DC",
        shape: "rect",
        coords: [150, 20, 240, 40],
        preFillColor: "white",
        center: [193, 30],
        href: "#/",
        port: 2,
        pin: 1
    },
    {
        name: "port2_pin2",
        title: "RH",
        shape: "rect",
        coords: [150, 44, 240, 64],
        preFillColor: "white",
        center: [193, 54],
        href: "#/",
        port: 2,
        pin: 2
    }
    , {
        name: "port3_pin1",
        title: "1",
        shape: "circle",
        coords: [279, 50, 10],
        preFillColor: "white",
        center: [279, 50],
        href: "#/",
        port: 3,
        pin: 1
    },
    {
        name: "port3_pin2",
        title: "2",
        shape: "circle",
        coords: [303, 50, 10],
        preFillColor: "white",
        center: [303, 50],
        href: "#/",
        port: 3,
        pin: 2
    },
    {
        name: "port3_pin3",
        title: "3",
        shape: "circle",
        coords: [327, 50, 10],
        preFillColor: "white",
        center: [327, 50],
        href: "#/",
        port: 3,
        pin: 3
    },
    {
        name: "port3_pin4",
        title: "4",
        shape: "circle",
        coords: [351, 50, 10],
        preFillColor: "white",
        center: [351, 50],
        href: "#/",
        port: 3,
        pin: 4
    },

    {
        name: "port4_pin1",
        title: "1",
        shape: "circle",
        coords: [400, 50, 10],
        preFillColor: "white",
        center: [400, 50],
        href: "#/",
        port: 4,
        pin: 1
    },
    {
        name: "port4_pin2",
        title: "2",
        shape: "circle",
        coords: [424, 50, 10],
        preFillColor: "white",
        center: [424, 50],
        href: "#/",
        port: 4,
        pin: 2
    },
    {
        name: "port4_pin3",
        title: "3",
        shape: "circle",
        coords: [448, 50, 10],
        preFillColor: "white",
        center: [448, 50],
        href: "#/",
        port: 4,
        pin: 3
    },
    {
        name: "port4_pin4",
        title: "4",
        shape: "circle",
        coords: [472, 50, 10],
        preFillColor: "white",
        center: [472, 50],
        href: "#/",
        port: 4,
        pin: 4
    },

    {
        name: "port5_pin4",
        title: "1",
        shape: "circle",
        coords: [521, 50, 10],
        preFillColor: "white",
        center: [521, 50],
        href: "#/",
        port: 5,
        pin: 1
    },
    {
        name: "port5_pin4",
        title: "2",
        shape: "circle",
        coords: [545, 50, 10],
        preFillColor: "white",
        center: [545, 50],
        href: "#/",
        port: 5,
        pin: 2
    },
    {
        name: "port5_pin4",
        title: "3",
        shape: "circle",
        coords: [569, 50, 10],
        preFillColor: "white",
        center: [569, 50],
        href: "#/",
        port: 5,
        pin: 3
    },
    {
        name: "port5_pin4",
        title: "4",
        shape: "circle",
        coords: [593, 50, 10],
        preFillColor: "white",
        center: [593, 50],
        href: "#/",
        port: 5,
        pin: 4
    },

    {
        name: "port6_pin1",
        title: "1",
        shape: "circle",
        coords: [642, 50, 10],
        preFillColor: "white",
        center: [642, 50],
        href: "#/",
        port: 6,
        pin: 1
    },
    {
        name: "port6_pin1",
        title: "2",
        shape: "circle",
        coords: [664, 50, 10],
        preFillColor: "white",
        center: [664, 50],
        href: "#/",
        port: 6,
        pin: 2
    },
    {
        name: "port6_pin1",
        title: "3",
        shape: "circle",
        coords: [688, 50, 10],
        preFillColor: "white",
        center: [688, 50],
        href: "#/",
        port: 6,
        pin: 3
    },
    {
        name: "port6_pin1",
        title: "4",
        shape: "circle",
        coords: [712, 50, 10],
        preFillColor: "white",
        center: [712, 50],
        href: "#/",
        port: 6,
        pin: 4
    },

    {
        name: "port7_pin1",
        title: "1",
        shape: "circle",
        coords: [39, 260, 10],
        preFillColor: "gray",
        center: [39, 260],
        href: "#/",
        port: 7,
        pin: 1,
        disabled: true
    },
    {
        name: "port7_pin2",
        title: "2",
        shape: "circle",
        coords: [63, 260, 10],
        preFillColor: "gray",
        center: [63, 260],
        href: "#/",
        port: 7,
        pin: 2,
        disabled: true
    },
    {
        name: "port7_pin3",
        title: "3",
        shape: "circle",
        coords: [86, 260, 10],
        preFillColor: "gray",
        center: [86, 260],
        href: "#/",
        port: 7,
        pin: 3,
        disabled: true
    },
    {
        name: "port7_pin4",
        title: "4",
        shape: "circle",
        coords: [109, 260, 10],
        preFillColor: "gray",
        center: [109, 260],
        href: "#/",
        port: 7,
        pin: 4,
        disabled: true
    },
    {
        name: "port7_pin5",
        title: "5",
        shape: "circle",
        coords: [39, 288, 10],
        preFillColor: "gray",
        center: [39, 288],
        href: "#/",
        port: 7,
        pin: 5,
        disabled: true
    },
    {
        name: "port7_pin6",
        title: "6",
        shape: "circle",
        coords: [63, 288, 10],
        preFillColor: "gray",
        center: [63, 288],
        href: "#/",
        port: 7,
        pin: 6,
        disabled: true
    },
    {
        name: "port7_pin7",
        title: "7",
        shape: "circle",
        coords: [86, 288, 10],
        preFillColor: "white",
        center: [86, 288],
        href: "#/",
        port: 7,
        pin: 7,
    },
    {
        name: "port7_pin8",
        title: "8",
        shape: "circle",
        coords: [109, 288, 10],
        preFillColor: "white",
        center: [109, 288],
        href: "#/",
        port: 7,
        pin: 8,
    },
    //
    // { name: "port8_pin1", title: "1", shape: "circle", coords: [158, 285, 10], preFillColor: "white", center: [158, 285], href: "#/" },
    // { name: "port8_pin2", title: "2", shape: "circle", coords: [182, 285, 10], preFillColor: "white", center: [182, 285], href: "#/" },
    // { name: "port8_pin3", title: "3", shape: "circle", coords: [206, 285, 10], preFillColor: "white", center: [206, 285], href: "#/" },
    // { name: "port8_pin4", title: "4", shape: "circle", coords: [230, 285, 10], preFillColor: "white", center: [230, 285], href: "#/" },
    // { name: "port8_pin4", title: "4", shape: "circle", coords: [230, 285, 10], preFillColor: "white", center: [230, 285], href: "#/" },
    // { name: "port8_pin4", title: "4", shape: "circle", coords: [230, 285, 10], preFillColor: "white", center: [230, 285], href: "#/" },
    // { name: "port8_pin7", title: "7", shape: "circle", coords: [207, 285, 10], preFillColor: "white", center: [207, 285], href: "#/", port: 8, pin:7 },
    // { name: "port8_pin8", title: "8", shape: "circle", coords: [231, 285, 10], preFillColor: "white", center: [231, 285], href: "#/", port: 8, pin:8 },

    {
        name: "port9_pin1",
        title: "1",
        shape: "circle",
        coords: [279, 260, 10],
        preFillColor: "white",
        center: [279, 260],
        href: "#/",
        port: 9,
        pin: 1
    },
    {
        name: "port9_pin2",
        title: "2",
        shape: "circle",
        coords: [303, 260, 10],
        preFillColor: "white",
        center: [303, 260],
        href: "#/",
        port: 9,
        pin: 2
    },
    {
        name: "port9_pin3",
        title: "3",
        shape: "circle",
        coords: [327, 260, 10],
        preFillColor: "white",
        center: [327, 260],
        href: "#/",
        port: 9,
        pin: 3
    },
    {
        name: "port9_pin4",
        title: "4",
        shape: "circle",
        coords: [351, 260, 10],
        preFillColor: "white",
        center: [351, 260],
        href: "#/",
        port: 9,
        pin: 4
    },

    {
        name: "port10_pin1",
        title: "1",
        shape: "circle",
        coords: [400, 260, 10],
        preFillColor: "white",
        center: [400, 260],
        href: "#/",
        port: 10,
        pin: 1
    },
    {
        name: "port10_pin2",
        title: "2",
        shape: "circle",
        coords: [424, 260, 10],
        preFillColor: "white",
        center: [424, 260],
        href: "#/",
        port: 10,
        pin: 2
    },
    {
        name: "port10_pin3",
        title: "3",
        shape: "circle",
        coords: [448, 260, 10],
        preFillColor: "white",
        center: [448, 260],
        href: "#/",
        port: 10,
        pin: 3
    },
    {
        name: "port10_pin4",
        title: "4",
        shape: "circle",
        coords: [472, 260, 10],
        preFillColor: "white",
        center: [472, 260],
        href: "#/",
        port: 10,
        pin: 4
    },

    {
        name: "port11_pin4",
        title: "1",
        shape: "circle",
        coords: [521, 260, 10],
        preFillColor: "white",
        center: [521, 260],
        href: "#/",
        port: 11,
        pin: 1
    },
    {
        name: "port11_pin4",
        title: "2",
        shape: "circle",
        coords: [545, 260, 10],
        preFillColor: "white",
        center: [545, 260],
        href: "#/",
        port: 11,
        pin: 2
    },
    {
        name: "port11_pin4",
        title: "3",
        shape: "circle",
        coords: [569, 260, 10],
        preFillColor: "white",
        center: [569, 260],
        href: "#/",
        port: 11,
        pin: 3
    },
    {
        name: "port11_pin4",
        title: "4",
        shape: "circle",
        coords: [593, 260, 10],
        preFillColor: "white",
        center: [593, 260],
        href: "#/",
        port: 11,
        pin: 4
    },

    {
        name: "port12_pin1",
        title: "1",
        shape: "circle",
        coords: [642, 260, 10],
        preFillColor: "white",
        center: [642, 260],
        href: "#/",
        port: 12,
        pin: 1
    },
    {
        name: "port12_pin1",
        title: "2",
        shape: "circle",
        coords: [664, 260, 10],
        preFillColor: "white",
        center: [664, 260],
        href: "#/",
        port: 12,
        pin: 2
    },
    {
        name: "port12_pin1",
        title: "3",
        shape: "circle",
        coords: [688, 260, 10],
        preFillColor: "white",
        center: [688, 260],
        href: "#/",
        port: 12,
        pin: 3
    },
    {
        name: "port12_pin1",
        title: "4",
        shape: "circle",
        coords: [712, 260, 10],
        preFillColor: "white",
        center: [712, 260],
        href: "#/",
        port: 12,
        pin: 4
    },

]


const ONBOARD_SENSOR_PINS = [{
    name: "obs_pin0",
    title: "TC 0",
    shape: "rect",
    coords: [25, 12, 57, 139],
    preFillColor: "transparent",
    center: [40, 75],
    href: "#/",
    id: 'TC0',
    port: 1,
    pin: 40
},
    {
        name: "obs_pin1",
        title: "TC 1",
        shape: "rect",
        coords: [71, 12, 103, 139],
        preFillColor: "transparent",
        center: [85, 75],
        href: "#/",
        id: 'TC1',
        port: 1,
        pin: 41
    }, {
        name: "obs_pin2",
        title: "TC 2",
        shape: "rect",
        coords: [117, 12, 149, 139],
        preFillColor: "transparent",
        center: [85, 75],
        href: "#/",
        id: 'TC2',
        port: 1,
        pin: 42
    }, {
        name: "obs_pin3",
        title: "TC 3",
        shape: "rect",
        coords: [163, 12, 196, 139],
        preFillColor: "transparent",
        center: [85, 75],
        href: "#/",
        id: 'TC3',
        port: 1,
        pin: 43
    },
]
export default class Rev7aBoardDiagram extends PureComponent<any> {
    constructor(props: any) {
        super(props);
    }

    getTipPosition = (area: any) => {
        return {top: `${area.center[1]}px`, left: `${area.center[0] + 14}px`};
    }
    styleButtons = (connectedPins: any) => {
        const pins = _.cloneDeep(ONBOARD_SENSOR_PINS)
        connectedPins?.map(id => {
            let index = pins.findIndex(x => x.id == id)
            if (index > -1) {
                pins[index] = {
                    ...pins[index],
                    preFillColor: 'rgba(0,38,112,0.68)'
                }
            }
        });
        return pins
    }

    render() {
        const pins = this.onMarkingPinsOnBoard(this.props.connectedPins())
        const pinSensors = {
            name: 'map1',
            areas: pins,
        };
        const pinSensors2 = {
            name: 'map2',
            areas: this.styleButtons(this.props.thermocouples),
        };
        return <div className="root-container flex-column">
            <div>
                <BoardDiagram
                    src={pinImage}
                    map={pinSensors}
                    width={750}
                    height={320}
                    onClick={(sensor: any) => {
                        this.onSensorSelection(sensor);
                    }}>

                </BoardDiagram>
                {
                    pinSensors?.areas?.map((sensor: any, index: any) => {
                        let style = 'tooltip-style cursor-pointer font-w-500'
                        if (sensor.connected)
                            style = 'tooltip-style cursor-pointer font-w-500 text-color-white'
                        return (
                            <span className={style} style={{...this.getTipPosition(sensor)}}>
                          {sensor?.title}
                        </span>
                        )
                    })
                }
            </div>
            <div className="d-flex align-content-end">
                <BoardDiagram
                    src={onBoardSensorsImage}
                    map={pinSensors2}
                    width={220}
                    height={150}
                    onClick={(sensor: any) => {
                        this.props.addThermocouple(sensor)
                        setTimeout(() => {
                            const item = document.getElementById(`pin-config-list-item-obs-${sensor.port}-${sensor.pin}`)
                            item?.scrollIntoView()
                        }, 500)
                    }}>
                </BoardDiagram>
                <div className="d-flex flex-column flex-gap mt-3">
                    <Button style={this.props.rh_1 ? {backgroundColor: 'rgba(0,38,112,0.85)', color: 'white'} : {}}
                            size="middle" onClick={() => {
                        if (!this.props.rh_1)
                            this.configureOnboardSensor({id: 'OBS_RH_1', pin: 2, port: 1})
                        setTimeout(() => {
                            const item = document.getElementById(`pin-config-list-item-obs-1-2`)
                            item?.scrollIntoView()
                        }, 500)
                    }}>
                        On-board RH1
                    </Button>
                    <Button style={this.props.rh_2 ? {backgroundColor: 'rgba(0,38,112,0.85)', color: 'white'} : {}}
                            size="middle" onClick={() => {
                        if (!this.props.rh_2)
                            this.configureOnboardSensor({id: 'OBS_RH_2', pin: 3, port: 1})
                        setTimeout(() => {
                            const item = document.getElementById(`pin-config-list-item-obs-1-3`)
                            item?.scrollIntoView()
                        }, 500)
                    }}>
                        On-board RH2
                    </Button>
                    <Button style={this.props.voc ? {backgroundColor: 'rgba(0,38,112,0.85)', color: 'white'} : {}}
                            size="middle" onClick={() => {
                        if (!this.props.voc)
                            this.configureOnboardSensor({id: 'OBS_VOC', pin: 1, port: 1})
                        setTimeout(() => {
                            const item = document.getElementById(`pin-config-list-item-obs-1-1`)
                            item?.scrollIntoView()
                        }, 500)
                    }}>
                        On-board VOC
                    </Button>
                </div>
            </div>
        </div>
    }


    onMarkingPinsOnBoard(connectedPins: any[]) {
        const pins = _.cloneDeep(PINS)
        connectedPins.map(pin => {
            let index = pins.findIndex(x => x.pin == pin.pin && x.port == pin.port)
            if (index > -1) {
                pins[index] = {
                    ...pins[index],
                    preFillColor: 'rgba(0,38,112,0.85)',
                    connected: true
                }
            }
            if (pin.port == 2 && pin.port_type == PORT_TYPE.ONBOARD) {
                const index = 9
                pins[index]['preFillColor'] = 'rgba(0,38,112,0.85)'
                pins[index]['connected'] = true
                pins[index - 1]['preFillColor'] = 'grey'
                pins[index - 1]['disabled'] = true
            }
        })
        if (this.props.isDaisyChained) {
            let index = 9
            if (index > -1) {
                pins[index - 1]['preFillColor'] = 'rgba(0,38,112,0.85)'
                pins[index - 1]['connected'] = true
                pins[index]['preFillColor'] = 'grey'
                pins[index]['disabled'] = true
            }
        }
        return pins
    }

    private onSensorSelection(sensor: any) {
        if (!sensor.connected)
            this.props.configurePin(sensor)
        else {
            const item = document.getElementById(`pin-config-list-item-${sensor.port}-${sensor.pin}`)
            item?.scrollIntoView()
        }
    }

    private configureOnboardSensor(sensor) {
        this.props.configureOnboardSensors(sensor)
    }

    private removeOnboardSensor(sensor) {

    }

}

const PopConfirm = ({action, hide, show}) => {
    const [visible, setVisibility] = useState(false);

    useEffect(() => {
        if (show) {
            setVisibility(show)
            autoHide()
        }
    }, [show]);
    const autoHide = () => {
        setTimeout(() => {
            setVisibility(false)
        }, 2000)
    }
    return visible ? (<div className="popup-root">
        <div className="popup-title">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                 class="bi bi-question-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                <path
                    d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"/>
            </svg>
            Delete Sensor
        </div>
        <div className="popup-body">Sensor will be removed!</div>
        <div className="popup-footer ">
            <button className="text-color-black btn-ok btn-sm" onClick={() => {
                setVisibility(false)
            }}>cancel
            </button>
            <button className="text-color-black btn-danger btn-sm" onClick={() => {
                action()
            }}>yes
            </button>
        </div>
    </div>) : <Fragment></Fragment>
}