import React, {createRef} from 'react'
import createPlotlyComponent from 'react-plotly.js/factory';
import Utils from '../../../helpers/Utility';
import _ from 'lodash';

const Plotly = window.Plotly;
const Plot = createPlotlyComponent(Plotly);

interface GraphProps {
    sensor?: any;
    configDate?: String;
    category?: any;
    x_data?: Array<string>;
    y_data?: Array<number>;
    index?: number;
    x?: any;
    y?: any;
    threshold?: any;
    param?: any;
    sensor_values?: any;
    fieldTestOfflineDuration?: any;
}

class Graph extends React.PureComponent<GraphProps, {}> {

    graphContainerRef?: React.RefObject<HTMLDivElement> = createRef()
    layouts = (sensors: any) => {
        // let title = sensors?.map((sensor: any) => { return sensor[`name`] }).join(' & ');
        let result = {
            width: this.graphContainerRef?.current?.offsetWidth,
            // title: title,
            legend: {
                x: 0,
                y: 100
            },
            autosize: true,
            xaxis: {
                type: "date",
                autorange:true,
                tickformat: '%I:%M:%S %p\n%B %d, %Y',
                uirevision: 'time',
                // linewidth: 1,
            },
            yaxis: {
                type: 'linear',
                title: sensors[0]?.name,
                titlefont: {color: 'black'},
                tickfont: {color: 'black'},
                linewidth: 1,

            },
            yaxis2: {
                type: 'linear',
                title: sensors[1]?.name,
                titlefont: {color: 'black'},
                tickfont: {color: 'black'},
                overlaying: 'y',
                tickmode: 'match',
                side: 'right',
                linewidth: 1,
            }
        } as any

        if (sensors[0]?.sensor_type === 'switch') {
            result.yaxis[`tickvals`] = [0, 1]
        }

        if (sensors[1]?.sensor_type === 'switch') {
            result.yaxis2[`tickvals`] = [0, 1]
        }
        if (sensors[0]?.sensor_type === sensors[1]?.sensor_type) {
            const gap =.1;
            result.yaxis['range'] = [sensors.min - (sensors.max - sensors.min) * gap, sensors.max + (sensors.max - sensors.min) * gap]
            result.yaxis['anchor'] = 'x'
            result.yaxis2['range'] = [sensors.min - (sensors.max - sensors.min) * gap, sensors.max + (sensors.max - sensors.min) * gap]
            result.yaxis2['anchor'] = 'y'
            result.yaxis2['scaleanchor'] = 'y'
            result.yaxis2['tickmode'] = 'sync'
        }
        return result
    }
addhighlightToThresholds = (min:any,max:any,label:string,xValues:any)=>{
        const minLineTrace=[{
            x:xValues,
            y:Array(xValues.length).fill(min),
            mode:'lines',
            line:{
                color:'orange',
                width:2
            },
            yaxis:'y1',
            name:`${label} Minimum`
        }]
    const maxLineTrace=[{
        x:xValues,
        y:Array(xValues.length).fill(max),
        mode:'lines',
        line:{
            color:'red',
            width:2
        },
        yaxis:'y1',
        name:`${label} Maximum`
    }]
        return [...minLineTrace,...maxLineTrace]
}
    render() {
        const { index, configDate, param, x, y,threshold, sensor_values, category, fieldTestOfflineDuration } = this.props
        let max = 0, min = 0;
        let datas = param?.map((data: any, index: any) => {
            let result = {
                x: x[data.value],
                y: y[data.value],
                mode: 'lines+markers',
                type: 'scatter',
                name: sensor_values?.find((sensor: any) => (sensor?.pin_with_representation === data?.value))?.display_name,
                sensor_type: sensor_values?.find((sensor: any) => (sensor?.pin_with_representation === data?.value))?.type,
                representation: sensor_values?.find((sensor: any) => (sensor?.pin_with_representation === data?.value))?.representation
            } as any;

            if (index === 1) {
                result.yaxis = 'y2'
            }
            let temp = _.max(result.y) as number
            if (temp) {
                max = Math.max(max, temp)
            }
            temp = _.min(result.y) as number
            min = index === 0 ? temp :Math.min(min, temp)
            return result
        });
        datas.max = max;
        datas.min = min;

        if (param.length == 1 && threshold?.hasOwnProperty(param[0].value)) {
            const {min, max} = threshold[param[0].value]
            datas = [...datas, ...this.addhighlightToThresholds(min, max, param[0].label, datas[0].x)]
        }
        let cycleInfo = {} as any

        let tstatWSwitch = datas?.find((sensor: any) => (sensor.representation === 'tstat_w'))

        let isFieldTest = (category === 'fieldtest')

        if (tstatWSwitch && isFieldTest) {

            let startTime = null as any;
            let prevValue = null as any;
            let prevTime = null as any;
            let timeCycleInms = null as any;
            let cycle = 0;
            let offlineDuration = fieldTestOfflineDuration ? Number(fieldTestOfflineDuration) : 10;

            tstatWSwitch?.y?.forEach((currentVal: any, index: any) => {

                if (index >= 0) {
                    if (currentVal === 1 && prevValue === 0) {
                        cycle += 1;
                        startTime = tstatWSwitch?.x?.length > 0 ? tstatWSwitch?.x[index] : 0
                    } else if (currentVal === 1 && prevValue === 1) {

                        let currentTime = tstatWSwitch?.x?.length > 0 ? tstatWSwitch?.x[index] : 0
                        let timeDiffOfCurrentAndPrevData = (currentTime - prevTime)
                        if (timeDiffOfCurrentAndPrevData >= offlineDuration * 60 * 1000) {

                            if (startTime) {
                                let timeDiffOfCycle = (tstatWSwitch?.x?.length > 0 ? (tstatWSwitch?.x[index] - startTime) : 0);
                                timeCycleInms += timeDiffOfCycle - timeDiffOfCurrentAndPrevData
                            }

                            cycle += 1;
                            startTime = tstatWSwitch?.x?.length > 0 ? tstatWSwitch?.x[index] : 0
                        }

                    } else if (currentVal === 0 && prevValue === 1) {

                        let currentTime = tstatWSwitch?.x?.length > 0 ? tstatWSwitch?.x[index] : 0
                        let timeDiffOfCurrentAndPrevData = (currentTime - prevTime)

                        if (startTime) {
                            let timeDiffOfCycle = (tstatWSwitch?.x?.length > 0 ? (tstatWSwitch?.x[index] - startTime) : 0);
                            timeCycleInms += (timeDiffOfCurrentAndPrevData >= 10 * 60 * 1000 ? (timeDiffOfCycle - timeDiffOfCurrentAndPrevData) : timeDiffOfCycle)
                        }

                    }

                    prevValue = currentVal
                    prevTime = (tstatWSwitch?.x?.length > 0 ? tstatWSwitch?.x[index] : 0)
                }

            })

            if ((tstatWSwitch?.y[tstatWSwitch?.y?.length - 1]) === 1) {
                cycle = cycle > 0 ? (cycle - 1) : cycle
            }

            cycleInfo['noOfCycles'] = cycle;
            cycleInfo['durationOfCycles'] = Utils.durationAsString(timeCycleInms); // in ms
        }

        return (
            <div
                className="graph-container"
                key={index}
                hidden={
                    !!(y && Object.keys(y).length === 0)
            }
                ref={this.graphContainerRef}
            >
                {(tstatWSwitch && isFieldTest) &&
                    <div className='d-flex float-left font-w-600'>
                        <div className='flex-fill'>
                            <p>{cycleInfo ? `No Of Cycles For TSTAT Heat 'W' : ${cycleInfo?.noOfCycles}` : ''}</p>
                        </div>
                        <div className='flex-auto ml-4'>
                            <p>{cycleInfo ? `Total Time Of Cycles For TSTAT Heat 'W' : ${cycleInfo?.durationOfCycles}` : ''}</p>
                        </div>
                    </div>
                }

                <Plot
                    data={datas}
                    layout={this.layouts(datas)}
                    config={{
                        scrollZoom: false,
                        displaylogo: false,
                        toImageButtonOptions: {
                            format: 'svg', // one of png, svg, jpeg, webp
                            filename: 'custom_image',
                            height: 500,
                            width: 700,
                            scale: 1 // Multiply title/legend/axis/canvas sizes by this factor
                        }
                    }}
                />
                <p className='font-w-600'>{configDate ? configDate : ''}</p>
            </div>
        );
    }
}

export default Graph