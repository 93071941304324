import React, { useState , useEffect } from 'react';
import DatePicker from '../datepicker';
import constants from '../../config/constants';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import infoIcon from '../../assets/icons/infoCircle.svg';
import authStore from '../../store/authStore';
import  Permissions from '../../config/permssions';

const {
    view_only_partial_asset_sensorbox_details
  } = Permissions

interface Props {
    sensorbox: any;
    onChangeDeploymentStatus: any
    disabled: any;
}

const DeploymentStatus: React.FC<Props> = ({ onChangeDeploymentStatus, sensorbox, disabled }) => {

    const [errors, setErrors] = useState<any>({})

    useEffect(() => {
        setErrors({})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sensorbox]);

    const validateField = (type: any, value: any) => {
        let error: any = {}
        if (type === 'tested_date') {

            if(sensorbox.commissioned_date){
                if(value.getTime() <= sensorbox?.commissioned_date){
                    error.tested_date = ''
                }else{
                    error.tested_date = constants.message.invalid_tested_date
                    setErrors(error)
                    return false
                }
            }

            if(sensorbox.decommissioned_date){
                if(value.getTime() <= sensorbox?.decommissioned_date){
                    error.tested_date = ''
                }else{
                    error.tested_date = constants.message.invalid_tested_date
                    setErrors(error)
                    return false
                }
            }
        }

        else if (type === 'commissioned_date') {

            if(sensorbox.tested_date){
                if(value.getTime() >= sensorbox?.tested_date){
                    error.commissioned_date = ''
                }else{
                    error.commissioned_date = constants.message.invalid_commissioned
                    setErrors(error)
                    return false
                }
            }

            if(sensorbox.decommissioned_date){
                if(value.getTime() <= sensorbox?.decommissioned_date){
                    error.commissioned_date = ''
                }else{
                    error.commissioned_date = constants.message.invalid_commissioned
                    setErrors(error)
                    return false
                }
            }
        }

        else if (type === 'decommissioned_date') {

            if(sensorbox.tested_date){
                if(value.getTime() >= sensorbox?.tested_date){
                    error.decommissioned_date = ''
                }else{
                    error.decommissioned_date = constants.message.invalid_decommissioned
                    setErrors(error)
                    return false
                }
            }

            if(sensorbox.commissioned_date){
                if(value.getTime() >= sensorbox?.commissioned_date){
                    error.decommissioned_date = ''
                }else{
                    error.decommissioned_date =  constants.message.invalid_decommissioned
                    setErrors(error)
                    return false
                }
            }
        }

        setErrors(error)
        return true
    }

    const onChangeDateHandler = (value: any, type: any) => {
        let action = ''
        if(value === null){
            action = "clear"
            setErrors({})
            onChangeDeploymentStatus(null, type ,action)
        }else{
            if (validateField(type, value)) {
                onChangeDeploymentStatus(value, type, action)
            } else {
                onChangeDeploymentStatus(null, type , action)
            }
        }
    }
    const tooltip = (
        <Tooltip style={{display:!(sensorbox?.sensorbox_group)?'none':'block'}} id="tooltip-decommission-date">
        {constants.message.cannot_be_decommissioned}
        </Tooltip>
      );
    return (
        <div id="deployment-status">
            <div className="row mt-50 mb-25 ml-2 ht-custom">
                <div className="col-xs-4 col-sm-4 col-md-3 col-lg-3 media-margin-tp">
                    <div className="form-group mb-0">
                        <label className="custom-label" >Tested Date</label>
                        <DatePicker
                            placeholderText={!authStore?.currentUser?.access?.includes(view_only_partial_asset_sensorbox_details) ? "Choose date" : 'Date'}
                            value={sensorbox?.tested_date ? new Date(sensorbox?.tested_date) : ''}
                            dateFormat="MM/dd/yyyy"
                            maxDate={new Date()}
                            isClearable={true}
                            onChange={(value: any, e: any) => {
                                onChangeDateHandler(value, 'tested_date')
                            }}
                            disabled={disabled}

                        />
                        <div className="error-msg">{errors && errors.tested_date ? errors.tested_date : ''}</div>
                    </div>
                </div>
                <div className="col-xs-4 col-sm-4 col-md-3 col-lg-3 media-margin-tp">
                    <div className="form-group mb-0">
                        <label className="custom-label">Commissioned Date</label>
                        <DatePicker
                            placeholderText={!authStore?.currentUser?.access?.includes(view_only_partial_asset_sensorbox_details) ? "Choose date" : 'Date'}
                            value={sensorbox?.commissioned_date ? new Date(sensorbox?.commissioned_date) : ''}
                            dateFormat="MM/dd/yyyy"
                            maxDate={new Date()}
                            isClearable={true}
                            onChange={(value: any) => {
                                onChangeDateHandler(value, 'commissioned_date')
                            }}
                            disabled={disabled}
                        />
                        <div className="error-msg">{errors && errors.commissioned_date ? errors.commissioned_date : ''}</div>
                    </div>
                </div>

                <div className="col-xs-4 col-sm-4 col-md-3 col-lg-3 media-margin-tp">
                    <div className="form-group mb-0">
                    <label className="custom-label" >Decommissioned Date</label>
                        <OverlayTrigger placement="top" overlay={tooltip}>
                        <img style={{display:!(sensorbox?.sensorbox_group)?'none':''}} className="icon-15 ml-1" src={infoIcon} />
                        </OverlayTrigger>
                        <DatePicker
                            placeholderText={!authStore?.currentUser?.access?.includes(view_only_partial_asset_sensorbox_details) ? "Choose date" : 'Date'}
                            value={sensorbox?.decommissioned_date ? new Date(sensorbox?.decommissioned_date) : ''}
                            dateFormat="MM/dd/yyyy"
                            maxDate={new Date()}
                            isClearable={true}
                            onChange={(value: any) => {
                                onChangeDateHandler(value, 'decommissioned_date')
                            }}
                            disabled={disabled}
                        />
                        <div className="error-msg">{errors && errors.decommissioned_date ? errors.decommissioned_date : ''}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeploymentStatus;

