import React, { ChangeEvent } from 'react';
import './viewsensor.css';
import {Contractor, SensorBox, sensors} from '../../interface'
import Select from '../../components/select';
import UserModal from '../createuser'
import Checkbox from '../../components/checkbox'
import userService from '../../services/userService';
import toast from '../toast';
import validate from '../../helpers/validation';
import LoadingWheel from '../loadingwheel';
import constants from '../../config/constants';
import roleService from '../../services/roleService';
import Utility from '../../helpers/Utility';
import ConfirmModal from '../confirmmodal'
import sensorService from '../../services/sensorService';
import authStore from '../../store/authStore';
import userStore from '../../store/userStore';
import trashRestore from '../../assets/trash-restore.svg'
import MaintenanceCheckList from '../maintenance-checklist';
import eventStore from '../../store/eventStore';
import pinConfigurationService from '../../services/pinConfigurationService';
import InspectionChecklistServices from '../../services/InspectionChecklistServices';
import _ from 'lodash';
import MoveSensorboxModal from '../../components/moveSensorboxModal'
import  Permissions from '../../config/permssions'
import SaveConfirmationModal from '../../components/confirmDialogModal';
import DeploymentStatus from '../deploymentStatus';
import {Rev7aSensorsTab, validateRev7aSensorState} from '../Rev7aSensorsTab/Rev7aSensorsTab'
import login from "../../screens/login";
import issueTrackerServices from '../../services/issueTrackerServices';

const {
    super_admin_access,
    org_admin_access ,
    view_contractor_sensorboxes_assets,
    view_assigned_users_sensorboxes_assets,
    create_or_update_sensorboxes_assets,
    delete_sensorboxes_assets,
    view_only_partial_asset_sensorbox_details
  } = Permissions

const categoryOptions = [
    { _id: 'default', name: 'Default' },
    { _id: 'chiller-smaller', name: 'Small Chiller' },
    { _id: 'chiller-larger', name: 'Large Chiller' },
    { _id: 'chromalox', name: 'Chromalox' },
    { _id: 'fieldtest', name: 'Field Test' },
    { _id: 'generator', name: 'Generator' },
]

const boardVersionOptions = [
    { _id: 3, name: 'Rev3' },
    { _id: 6, name: 'Rev6' },
    { _id: 7, name: 'Rev7' },
    { _id: 7.1, name: 'Rev7A' },
]

const deviceTypeOptions = [
    { _id: 'general', name: 'General' },
    { _id: 'lockrouter', name: 'Lock Rotor' },
    { _id: 'cyclictest', name: 'Cyclic Test' }
]

export interface createSensorBoxFormProps {
    sensorbox?                  :   SensorBox;
    clearSelectedSensorBox      :   any;
    updateSensorBoxList         :   any;
    organisationId?             :   any;
    organisationList?           :   any;
    shouldPageChange            :   any;
    setVisibleClose?            :   any;
    updateEventSourceListener   :   () => void;
    issueDetailsParams          : any
}

export interface createSensorBoxFormState {
    sensorbox?      :   SensorBox;
    error?          :   SensorBox;
    isEdit          :   boolean;
    user_access      :   Array<any>;
    roleList        :   Array<any>;
    consumerList    :   Array<object>;
    inspectionList  :   Array<object>;
    contractorList  :   Array<object>;
    coordinates     :   Array<object>;
    isLoading       :   boolean;
    sensorTypeList  :   Array<any>;
    sensorTemplates?   :   Array<any>;
    sensorTypeListCopy  :   Array<object>;
    sensors         :   Array<object>;
    sensorDetails?  :   sensors;
    responseError   :   string;
    refrigerantList :   Array<object>;
    tabactive       :   boolean;
    tabhomeactive   :   boolean;
    timeZoneList    :   Array<any>,
    validRangeError? :  string;
    validCalibrationError? :  string;
    invalidDeploymentStatusDate? : boolean;
    activeTab       :   string;
    pinConfigList   :   any;
    pinError        :   any;
    showMoveSensorboxModal : boolean;
    saveConfirmationModal: boolean;
    saveType: string;
    readIntervalChanged: boolean;
    issueCount: number
}



class CreateSensorBox extends React.Component<createSensorBoxFormProps, createSensorBoxFormState> {
    sensorArray : any = []    
    defaultRole: { _id: string; name: string; };
    issueDetailsParams: { pageNumber: number; limit: number; orgId: string; status: string[]; consumer: any[]; contractor: any[]; service_resolved_by: any[]; searchFilter: string; startDate: string; endDate: string; groupId: string};
    constructor(props : createSensorBoxFormProps){
        super(props)
        this.defaultRole={ _id : '',name : 'All Roles'};
        this.state = {
            sensorbox           :   undefined,
            error               :   undefined,
            isEdit              :   false,
            roleList            :   [this.defaultRole],
            consumerList        :   [],
            user_access          :   [],
            inspectionList      :   [],
            contractorList      :   [],
            coordinates         :   [],
            isLoading           :   false,
            sensorTypeList      :   [],
            sensorTypeListCopy  :   [],
            sensorTemplates       :   [],
            sensors             :   [],
            sensorDetails       :   undefined,
            responseError       :   '',
            refrigerantList     :   [],
            tabactive           :   false,
            tabhomeactive       :   true,
            timeZoneList        :   [],  
            validRangeError     :   '',
            validCalibrationError : '',
            invalidDeploymentStatusDate : false,
            activeTab           :   'sensorbox',
            pinConfigList       :   {},
            pinError            :   {},
            showMoveSensorboxModal : false,
            saveConfirmationModal : false,
            saveType: '',
            readIntervalChanged: false,
            issueCount: 0
        }
        this.issueDetailsParams = {
            pageNumber: 1,
            limit: 10,
            orgId: '',
            status: ['OPEN'],
            consumer: [],
            contractor: [],
            service_resolved_by: [],
            searchFilter: '',
            startDate: '',
            endDate: '',
            groupId: '',
        }
    }

    componentDidMount() {
        const { sensorbox } : any = this.state
        this.setState({ isLoading: true })
        if (this.props.sensorbox) {
            let category = categoryOptions.find((value:any)=>(value._id === this.props.sensorbox?.category))  
            this.setState({ sensorbox: {...JSON.parse(JSON.stringify(this.props.sensorbox)) ,category : category, board_version : this.props.sensorbox?.board_version}}, async () => {
                if(this.props?.sensorbox?.consumer?.[0]?._id){ // get inspection details
                    let inspection_data = await InspectionChecklistServices.getInspectionsList({ customer : this.props?.sensorbox?.consumer?.[0]?._id , orgid : this.props?.organisationId});
                    const inspectionList = inspection_data?.data?.inspections?.map((inspection: any) => {
                        let address = (inspection?.consumer_data?.street && inspection?.consumer_data?.city && inspection?.consumer_data?.state) ?   `${inspection?.consumer_data?.street}, ${inspection?.consumer_data?.city}, ${inspection?.consumer_data?.state} ` : '-'
                        let inspections: any = { _id: inspection?._id, name: `${inspection?.system_name} / ${address}` };
                        return inspections
                    })
                    this.setState({inspectionList})
                }

                this.sensorArray = this.state.sensorbox && this.state.sensorbox.sensors ? this.state.sensorbox.sensors : [];
                await this.getRoles()
                await this.getCustomerList()

                let userAccessArray = this.state?.consumerList?.filter((item:any)=>(
                        this.props.sensorbox?.user_access?.includes(item?._id)
                ))  
                    
                let userAccessObject = userAccessArray?.map((item:any)=>({value:item._id,label:item.name})) 

                this.setState({ sensorbox: {...JSON.parse(JSON.stringify(this.props.sensorbox)) , userAccessObject ,category : category, board_version : this.props.sensorbox?.board_version}})
                await this.getContractorList()
                await this.getPinConfiguration();
                await this.getSensorTypeList()
                await this.getSensorTemplates()
                await this.getRefrigerentList()
                await this.getIssueTrackerList(this.props.sensorbox)
                this.setState({ isLoading: false })
            })
        } else {
            let category = categoryOptions.find((value:any)=>(value._id === 'default')) 
            this.setState({ sensorbox: { ...sensorbox , heat_provided_by : 'furnace' ,category  ,board_version : 3 , offline_alert_on : true, device_type : 'general'} }, async () => {
                await this.getRoles()
                await this.getCustomerList()
                await this.getContractorList()
                await this.getSensorTypeList()
                await this.getSensorTemplates()
                await this.getPinConfiguration()
                await this.getRefrigerentList()
                this.getNewSensorBoxName()
                this.setState({ isLoading: false })
            })
        }
    }

    isDisabled() {
        return (this.state.sensorbox !== undefined && this.state.sensorbox._id !== undefined && !this.state.isEdit) || this.state.isLoading 
    }

    // this is temporary solution for identifying HVAC Intel organization
    isDisabledSensorBoxId() {
        if ((this.state.sensorbox !== undefined && this.state.sensorbox._id !== undefined) || this.state.isLoading || !authStore.currentUser.is_default || this.props?.organisationId)
            return authStore?.currentUser?.oid === this.props?.organisationId ? this.isUpdate() ? !this.state.isEdit : false : true;
        return false

    }

    isGrouped() {
        const { sensorbox } = this.props;
        return !(sensorbox?.sensorbox_group === null || sensorbox?.sensorbox_group === undefined)
    }

    isUpdate(){
        return this.state.sensorbox !== undefined && this.state.sensorbox._id !== undefined 
    }

    getRoles :any = async () => {
        try {
            const response = await roleService.getRoles(authStore.currentUser.oid ? authStore.currentUser.oid  : this.props?.organisationId)
            let filteredValue = response.data.roles.filter((item :any)=> item.role_hierarchy > authStore.currentUser.role_hierarchy)
            let orgFilteredValue = response.data.roles.filter((item :any)=> item.role_hierarchy >= authStore.currentUser.role_hierarchy)                

            if(authStore?.currentUser?.access?.includes(super_admin_access) || authStore?.currentUser?.access?.includes(org_admin_access)){
                this.setState({roleList : [this.defaultRole, ...orgFilteredValue] , isLoading : false})
            }
            else{
                this.setState({roleList : [this.defaultRole, ...filteredValue] , isLoading : false})
            }

        } catch (error) {
                toast.error(error.response.data.message)
                this.setState({isLoading : false})
        }
    }

    sensorBoxExist = async() =>{      
        let error               =   {} as any
        let isValid             =   false
        if(this.state.sensorbox && this.state.sensorbox.sensor_box_name){
            try{
                let response = await sensorService.checkSensorBoxExist(this.state.sensorbox._id,this.state.sensorbox.sensor_box_name)
                if(response !== 0){
                    error.sensor_box_name   =  constants.message.name_exist
                    isValid                 =  true
                    this.setState({error});
                    return isValid 
                }else{
                    error.sensor_box_name   = ''
                    this.setState({error});
                    return isValid 
                }                
            }catch(error){
                toast.error(error.response.data.message)
            }              
        }else{   
            return isValid 
        }
    }

    updateTextFieldValue(e : any,type?:string) {
        if(!this.state.sensorbox){
            Object.assign(this.state, {sensorbox : {}})
        }
        let error  = {} as any;
        Object.assign(this.state.sensorbox,{[e.target.name] : e.target.value.trimLeft() })
        this.setState({sensorbox : this.state.sensorbox,error})
                     
    }

    updateSystemName(e:any) {
        if(!this.state.sensorbox){
            Object.assign(this.state, {sensorbox : {}})
        }
        if(validate.isValidSystemName(e.target.value)){
            let error  = {} as any;
            Object.assign(this.state.sensorbox,{[e.target.name] : e.target.value.trimLeft() })
            this.setState({sensorbox : this.state.sensorbox,error})
        }                
             
    }

    updateAlias(e:any) {
        
        if(!this.state.sensorbox){
            Object.assign(this.state, {sensorbox : {}})
        }
        Object.assign(this.state.sensorbox,{[e.target.name] : e.target.value.trimLeft() })
        this.setState({sensorbox : this.state.sensorbox})
             
    }
    updateReadInterval(e: any) {
        if (!this.state.sensorbox) {
            Object.assign(this.state, { sensorbox: {} })
        }
        const read_interval = parseInt(e.target.value.trim())
        if (!_.isNaN(read_interval)) {
            let readIntervalChanged = this.state.sensorbox?.read_interval !== read_interval
            Object.assign(this.state.sensorbox, { read_interval })
            this.setState({ sensorbox: this.state.sensorbox, readIntervalChanged })
        } else {
            Object.assign(this.state.sensorbox, { read_interval: '' })
            this.setState({ sensorbox: this.state.sensorbox, readIntervalChanged: false })
        }

    }

    updateAddressValue(e:any) {
        if(!this.state.sensorbox){
            Object.assign(this.state, {sensorbox : {}})
        }        
        // if(validate.isValidAddress(e.target.value)){
            let error  = {} as any;
            Object.assign(this.state.sensorbox,{[e.target.name] : e.target.value.trimLeft() })
            this.setState({sensorbox : this.state.sensorbox,error})
        // } 
               
             
    }

    updateCapacityValue(e:any) {
        if(!this.state.sensorbox){
            Object.assign(this.state, {sensorbox : {}})
        }
        if(validate.isValidValue(e.target.value)){
            let error  = {} as any;
            Object.assign(this.state.sensorbox,{[e.target.name] : e.target.value.trimLeft() })
            this.setState({sensorbox : this.state.sensorbox,error});
        } 
                
             
    }

    updateModelValue(e:any) {
        if(!this.state.sensorbox){
            Object.assign(this.state, {sensorbox : {}})
        }         
        if(validate.isValidModel(e.target.value)){
            let error  = {} as any;
            Object.assign(this.state.sensorbox,{[e.target.name] : e.target.value.trimLeft() })
            this.setState({sensorbox : this.state.sensorbox,error})
        }   
             
    }

    updateAirflowValue(e:any) {
        if(!this.state.sensorbox){
            Object.assign(this.state, {sensorbox : {}})
        }   
        if(validate.isValidAirflow(e.target.value)){
            let error  = {} as any;
            Object.assign(this.state.sensorbox,{[e.target.name] : e.target.value.trimLeft() })
            this.setState({sensorbox : this.state.sensorbox,error})
        }         
    }

    updateLatitude(e:any) {
        if(!this.state.sensorbox){
            Object.assign(this.state, {sensorbox : {}})
        }    
        if(validate.isValidValue(e.target.value)){
            let error  = {} as any;
            let locationArray =  this.state.sensorbox &&  this.state.sensorbox.location &&  this.state.sensorbox.location.coordinates ? this.state.sensorbox.location.coordinates : [];
            locationArray[0]  =   e.target.value;   
            Object.assign(this.state.sensorbox,{ location : {coordinates : locationArray}})  
            this.setState({sensorbox : this.state.sensorbox,error})  
        } else{
            let error  = {} as any;
            Object.assign(this.state.sensorbox,{ location : {coordinates : {}}})  
            this.setState({sensorbox : this.state.sensorbox,error})
        }   
    }

    updateLongitude(e:any) {
        if(!this.state.sensorbox){
            Object.assign(this.state, {sensorbox : {}})
        }  
        if(validate.isValidValue(e.target.value)){
            let error  = {} as any;
            let locationArray =  this.state.sensorbox &&  this.state.sensorbox.location && this.state.sensorbox.location.coordinates ? this.state.sensorbox.location.coordinates : [];
            locationArray[1]  =   e.target.value;        
            Object.assign(this.state.sensorbox,{ location : {coordinates : locationArray}})
            this.setState({sensorbox : this.state.sensorbox,error})  
        } else{
            let error  = {} as any;
            Object.assign(this.state.sensorbox,{ location : {coordinates : {}}})  
            this.setState({sensorbox : this.state.sensorbox,error})  
        } 
            
    }

    coordinates = async() => {
        let isValid = false;
        if(!this.state.sensorbox){
            Object.assign(this.state, {sensorbox : {}})
        }
        if(this.state.sensorbox && this.state.sensorbox.place){
            isValid = await this.getCoordinates(this.state.sensorbox.place)
        }else{
            isValid = false;
            Object.assign(this.state.sensorbox,{ location :{ coordinates : []}, timezone : ''})  
            this.setState({sensorbox : this.state.sensorbox}) 
        }        
        return isValid;
    }

    async getCoordinates(place :any) {
        let coordinates=[];
        try {
            let error               =   {
                place:''
            } as any
            coordinates         = await userService.getCoordinatesList(place)
           this.getTimeZone(coordinates)
            error.place           = coordinates.length?"":constants.message.address_valid
            if(coordinates){
                let weatherStation = await userService.getWeatherInformation(coordinates)
                Object.assign(this.state.sensorbox,{weather_station : weatherStation?.station_id , forecast_link : weatherStation?.forecast_link , grid_id : weatherStation?.grid_id , grid_x : weatherStation?.grid_x , grid_y : weatherStation?.grid_y}) 
            }
            Object.assign(this.state.sensorbox,{ location :{ coordinates : coordinates}})
            this.setState({sensorbox : this.state.sensorbox,error}); 
            return coordinates.length>0 
        } catch (error) {
            toast.error(error.response.data.message)
            return coordinates.length>0 
        }
    }

    async getTimeZone(coordinates : Array<any>){
        let timeZone = await userService.getTimeZone(coordinates)
        let sensorbox  = this.state.sensorbox || {} as SensorBox
        sensorbox.timezone = timeZone
        this.setState({sensorbox})
    }
    setTimeZone(value : any){
        let timezone = value.name
        if(validate.validTimeZone(timezone)){
            if(!this.state.sensorbox){
                Object.assign(this.state, {sensorbox : {}})
            }

            Object.assign(this.state.sensorbox, { timezone : timezone })
            this.setState({sensorbox : this.state.sensorbox})
        }
    }

    showUserCreateForm() {
        Utility.showModal('createuser')
        Utility.closeModal('sensorboxdetails')
    }

    updateRefrigerant  = (refrigerent : any)=> {

        if(!this.state.sensorbox){
            Object.assign(this.state, {sensorbox : {}})
        }

        Object.assign(this.state.sensorbox, { refrigerent : refrigerent })
        this.setState({sensorbox : this.state.sensorbox},()=>{
            this.getRefrigerentList()
        })
    }

    updateCategory  = (category : any)=> {

        if(!this.state.sensorbox){
            Object.assign(this.state, {sensorbox : {}})
        }

        Object.assign(this.state.sensorbox, { category : category })
        this.setState({sensorbox : this.state.sensorbox})
    }

    inspectionValue(){
        const inspection = this.state?.inspectionList.find((s: any) => s?._id === this.state.sensorbox?.inspection) as any;
        return inspection
    }

    updateBoardVersion = async (boardVersion : any) => {
        if(!this.state.sensorbox){
            Object.assign(this.state, {sensorbox : {}})
        }

        Object.assign(this.state.sensorbox, { board_version : boardVersion?._id })

        let sortedPinsBasedOnBoardVersions = this.state?.sensorTypeList.map((item: any, index: any) => {
            let pin_order = item.pin_order.find((pin: any) => {
                return pin.board_version === boardVersion?._id
            })
            return pin_order ? {...item, pinOrder: pin_order?.order} : item 
        }).sort((a: any, b: any) => (a?.pinOrder - b?.pinOrder))

        await this.sensorType(sortedPinsBasedOnBoardVersions)
        await this.updateDefaultCalibration(this.sensorArray)
        this.setState({sensorbox : this.state.sensorbox})
    }

    updateDeviceType = async (type : any) => {
        if(!this.state.sensorbox){
            Object.assign(this.state, {sensorbox : {}})
        }

        Object.assign(this.state.sensorbox, { device_type : type?._id })
        this.setState({sensorbox : this.state.sensorbox})
    }

    async updateDefaultCalibration (sensors : any){
        if(sensors){
            sensors?.forEach((sensor: any) => {
                if(sensor.sensor_type?.is_configurable?.includes(1)){
                    if(!(sensor.calibration_c !== undefined)){
                        sensor.calibration_c = 1
                        sensor.sensor_type.calibration_c = 1
                    }
                    if(!(sensor.calibration_d !== undefined)){
                        sensor.calibration_d = 0
                        sensor.sensor_type.calibration_d = 0
                    }
                    if(!(sensor.calibration_a !== undefined)){
                        sensor.calibration_a = 0
                        sensor.sensor_type.calibration_a = 0
                    }
                    if(!(sensor.calibration_b !== undefined)){
                        sensor.calibration_b = 0
                        sensor.sensor_type.calibration_b = 0
                    }
                }
            })
        Object.assign(this.state.sensorbox, { sensors })
        }
    }

    updateCustomer = async (data : any) => {
        const consumer = this.convertDropdownData(data,'r')
        let inspectionList = [];

        if(consumer[0]){ // get inspection details
            const inspectionsListFromApi = await InspectionChecklistServices.getInspectionsList({ customer : consumer[0]?._id , orgid : this.props?.organisationId});

            inspectionList = inspectionsListFromApi?.data?.inspections?.map((inspection: any) => {
                let address = (inspection?.consumer_data?.street && inspection?.consumer_data?.city && inspection?.consumer_data?.state) ?   `${inspection?.consumer_data?.street}, ${inspection?.consumer_data?.city}, ${inspection?.consumer_data?.state} ` : '-'
                let inspections: any = { _id: inspection?._id, name: `${inspection?.system_name} / ${address}` };
                return inspections
            })
        }

        this.setState({inspectionList});

        if(!this.state.sensorbox){
            Object.assign(this.state, {sensorbox : {}})
        }
        if(authStore?.currentUser?.active_role?.role_type !== -1) {
            this.getConsumerAddress(consumer[0]);
        }
        Object.assign(this.state.sensorbox, { consumer : consumer , inspection : ''})
        this.setState({sensorbox : this.state.sensorbox},()=>{
            this.getCustomerList()
        })
    }

    updateInspection = async(inspectionData : any) => {
        if(!this.state.sensorbox){
            Object.assign(this.state, {sensorbox : {}})
        }

        Object.assign(this.state.sensorbox, { inspection : inspectionData?._id })
        this.setState({sensorbox : this.state.sensorbox})
    }

    updateFields = (event: ChangeEvent<HTMLInputElement>) => {
        const { sensorbox }: any = this.state
        const { value, name } = event.target;
        let error  = {} as any;
        this.setState({ sensorbox: { ...sensorbox, [name]: value },error})
    }

    updateContractorAlert = (event: ChangeEvent<HTMLInputElement>) => {
        if(!this.state.sensorbox){
            Object.assign(this.state, {sensorbox : {}})
        }
        Object.assign(this.state.sensorbox, { contractor_alert_on : event.target.checked })
        this.setState({sensorbox: this.state.sensorbox})
    }

    updateConsumerAlert = (event: ChangeEvent<HTMLInputElement>) => {
        if(!this.state.sensorbox){
            Object.assign(this.state, {sensorbox : {}})
        }
        Object.assign(this.state.sensorbox, { consumer_alert_on : event.target.checked })
        this.setState({sensorbox: this.state.sensorbox})
    }

    updateOfflineAlert = (event: ChangeEvent<HTMLInputElement>) => {
        if(!this.state.sensorbox){
            Object.assign(this.state, {sensorbox : {}})
        }
        Object.assign(this.state.sensorbox, { offline_alert_on : event.target.checked })
        this.setState({sensorbox: this.state.sensorbox})
    }

    updatePowerCalculationPermission = (event: ChangeEvent<HTMLInputElement>) => {
        if(!this.state.sensorbox){
            Object.assign(this.state, {sensorbox : {}})
        }
        Object.assign(this.state.sensorbox, { show_power_calculation : event.target.checked })
        this.setState({sensorbox: this.state.sensorbox})
    }

    updateFilterWidth = (event: ChangeEvent<HTMLInputElement>) => {
        if(!event.target.validity.valid) {
            return
        }
        if(!this.state.sensorbox){
            Object.assign(this.state, {sensorbox : {}})
        }
        let value = event.target.value;
        Object.assign(this.state.sensorbox, { filter_width : value })
        this.setState({sensorbox: this.state.sensorbox})
    }

    updateFilterLength = (event: ChangeEvent<HTMLInputElement>) => {
        if(!event.target.validity.valid) {
            return
        }
        if(!this.state.sensorbox){
            Object.assign(this.state, {sensorbox : {}})
        }
        let value = event.target.value;
        Object.assign(this.state.sensorbox, { filter_length : value })
        this.setState({sensorbox: this.state.sensorbox})
    }

    updateFilterThickness = (event: ChangeEvent<HTMLInputElement>) => {
        if(!event.target.validity.valid) {
            return
        }
        if(!this.state.sensorbox){
            Object.assign(this.state, {sensorbox : {}})
        }
        let value = event.target.value;
        Object.assign(this.state.sensorbox, { filter_thickness : value })
        this.setState({sensorbox: this.state.sensorbox})
    }

    updateFilterQuantity = (event: ChangeEvent<HTMLSelectElement>) => {
        if(!this.state.sensorbox){
            Object.assign(this.state, {sensorbox : {}})
        }
        let value = event.target.value;
        Object.assign(this.state.sensorbox, { filter_quantity : value })
        this.setState({sensorbox: this.state.sensorbox})
    }

    validateFilterSize = (fieldName: string, value: number) => {
        if(value !== 0) return;
        if(!this.state.sensorbox){
            Object.assign(this.state, {sensorbox : {}})
        }
        Object.assign(this.state.sensorbox, { [fieldName] : '' })
        this.setState({sensorbox: this.state.sensorbox})
    }

    getConsumerAddress = async(consumer:any)  =>{ 

        try{ 
            if(consumer){
                let consumerAddress = await userService.getCustomerAddressDetails(consumer._id)
                if(consumerAddress) {
                    let coordinates     = consumerAddress.location && consumerAddress.location.coordinates;
                    this.getTimeZone(coordinates)
                    let address         = `${consumerAddress?.street}, ${consumerAddress?.city}, ${consumerAddress?.state}, ${consumerAddress?.country}, ${consumerAddress?.zip_code}`;
                    if(coordinates){
                        let weatherStation = await userService.getWeatherInformation(coordinates)
                        Object.assign(this.state.sensorbox,{ place:address ,location :{ coordinates : coordinates} ,weather_station : weatherStation?.station_id , forecast_link : weatherStation?.forecast_link , grid_id : weatherStation?.grid_id , grid_x : weatherStation?.grid_x , grid_y : weatherStation?.grid_y}) 
                    }
                    else{
                        Object.assign(this.state.sensorbox,{ place : '',location :{ coordinates : []}, timezone : '' ,weather_station : '' , forecast_link : '' , grid_id : '' , grid_x : '' , grid_y : '' })
                    }
                }
                this.setState({sensorbox : this.state.sensorbox}); 
            }else{
                Object.assign(this.state.sensorbox,{ place : '',location :{ coordinates : []}, timezone : '' ,weather_station : '' , forecast_link : '' , grid_id : '' , grid_x : '' , grid_y : '' })
            } 
        }catch(error){
            toast.error(error.response.data?.message)
        } 
        
    }

    getCustomerList = async()  =>{     
        try{
            let consumerList = await userService.getCustomerList(this.props?.organisationId)
            if(!this.state.sensorbox){
                Object.assign(this.state, {sensorbox : {}})
            }    
            if(authStore?.currentUser?.active_role?.role_type === -1) {
                consumerList = consumerList.map((item: any, index :any) => ({...item, name: `Consumer ${index+1}`}))
            }

            this.setState({consumerList});
        }catch(error){
            toast.error(error.response.data.message)
        }       
        
    }

    async getRefrigerentList() {     
        try{
            let refrigerantList = await userService.getRefrigerentList();
            if(!this.state.sensorbox){
                Object.assign(this.state, {sensorbox : {}})
            }      
            this.setState({refrigerantList});
        }catch(error){
            toast.error(error.response.data.message)
        }       
        
    }

    async getIssueTrackerList(sensorboxDetails: any) {
        try{
            this.issueDetailsParams = {pageNumber: 1, limit: 10, orgId: '', status: ['OPEN', 'PENDING REVIEW'], consumer: [], contractor: [], service_resolved_by: [], searchFilter: '', startDate: '', endDate: '', groupId: '', }
            const issueListExist = await issueTrackerServices.getIssueTrackerDetails(this.issueDetailsParams.pageNumber, this.issueDetailsParams.limit, sensorboxDetails?.org_id, this.issueDetailsParams.status, this.issueDetailsParams.consumer, this.issueDetailsParams.contractor, this.issueDetailsParams.service_resolved_by, this.issueDetailsParams.searchFilter, this.issueDetailsParams.startDate, this.issueDetailsParams.endDate , this.issueDetailsParams?.groupId, sensorboxDetails?._id)
            this.setState({issueCount: issueListExist?.count});
        } catch(error){
            console.log(error)
        }

    }


    deleteSensorbox = async(HardDelete : boolean) => {
        try{
            if(this.state.sensorbox){
                if(HardDelete){
                    await  userService.hardDeleteSensorBox(this.state.sensorbox._id)
                }else{
                    await  userService.deleteSensorBox(this.state.sensorbox._id)    
                }    
                this.setState({isLoading : true})
                this.setState({isLoading : false},()=>{
                    this.closeForm()
                    let currentPage = this.props.shouldPageChange(false)
                    this.props.updateSensorBoxList(currentPage)
                })     
            }
        }catch(error){
            this.setState({isLoading : false})
            toast.error(error.response.data.message)
        }
    }

    enableSensorbox = async() =>{

        try{
            if(this.state.sensorbox){
                this.setState({isLoading : true})
                await  sensorService.enableSensorBox(this.state.sensorbox._id)
                this.setState({isLoading : false},()=>{
                        this.closeForm()
                        this.props.updateSensorBoxList()
                })                
            }

        }catch(error){
            toast.error(error.response.data.message)
        }
    }

    updateContractor = (data : any)=> {

        if(!this.state.sensorbox){
            Object.assign(this.state, {sensorbox : {}})
        }
       const contractorAndTeam = this.convertDropdownData(data,'r')

        Object.assign(this.state.sensorbox, { contractor : contractorAndTeam })
        this.setState({sensorbox : this.state.sensorbox},()=>{
            this.getContractorList()
        })
    }

    isAdmin() {
        return authStore.currentUser.role === constants.role["Admin"]
    }

    isFieldTech(){
        return authStore.currentUser.role === constants.role["Field Technician"]
    }

    async getContractorList() {       
        try{
            let contractorList = []
            let selectedItem;
            if(authStore?.currentUser?.access?.includes(view_contractor_sensorboxes_assets)) {

                    selectedItem = {_id : authStore.currentUser.uid, name : authStore.currentUser.name}
                    if(!this.state.sensorbox){
                        Object.assign(this.state, {sensorbox : {}})
                    }
                    Object.assign(this.state.sensorbox, { contractor : [selectedItem] })
                    this.setState({sensorbox: this.state.sensorbox});
                
            }else if(authStore?.currentUser?.access?.includes(view_assigned_users_sensorboxes_assets)){

                selectedItem = userStore.profileDetials && userStore.profileDetials.assigned_to;
                if(!this.state.sensorbox){
                    Object.assign(this.state, {sensorbox : {}})
                }
                Object.assign(this.state.sensorbox, { contractor : [selectedItem] })
                this.setState({sensorbox: this.state.sensorbox});
                
            }else{
                contractorList = await userService.getContractorList(this.props?.organisationId)
                if(!this.state.sensorbox){
                    Object.assign(this.state, {sensorbox : {}})
                }

                if(authStore?.currentUser?.active_role?.role_type === -1) {
                    contractorList = contractorList.map((item: any, index :any) => ({...item, name: `Contractor ${index+1}`}))
                }

                this.setState({contractorList});
            }                        

        }catch(error){
            toast.error(error.response.data.message)
        } 
    }

    thPinCombination = (sensorType : any, value : any) => {
        const { sensorTypeList , pinConfigList } = this.state;
        if(sensorType.sensor_name_abbr === "th_sensor1_db"){ // th pin1.
            sensorTypeList.forEach((type: any) => {
                    let returnHumidityPins = [...pinConfigList[17]]
                    let combinationPin = returnHumidityPins.find((pin: any) => pin.representation === value?.combination_pin);
                    let pin_type = '', representation = '', sub_type = '' ;
                    if(type.sensor_name_abbr === "th_sensor1_rh" && combinationPin){ // return humidity
                        pin_type = combinationPin?.type;
                        representation = combinationPin?.representation;
                        sub_type = combinationPin?.sub_type;

                        type.pin_type = pin_type;
                        type.representation = representation;
                        type.subtype = sub_type

                        const index = this.sensorArray.findIndex((sensor: any) => sensor.sensor_name === type?.sensor_name_abbr);

                        if (index >= 0) {
                            this.sensorArray[index].sensor_name = type?.sensor_name_abbr;
                            this.sensorArray[index].sub_type = sub_type;
                            this.sensorArray[index].representation = representation;
                            this.sensorArray[index].pin_type = pin_type;
                        }else{
                            let sensorObject: any = {
                                sensor_name: type?.sensor_name_abbr,
                                representation,
                                sensor_type: type,
                                pin_type
                            }
            
                            if(sub_type) sensorObject.sub_type = sub_type;            
                            this.sensorArray.push(sensorObject);
                        }  
                    }
            })
        }else if(sensorType.sensor_name_abbr === "th_sensor2_db"){
            sensorTypeList.forEach((type: any) => {
                let returnAndSupplyHumidityPins = [...pinConfigList[18]]
                let combinationPin = returnAndSupplyHumidityPins.find((pin: any) => pin.representation === value?.combination_pin);
                let pin_type = '', representation = '', sub_type = '' ; 
                if(type.sensor_name_abbr === "th_sensor2_rh" && combinationPin){

                    pin_type = combinationPin?.type;
                    representation = combinationPin?.representation;
                    sub_type = combinationPin?.sub_type;

                    type.pin_type = pin_type;
                    type.representation = representation;
                    type.subtype = sub_type

                    const index = this.sensorArray.findIndex((sensor: any) => sensor.sensor_name === type?.sensor_name_abbr);

                    if (index >= 0) {
                        this.sensorArray[index].sensor_name = type?.sensor_name_abbr;
                        this.sensorArray[index].sub_type = sub_type;
                        this.sensorArray[index].representation = representation;
                        this.sensorArray[index].pin_type = pin_type;
                    }else{
                        let sensorObject: any = {
                            sensor_name: type?.sensor_name_abbr,
                            representation,
                            sensor_type: type,
                            pin_type
                        }
        
                        if(sub_type) sensorObject.sub_type = sub_type;            
                        this.sensorArray.push(sensorObject);
                    }  
                }  
            })
        }
        else if(sensorType.sensor_name_abbr === "th_sensor1_rh"){
            sensorTypeList.forEach((type: any) => {
                let thPins = [...pinConfigList[3] , ...pinConfigList[4] , ...pinConfigList[19]]
                let combinationPin = thPins.find((pin: any) => pin.representation === value?.combination_pin);
                if(type.sensor_name_abbr === "th_sensor1_db" && combinationPin){
                    let pin_type = '', representation = '', sub_type = '' ; 

                    pin_type = combinationPin?.type;
                    representation = combinationPin?.representation;
                    sub_type = combinationPin?.sub_type;

                    type.pin_type = pin_type;
                    type.representation = representation;
                    type.subtype = sub_type

                    const index = this.sensorArray.findIndex((sensor: any) => sensor.sensor_name === type?.sensor_name_abbr);

                    if (index >= 0) {
                        this.sensorArray[index].sensor_name = type?.sensor_name_abbr;
                        this.sensorArray[index].sub_type = sub_type;
                        this.sensorArray[index].representation = representation;
                        this.sensorArray[index].pin_type = pin_type;
                    }else{
                        let sensorObject: any = {
                            sensor_name: type?.sensor_name_abbr,
                            representation,
                            sensor_type: type,
                            pin_type
                        }
        
                        if(sub_type) sensorObject.sub_type = sub_type;            
                        this.sensorArray.push(sensorObject);
                    }  
                }      
            })

        }
        else if(sensorType.sensor_name_abbr === "th_sensor2_rh"){
            sensorTypeList.forEach((type: any) => {
                let thPins = [...pinConfigList[3] , ...pinConfigList[4] , ...pinConfigList[19]]
                let combinationPin = thPins.find((pin: any) => pin.representation === value?.combination_pin);
                if(type.sensor_name_abbr === "th_sensor2_db" && combinationPin){
                    let pin_type = '', representation = '', sub_type = '' ; 

                    pin_type = combinationPin?.type;
                    representation = combinationPin?.representation;
                    sub_type = combinationPin?.sub_type;

                    type.pin_type = pin_type;
                    type.representation = representation;
                    type.subtype = sub_type

                    const index = this.sensorArray.findIndex((sensor: any) => sensor.sensor_name === type?.sensor_name_abbr);

                    if (index >= 0) {
                        this.sensorArray[index].sensor_name = type?.sensor_name_abbr;
                        this.sensorArray[index].sub_type = sub_type;
                        this.sensorArray[index].representation = representation;
                        this.sensorArray[index].pin_type = pin_type;
                    }else{
                        let sensorObject: any = {
                            sensor_name: type?.sensor_name_abbr,
                            representation,
                            sensor_type: type,
                            pin_type
                        }
        
                        if(sub_type) sensorObject.sub_type = sub_type;            
                        this.sensorArray.push(sensorObject);
                    }  
                }      
            })
        }
    }

    updatePinType = (sensorType: any, value: any) => {

        this.thPinCombination(sensorType, value)
        if (sensorType.is_configurable.includes(1)) {
            sensorType.pin_type = value;
            sensorType.representation = '';
            const index = this.sensorArray.findIndex((sensor: any) => sensor.sensor_name === sensorType.sensor_name_abbr);
            if (index >= 0) {
                this.sensorArray[index].pin_type = value;
                this.sensorArray[index].representation = '';
            }
        } else {

            sensorType.sub_type = value?.sub_type;
            sensorType.representation = value?.representation;
            sensorType.pin_type = value?.type;
            const index = this.sensorArray.findIndex((sensor: any) => sensor.sensor_name === sensorType.sensor_name_abbr);
            if (index >= 0) {
                this.sensorArray[index].sub_type = value?.sub_type;
                this.sensorArray[index].representation = value?.representation;
                this.sensorArray[index].pin_type = value?.type;
            }
        }
        Object.assign(this.state.sensorbox, { sensors: this.sensorArray })
        this.setState({ sensorbox: this.state.sensorbox })
    }

    selectAll = (event: ChangeEvent<HTMLInputElement>) => {
        const { sensorTypeList, pinConfigList, sensorbox } = this.state;
        if(event.target.checked) {
            sensorTypeList.forEach((type: any) => {
                const groundedCondtion = (this.state?.sensorbox?.hasOwnProperty('_id') && type?.sensor_name_abbr === 'gpio_pin6' && this.state.sensorbox?.version as any <= 3) ? false : type?.sensor_name_abbr === 'gpio_pin6'
                const combinationPinCondition = (sensorbox?.board_version as number >=6  && (type?.sensor_name_abbr === 'th_sensor1_rh' || type?.sensor_name_abbr === 'th_sensor2_rh'))
                let isInvalidPin = ( type?.sensor_name_abbr === 'blower_current' && sensorbox?.version !== 1 ) || groundedCondtion || combinationPinCondition                 
                const index = this.sensorArray.findIndex((s:any) => s.sensor_name === type.sensor_name_abbr)
                if(index < 0 && !isInvalidPin) {
                    let pin_type = '', representation = '', sub_type = '', calibration_c ,calibration_d , calibration_a ,calibration_b ; 
                    if(type?.is_configurable?.includes(0)) {
                        pin_type = type?.type;
                        representation =  type?.represent;
                    }else if(type?.is_configurable.includes(1)) {
                        pin_type = Object.keys(pinConfigList['1'])[0]
                        representation = pinConfigList['1'][pin_type][0]?.representation
                        sub_type = pinConfigList['1'][pin_type][0]?.sub_type
                        calibration_c = 1
                        calibration_a = 0
                        calibration_b = 0
                        calibration_d = 0

                    }else if(!type?.is_configurable.includes(0) && !type?.is_configurable.includes(1)) {
                        const options = type?.is_configurable?.reduce((a: any, c: string) => (pinConfigList[c] ? [...a, ...pinConfigList[c] ] : a), []);
                        this.thPinCombination(type, options[0])
                        pin_type = options[0]?.type;
                        representation = options[0]?.representation;
                        sub_type = options[0]?.sub_type;
                    }

                    type.pin_type = pin_type;
                    type.representation = representation;
                    
                    let sensorObject: any = {
                        sensor_name: type?.sensor_name_abbr,
                        representation,
                        sensor_type: type,
                        pin_type
                    }
                    if(sub_type) sensorObject.sub_type = sub_type;

                    if(calibration_c !== undefined ) sensorObject.calibration_c = calibration_c;
                    if(calibration_d !== undefined) sensorObject.calibration_d = calibration_d;

                    if(calibration_a !== undefined ) sensorObject.calibration_a = calibration_a;
                    if(calibration_b !== undefined) sensorObject.calibration_b = calibration_b;
    
                    this.sensorArray.push(sensorObject);
                }
            })
        }else {
            sensorTypeList.forEach((type: any) => {
                type.pin_type = '';
                type.representation = '';
                type.sub_type = '';
                if(type?.is_configurable?.includes(1)) {
                    type.calibration_c = 1
                    type.calibration_a = 0
                    type.calibration_b = 0
                    type.calibration_d = 0
                }
            })
            
            this.sensorArray = [];
        }
        Object.assign(this.state.sensorbox, { sensors: this.sensorArray })
        this.setState({ sensorbox: this.state.sensorbox })
    }

    deselectAll = (event: ChangeEvent<HTMLInputElement>) => {
        const { sensorTypeList } = this.state;
        sensorTypeList.forEach((type: any) => {
            type.pin_type = '';
            type.representation = '';
            type.sub_type = '';
            if(type?.is_configurable?.includes(1)) {
                type.calibration_c = 1
                type.calibration_a = 0
                type.calibration_b = 0
                type.calibration_d = 0
            }
        })
            
        this.sensorArray = [];
        Object.assign(this.state.sensorbox, { sensors: this.sensorArray })
        this.setState({ sensorbox: this.state.sensorbox })
    }

    updatePinRepresentation = (sensorType: any, value: any) => {
        sensorType.representation = value?.representation;
        sensorType.sub_type = value?.sub_type;

        const index = this.sensorArray.findIndex((sensor: any) => sensor.sensor_name === sensorType.sensor_name_abbr);
        if (index >= 0) {
            this.sensorArray[index].representation = value?.representation;
            this.sensorArray[index].sub_type = value?.sub_type;
        }
        Object.assign(this.state.sensorbox, { sensors: this.sensorArray })
        this.setState({ sensorbox: this.state.sensorbox })
    }

    onChangeDeploymentStatus = (date : any , type : any, action : any) =>{

        let isInvalid = true
        if(action === '' && date){
            isInvalid = false
        }else if(action === "clear" && date === null){
            isInvalid = false
        }

        Object.assign(this.state.sensorbox, { [type] : date ? date.getTime() : '' })
        this.setState({sensorbox : this.state.sensorbox, invalidDeploymentStatusDate : isInvalid})
    }


    updateSensorType = (e: any, sensor_type: any) => {

        if (!this.state.sensorbox) {
            Object.assign(this.state, { sensorbox: {} })
        }

        let sensor_name = ''
        let sensorObject = {} as any;
        let error = {} as any
        let isValid = true

        const { pinConfigList, sensorbox } = this.state;

        if (this.state.sensorbox && !this.state.sensorbox.sensor_box_name) {
            error.sensors = constants.message.sensor_name_mandatory
            isValid = false
            this.setState({ error })
            return isValid

        } else if (this.state.sensorbox && this.state.sensorbox.sensor_box_name && sensor_type) {

            sensor_name = sensor_type.sensor_name_abbr;
            sensorObject = {
                sensor_name: sensor_name,
                sensor_type: sensor_type,
            }
            if (e.target.checked && e.target.name !== 'gpio_pin4_energized') {
                if (!this.sensorArray.includes(sensorObject)) {
                    let pin_type = '', representation = '', sub_type = '';
                    if(sensor_type?.is_configurable.includes(0)){
                        pin_type = sensor_type?.type;
                        representation =  sensor_type?.represent;
                    }else if(sensor_type?.is_configurable.includes(1)) {
                        pin_type = Object.keys(pinConfigList['1'])[0]
                        representation = pinConfigList['1'][pin_type][0]?.representation
                        sub_type = pinConfigList['1'][pin_type][0]?.sub_type
                        sensorObject.calibration_c = 1
                        sensorObject.calibration_a = 0
                        sensorObject.calibration_b = 0
                        sensorObject.calibration_d = 0
                    }else if(!sensor_type?.is_configurable.includes(0) && !sensor_type?.is_configurable.includes(1)) {
                        const options = sensor_type?.is_configurable?.reduce((a: any, c: string) => (pinConfigList[c] ? [...a, ...pinConfigList[c] ] : a), []);
                        this.thPinCombination(sensor_type, options[0])
                        pin_type = options[0]?.type;
                        representation = options[0]?.representation;
                        sub_type = options[0]?.sub_type;
                    }
                    sensorObject.pin_type = pin_type;
                    sensorObject.representation = representation;
                    if(sub_type) sensorObject.sub_type = sub_type;

                    sensor_type.pin_type = pin_type;
                    sensor_type.representation = representation;
                    this.sensorArray.push(sensorObject);
                }
            }

            else if (["min", "max"].includes(e.target.name)) {
                sensor_type[e.target.name] = e.target.value;
                this.sensorArray.forEach((sensor: any) => {
                    if (sensor.sensor_name === sensor_type.sensor_name_abbr && sensor.sensor_type) {
                        sensor.sensor_type[e.target.name] = e.target.value
                    }
                })
            }


            else if (["calibration_c", "calibration_d", "calibration_a", "calibration_b"].includes(e.target.name)) {
                sensor_type[e.target.name] = e.target.value;
                this.sensorArray.forEach((sensor: any) => {
                    if (sensor.sensor_name === sensor_type.sensor_name_abbr) {
                        sensor[e.target.name] = e.target.value
                    }
                })
            }

            else if (e.target.name === 'gpio_pin4_energized') {

                this.swapSPI_Pins()  // to swap SPI_1 pin and SPI_2 pin values when the gpio4 energize checkbox is clicked

            } else if (!["min", "max", "calibration_c", "calibration_d", "calibration_a", "calibration_b"].includes(e.target.name)) {

                let currentSensorArray = this.state.sensorbox && this.state.sensorbox.sensors ? this.state.sensorbox.sensors : [];
               
                let current_array = currentSensorArray.filter((sensor_types: any) => {
                    let sensors: any = sensor_type;
                    return sensor_types.sensor_name !== sensors.sensor_name_abbr
                });

                if(e.target.name !== "thpincheckbox" && sensorbox?.board_version as number >= 6){
                    if(sensor_type.sensor_name_abbr === "th_sensor1_db"){

                        current_array = current_array.filter((sensor_types: any) => {
                            return sensor_types.sensor_name !== "th_sensor1_rh"
                        });

                        const { sensorTypeList } = this.state

                        sensorTypeList.forEach((sensor_type: any) => {
                            if(sensor_type.sensor_name_abbr === "th_sensor1_rh"){
                                sensor_type.pin_type = '';
                                sensor_type.sub_type = '';
                                sensor_type.representation = '';
                                if (sensor_type.max !== undefined) {
                                    sensor_type.max = '';
                                }
                
                                if (sensor_type.min !== undefined) {
                                    sensor_type.min = '';
                                }

                            }      
                        })
                    }else if(sensor_type.sensor_name_abbr === "th_sensor2_db"){
                        current_array = current_array.filter((sensor_types: any) => {
                            return sensor_types.sensor_name !== "th_sensor2_rh"
                        });

                        const { sensorTypeList } = this.state

                        sensorTypeList.forEach((sensor_type: any) => {
                            if(sensor_type.sensor_name_abbr === "th_sensor2_rh"){
                                sensor_type.pin_type = '';
                                sensor_type.sub_type = '';
                                sensor_type.representation = '';
                                if (sensor_type.max !== undefined) {
                                    sensor_type.max = '';
                                }
                
                                if (sensor_type.min !== undefined) {
                                    sensor_type.min = '';
                                }

                            }      
                        })

                    }else if(sensor_type.sensor_name_abbr === "th_sensor1_rh"){
                        current_array = current_array.filter((sensor_types: any) => {
                            return sensor_types.sensor_name !== "th_sensor1_db"
                        });

                        const { sensorTypeList } = this.state

                        sensorTypeList.forEach((sensor_type: any) => {
                            if(sensor_type.sensor_name_abbr === "th_sensor1_db"){
                                sensor_type.pin_type = '';
                                sensor_type.sub_type = '';
                                sensor_type.representation = '';
                                if (sensor_type.max !== undefined) {
                                    sensor_type.max = '';
                                }
                
                                if (sensor_type.min !== undefined) {
                                    sensor_type.min = '';
                                }

                            }      
                        })

                    }else if(sensor_type.sensor_name_abbr === "th_sensor2_rh"){
                        current_array = current_array.filter((sensor_types: any) => {
                            return sensor_types.sensor_name !== "th_sensor2_db"
                        });

                        const { sensorTypeList } = this.state

                        sensorTypeList.forEach((sensor_type: any) => {
                            if(sensor_type.sensor_name_abbr === "th_sensor2_db"){
                                sensor_type.pin_type = '';
                                sensor_type.sub_type = '';
                                sensor_type.representation = '';
                                if (sensor_type.max !== undefined) {
                                    sensor_type.max = '';
                                }
                
                                if (sensor_type.min !== undefined) {
                                    sensor_type.min = '';
                                }

                            }      
                        })

                    }
                }  
                

                this.sensorArray = current_array

                sensor_type.pin_type = '';
                sensor_type.sub_type = '';
                sensor_type.representation = '';

                if (sensor_type.max !== undefined) {
                    sensor_type.max = '';
                }

                if (sensor_type.min !== undefined) {
                    sensor_type.min = '';
                }

                if (sensor_type.calibration_c !== undefined) {
                    sensor_type.calibration_c = 1;
                }

                if (sensor_type.calibration_d !== undefined) {
                    sensor_type.calibration_d = 0;
                }

                if (sensor_type.calibration_a !== undefined) {
                    sensor_type.calibration_a = 0;
                }

                if (sensor_type.calibration_b !== undefined) {
                    sensor_type.calibration_b = 0;
                }

                if (e.target.name === 'gpio_pin4') {
                    this.swapSPI_Pins()
                }

            }

            Object.assign(this.state.sensorbox, { sensors: this.sensorArray })
            this.setState({ sensorbox: this.state.sensorbox ,validRangeError : '', validCalibrationError : '' })
        }
    }

       swapSPI_Pins = () => {

        let spi_pin1_Obj: any, spi_pin2_Obj: any
        
        this.sensorArray.forEach((sensor: any) => {
            if (sensor.sensor_name === "spi_pin1") {
                spi_pin1_Obj = Object.assign({}, sensor);
            } else if (sensor.sensor_name === "spi_pin2") {
                spi_pin2_Obj = Object.assign({}, sensor);
            }
        })

           if (this.checkEnergizedCondition(spi_pin1_Obj, spi_pin2_Obj)) {
               this.sensorArray.forEach(async (sensor: any) => {

                if (sensor.sensor_name === "spi_pin1" || sensor.sensor_name === "spi_pin2") {

                    const isSPI_Pin1 = sensor.sensor_name === "spi_pin1"
                    sensor.representation = isSPI_Pin1 ? spi_pin2_Obj.representation : spi_pin1_Obj.representation
                    sensor.sub_type = isSPI_Pin1 ? spi_pin2_Obj.sub_type : spi_pin1_Obj.sub_type
                    
                    const pin = this.state.sensorTypeList?.find((s: any) => s?.sensor_name_abbr === sensor.sensor_name);
                    pin.representation = isSPI_Pin1 ? spi_pin2_Obj.representation : spi_pin1_Obj.representation;
                    pin.sub_type = isSPI_Pin1 ? spi_pin2_Obj.sub_type : spi_pin1_Obj.sub_type

                }
            })
        }
    }

    checkEnergizedCondition = (spi_pin1_Obj: any, spi_pin2_Obj: any) => {
        return (spi_pin1_Obj && spi_pin2_Obj && (
            (spi_pin1_Obj.sub_type === 'liquid_temp' && spi_pin2_Obj.sub_type === 'suction_temp') ||
            (spi_pin1_Obj.sub_type === 'suction_temp' && spi_pin2_Obj.sub_type === 'liquid_temp')
        ))
    }

    async getSensorTypeList() {
        try {
            let sensorTypeList = await userService.getSensorTypeList()
            if (!this.state.sensorbox) {
                Object.assign(this.state, { sensorbox: {} })
            }

            let sortedPinsBasedOnBoardVersions = sensorTypeList?.map((item: any, index: any) => {
                let pin_order = item.pin_order.find((pin: any) => {
                    return pin.board_version === this.state.sensorbox?.board_version
                })
                return pin_order ? {...item, pinOrder: pin_order?.order} : item 
            }).sort((a: any, b: any) => (a?.pinOrder - b?.pinOrder))

            await this.sensorType(sortedPinsBasedOnBoardVersions)
        } catch (error) {
            // toast.error(error.response.data.message)
        }
    }

    async getSensorTemplates() {
        try {
            let sensorTemplates = await userService.getSensorTemplates()
            if (!this.state.sensorbox) {
                Object.assign(this.state, { sensorbox: {} })
            }

            this.setState({ sensorTemplates:  sensorTemplates });
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }

    async sensorType (sensorTypeList : any){
        sensorTypeList.forEach((sensorType: any) => {
            if (this.state.sensorbox && this.state.sensorbox.sensors) {
                let sensor = this.state.sensorbox.sensors.filter((sensor: any) => sensor.sensor_name === sensorType.sensor_name_abbr)[0]
                if (sensorType.min !== undefined && sensorType.max !== undefined) {
                    sensorType.min = (sensor && sensor.sensor_type && sensor.sensor_type.min) ? sensor.sensor_type.min : '';
                    sensorType.max = (sensor && sensor.sensor_type && sensor.sensor_type.min) ? sensor.sensor_type.max : '';
                    sensorType.calibration_c = (sensor?.calibration_c !== undefined ) ? sensor.calibration_c : 1 ;
                    sensorType.calibration_a = (sensor?.calibration_a !== undefined ) ? sensor.calibration_a : 0 ;
                    sensorType.calibration_b = (sensor?.calibration_b !== undefined ) ? sensor.calibration_b : 0 ;
                    sensorType.calibration_d = (sensor?.calibration_d !== undefined ) ? sensor.calibration_d : 0 ;
                }
                sensorType.pin_type = (sensor && sensor.pin_type) ? sensor.pin_type : '';
                sensorType.representation = (sensor && sensor.representation) ? sensor.representation :  '';
                sensorType.sub_type = (sensor && sensor.sub_type) ? sensor.sub_type : '';
            }
            else if(sensorType?.is_configurable?.includes(1)) {
                sensorType.calibration_c = 1
                sensorType.calibration_a = 0
                sensorType.calibration_b = 0
                sensorType.calibration_d = 0
            }
        })
        this.setState({ sensorTypeList: JSON.parse(JSON.stringify(sensorTypeList)), sensorTypeListCopy: JSON.parse(JSON.stringify(sensorTypeList)) });
    }

    async getPinConfiguration() {
        try {
            const resp = await pinConfigurationService.getPinConfiguation();
            if (resp) {
                const { pin_configuration } = resp.data;
                const i2cCombinationPins = pin_configuration?.filter((config: any) => config.pin_type === 19); //combination pin type i2c sh and th
                const types: any = _.groupBy(pin_configuration, 'pin_type');
                types['1'] = [...types['1'] , ...i2cCombinationPins] // merge i2c combination pins with i2c types
                types['1'] = _.groupBy(types['1'], 'type');

                for (const key in types['1']){
                    types['1'][key]?.sort((a:any, b:any) => {
                        const name_a  = a?.display_name ?? ''
                        const name_b  = b?.display_name ?? ''
                        const aIsAlphabetical = name_a.localeCompare("a") >= 0
                        const bIsAlphabetical = name_b.localeCompare("a") >= 0
                        if (!aIsAlphabetical && bIsAlphabetical)
                            return 1;
                        if (aIsAlphabetical && !bIsAlphabetical)
                            return -1;
                        return name_a.localeCompare(name_b);
                    })
                }

                this.setState({ pinConfigList: types })
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
        }
    }


    async getSensorBoxDetails(id: string){        
        try{
            let sensorTypeList = await userService.getSensorBoxList(id)
            if(!this.state.sensorbox){
                Object.assign(this.state, {sensorbox : {}})
            }        
            this.setState({sensorTypeList});       

        }catch(error){
            toast.error(error.response.data.message)
        }
    }

    closeForm = () => {   
        this.setState({sensorbox : undefined,isEdit : false,consumerList : [],contractorList:[],sensorTypeList:[],tabhomeactive : true,tabactive : false},()=>{
            if(Utility.checkModalOpen('confirmmodal')) {
                Utility.closeModal('confirmmodal')
            }
            this.props.setVisibleClose()
            Utility.closeModal('sensorboxdetails')
            this.props.clearSelectedSensorBox()
        })
    }

    showDeleteConfirm() {
        Utility.closeModal('sensorboxdetails')        
        Utility.showModal('confirmmodal')
    }

    closeConfirmModal(){
        Utility.closeModal('confirmmodal')
        Utility.showModal('sensorboxdetails')

    }
    


    isSelected(){
        return this.state.sensorbox !== undefined && this.state.sensorbox._id !== undefined && !this.state.isEdit
    }
    

    isValidSensorDetails(){
        let sensorbox       =   this.state.sensorbox
        let error           =   {} as any
        let isValid         =   true
       
        if(sensorbox ) {
            if(validate.isEmpty(sensorbox.sensor_box_name)){
                error.sensor_box_name =  constants.message.sensor_name_mandatory
                isValid    =  false
            }
            if(!validate.isValidCharacter(sensorbox.sensor_box_name)){
                error.sensor_box_name = constants.message.box_name_valid
                isValid    =  false
            }        

            if(!sensorbox.category){
                error.category = constants.message.sensorbox_category_required
                isValid    =  false
            }

            if(!sensorbox.device_type){
                error.device_type = constants.message.device_type_required
                isValid    =  false
            }

            if(!sensorbox.board_version){
                error.board_version = constants.message.board_version_required
                isValid    =  false
            }

            if(!sensorbox.heat_provided_by){
                error.heat_provided_by = constants.message.heat_provided_by_required
                isValid    =  false
            }
    
            if(sensorbox.heat_provided_by === 'heat-pump'){
                if(!sensorbox.rv_mode){
                    error.rv_mode = constants.message.rv_mode_required
                    isValid    =  false
                }
            }
            

            if(!validate.isValidModel(sensorbox.system_model)){
                error.system_model = constants.message.system_model_valid
                isValid    =  false
            }

            if(!validate.isValidModel(sensorbox.evaporator_motor_model)){
                error.evaporator_motor_model = constants.message.name_valid
                isValid    =  false
            }

            if(!validate.isValidModel(sensorbox.condensor_motor_model)){
                error.condensor_motor_model = constants.message.name_valid
                isValid    =  false
            }

            if(sensorbox.compressor_model && !validate.isValidModel(sensorbox.compressor_model) ){
                error.compressor_model = constants.message.name_valid
                isValid    =  false
            }

            if(!validate.isValidModel(sensorbox.expansion_valve_model)){
                error.expansion_valve_model = constants.message.name_valid
                isValid    =  false
            }
            

            if(sensorbox.nominal_cooling_capacity && !validate.isValidValue(sensorbox.nominal_cooling_capacity)) {
                error.nominal_cooling_capacity = constants.message.capacity_value_invalid
                isValid    =  false
            }

            if(sensorbox.nominal_heating && !validate.isValidValue(sensorbox.nominal_heating)) {
                error.nominal_heating = constants.message.heating_value_invalid
                isValid    =  false
            }


            if((sensorbox.location && (sensorbox.location.coordinates[0]) && validate.isEmpty( sensorbox.location.coordinates[1] ))){
                error.location =  constants.message.location_mandatory
                isValid    =  false
            }

            if(sensorbox.location && (validate.isEmpty(sensorbox.location.coordinates[0]) && (sensorbox.location.coordinates[1]))){
                error.location =  constants.message.location_mandatory
                isValid    =  false
            }
            if(sensorbox.sensors) {
                sensorbox.sensors.forEach(sensor => {
                    if(sensor.sensor_type && sensor.sensor_type.min !== undefined && sensor.sensor_type.max !== undefined) {
                        if((sensor.sensor_type.min === '' && sensor.sensor_type.max.length > 0 ) || (sensor.sensor_type.min.length > 0 && sensor.sensor_type.max === '' )) {
                            this.setState({validRangeError : constants.message.max_min_value_mandatory+` for ${sensor.sensor_name} sensor.`})
                            isValid         = false
                        }
                        else if((sensor.sensor_type.min !== '' &&  sensor.sensor_type.max !== '')) {
                            if(isNaN(parseFloat(sensor.sensor_type.min)) || isNaN(parseFloat(sensor.sensor_type.max ))) {
                                this.setState({validRangeError : constants.message.max_min_invalid_value+` for ${sensor.sensor_name} sensor.`})
                                isValid         = false
                            }
                            else if (parseFloat(sensor.sensor_type.min) >= parseFloat(sensor.sensor_type.max)) {
                                this.setState({validRangeError : constants.message.max_must_be_greater_than_min+` for ${sensor.sensor_name} sensor.`})
                                isValid         = false
                            }
                            
                        }
                    }

                    if(sensor && sensor.calibration_c && sensor.calibration_c !== undefined ) {

                        if(!(validate.isValidCalibration(String(sensor.calibration_c)))){
                            this.setState({validCalibrationError : constants.message.calibration_must_be_range+` for ${sensor.sensor_name} sensor.`})
                            isValid         = false
                        }

                    }

                    if(sensor && sensor.calibration_d  && sensor.calibration_d !== undefined ) {

                        if(!(validate.isValidCalibration(String(sensor.calibration_d)))){
                            this.setState({validCalibrationError : constants.message.calibration_must_be_range+` for ${sensor.sensor_name} sensor.`})
                            isValid         = false
                        }

                    }

                    if(sensor && sensor.calibration_a && sensor.calibration_a !== undefined ) {

                        if(!(validate.isValidCalibration(String(sensor.calibration_a)))){
                            this.setState({validCalibrationError : constants.message.calibration_must_be_range+` for ${sensor.sensor_name} sensor.`})
                            isValid         = false
                        }

                    }

                    if(sensor && sensor.calibration_b && sensor.calibration_b !== undefined ) {

                        if(!(validate.isValidCalibration(String(sensor.calibration_b)))){
                            this.setState({validCalibrationError : constants.message.calibration_must_be_range+` for ${sensor.sensor_name} sensor.`})
                            isValid         = false
                        }

                    }
                })
            }
            if(this.state.invalidDeploymentStatusDate){
                isValid    =  false
            }
            
        }     
        this.setState({error})
        return isValid
       
    }

    isFormat() {
        if(this.state.sensorbox){
            Object.entries(this.state.sensorbox).forEach(item=>{                
                if(typeof item[1] ==="string") {
                    Object.assign(this.state.sensorbox,{[item[0]] : item[1].trim()})
                }else{
                    Object.assign(this.state.sensorbox,{[item[0]] : item[1]})
                }
            }) 
        }
    }

    isValidFormatValue = async() => {
        let error           =   {} as any
        let isValid         =   true;

        if(this.state.sensorbox ) {

            if(!validate.isValidFormatValue(this.state.sensorbox.nominal_cooling_capacity) ) {              
                error.nominal_cooling_capacity = constants.message.capacity_value_invalid
                isValid = false;
            }
            if(!validate.isValidFormatValue(this.state.sensorbox.nominal_heating) ) {
                error.nominal_heating = constants.message.heating_value_invalid
                isValid = false;
            }
            if(!validate.isValidAirflowFormat(this.state.sensorbox.airflow) ) {
                error.airflow = constants.message.airflow_invalid
                isValid = false;
            } 
            if(this.state.sensorbox.place) {
                let addressCheck = await this.addressCheck();
                if(!addressCheck){
                    error.place = constants.message.address_valid
                    isValid = false;
                } 
            }                                
            
        }
        this.setState({error})
        return isValid;

    }

    addressCheck = async () => {
        let error = {} as any
        let addressValid = await this.coordinates();
        if(addressValid) {           
            return true;           
        } else{
            error.place = constants.message.address_valid
            this.setState({error}); 
            return false;
        }              
       
    }

    validatePinConfiguration = () => {
        const errors: any = {};
        const { sensorbox } = this.state
        sensorbox?.sensors?.forEach((sensor: any) => {
            if (!sensor?.representation) {
                errors[sensor.sensor_name] = 'Select pin configuration';
            }
        })
        this.setState({ pinError: errors });
        return !Object.keys(errors).some(field => field !== '');
    }

    checkSensor = async() =>{
        
        let isSensorExist = await this.sensorBoxExist(); 
        if(!isSensorExist){           
            let validFormat =  await this.isValidFormatValue();
            if(validFormat) {
                this.isFormat();  
                let validSensorDetails = await this.isValidSensorDetails();
                let pinValid = await this.validatePinConfiguration();
                if(validSensorDetails && pinValid){
                    return true;
                }else{
                    return false;
                }
            
            }else {
                return false
            }            
        }
        return false;
    }

    setSensorNameBasedonSensorBoxName() {
        if(this.state.sensorbox && this.state.sensorbox.sensors) {
            this.state.sensorbox.sensors.forEach((item)=>{
                let sensorBoxName = this.state.sensorbox?this.state.sensorbox.sensor_box_name:"";
                item.sensor_name = sensorBoxName + item.sensor_type.sensor_name_abbr;
            })
        }
    }

    reconnetToSSE = async () => {
        await eventStore.connectEventSource();
        this.props.updateEventSourceListener();
    }

    async moveSensorBox(){
        this.setState({ showMoveSensorboxModal :true })
        Utility.closeModal('sensorboxdetails')
    }

    closeMoveSensorboxModal = (orgName : any) => {
        if(this.state.sensorbox){
            toast.success(`Moved the ${this.state.sensorbox?.sensor_box_name} to ${orgName} organization`)
            this.setState({isLoading : true})
            this.setState({isLoading : false , showMoveSensorboxModal :false},()=>{
                this.closeForm()
                let currentPage = this.props.shouldPageChange(false)
                this.props.updateSensorBoxList(currentPage)
            })                
        }
    }

    closeMoveSensorsModal = () => {
        this.setState({ showMoveSensorboxModal :false })
        Utility.showModal('sensorboxdetails')
    }
    validateRev7a(sensors:any){
        if(sensors.some(x=>Object.values(x).includes('select'))){
            toast.error('incomplete sensor configuration exists!')
            return false
        }
        return true
    }
    async saveSensors(action = ''){
        //note : filter sensors if you are changing versions while editing sensorboxes

        let isAddOperation = false;
        let currentPage = 0;

       if(this.state.sensorbox?.board_version == 7.1) {
           if (!this.state.sensorbox.boards?.map((x: { sensors: any[]; }) => x.sensors)
               .map((x: any) => validateRev7aSensorState(x))
               .every((x: boolean) => x)) {
               return
           }
           let duplicateSensorBoxExists = await this.sensorBoxExist();
           if(!duplicateSensorBoxExists && this.state.sensorbox && this.state.sensorbox._id) {
               this.setState({ saveConfirmationModal :true , saveType : action , isLoading : false})
               Utility.closeModal('sensorboxdetails')
           }else{
               const msg = await userService.createRev7aSensorBox(this.state.sensorbox, action === 'send' ,this.props?.organisationId);
               toast.success(msg)
               await this.reconnetToSSE();
               this.setState({isLoading : false},()=>{
                   if(action === 'export') this.exportConfigHandler(this.state.sensorbox)
                   else this.closeForm()
                   this.props.updateSensorBoxList(this.props.shouldPageChange(false))
               })
           }
       }else{
           const sensors = this.state.sensorbox?.sensors?.filter((sensor: any) => this.state.sensorTypeList?.find((sensorType:any)=>(sensorType?.sensor_name_abbr === sensor?.sensor_name))?.board_version?.includes(this.state.sensorbox?.board_version))
           Object.assign(this.state.sensorbox, { sensors : sensors })
           this.setState({sensorbox : this.state.sensorbox})

           try{
               this.setState({isLoading : true})
               let sensorExist = await this.checkSensor();
               if(sensorExist){

                   if(this.state.sensorbox && this.state.sensorbox._id) {
                       this.setState({ saveConfirmationModal :true , saveType : action , isLoading : false})
                       Utility.closeModal('sensorboxdetails')

                   }else {
                       // note : temp solution for the org admin access to all org
                       await userService.createSensorBox(this.state.sensorbox, action === 'send' ,this.props?.organisationId);
                       await this.reconnetToSSE();
                       isAddOperation = true;
                       this.setState({isLoading : false},()=>{
                           if(isAddOperation) {
                               currentPage = this.props.shouldPageChange(false)
                           }
                           if(action === 'export'){
                               this.exportConfigHandler(this.state.sensorbox)
                           }else{
                               this.closeForm()
                           }
                           this.props.updateSensorBoxList(currentPage)
                       })
                   }
               }
               else{
                   this.setState({isLoading : false})
               }

           }catch(error){
               this.setState({isLoading : false})
               toast.error(error?.response?.data?.message)
           }
       }
    }

    toggleEdit() {
        const { sensorTypeListCopy } = this.state

        let userAccessArray = this.state?.consumerList?.filter((item:any)=>(
            this.props.sensorbox?.user_access?.includes(item?._id)
        ))  
            
        let userAccessObject = userAccessArray?.map((item:any)=>({value:item._id,label:item.name})) 

        let category = categoryOptions.find((value:any)=>(value._id === this.props.sensorbox?.category)) 
        this.sensorArray = this.state.sensorbox && this.state.sensorbox.sensors ? this.state.sensorbox.sensors : [];
        this.setState({
            isEdit       : !this.state.isEdit,
            invalidDeploymentStatusDate : false,
            sensorbox    : {...JSON.parse(JSON.stringify(this.props.sensorbox)) ,category : category ,board_version : this.props.sensorbox?.board_version , userAccessObject},
            sensorTypeList: JSON.parse(JSON.stringify(sensorTypeListCopy)),
            error        : {} as any,    
        })
    }

    showPillTab = () => {
        this.setState({tabactive :!this.state.tabactive,tabhomeactive :!this.state.tabhomeactive});
    }

    showPillTabHome = () => {
        this.setState({tabactive :!this.state.tabactive,tabhomeactive :!this.state.tabhomeactive},() =>{
        });
        
    }


    getNewSensorBoxName = async() => {
          
        
        let sensorboxcount = await userService.getNewSensorBoxName();
        let sensorName = 'SYS' + sensorboxcount?.toString().padStart(8, '0');
        
        Object.assign(this.state.sensorbox,{sensor_box_name : sensorName });
        this.setState({sensorbox : this.state.sensorbox})
    

    }

    clearSensorTypes = () => {
        const { sensorTypeList } = this.state
        sensorTypeList.forEach((type: any) => {
            type.pin_type = '';
            type.representation = '';
            type.sub_type = '';
            if(type?.is_configurable?.includes(1)) {
                type.calibration_c = 1
                type.calibration_a = 0
                type.calibration_b = 0
                type.calibration_d = 0
            }
        })
    } 

    updateTemplateForBoardVersion = (template: any) => {
        this.clearSensorTypes()
        this.sensorArray = [];
        const { sensorTypeList, pinConfigList } = this.state;
        template.config.forEach((pinConfig: any) => {
            const type = sensorTypeList?.find((type : any) => (pinConfig.sensor_name  === type.sensor_name_abbr));
            let pin_type = '', representation = '', sub_type = '', calibration_c ,calibration_d , calibration_a ,calibration_b ; 
            if(type?.is_configurable?.includes(0)) {
                pin_type = pinConfig.pin_type;
                representation =  pinConfig.representation;
            }else if(type?.is_configurable.includes(1)) {
                pin_type = pinConfig.pin_type
                representation = pinConfig.representation
                sub_type = pinConfig.sub_type
                calibration_c = 1
                calibration_a = 0
                calibration_b = 0
                calibration_d = 0

            }else if(!type?.is_configurable.includes(0) && !type?.is_configurable.includes(1)) {
                let thPins = [...pinConfigList[3] , ...pinConfigList[4] , ...pinConfigList[19]]
                let thPinValue = thPins.find((pin:any)=>(pin.representation === pinConfig.representation)) 
                if(thPinValue !== undefined){
                    this.thPinCombination(type, thPinValue)
                }
                pin_type = pinConfig.pin_type;
                representation = pinConfig.representation;
                sub_type = pinConfig.sub_type;
            }

            type.pin_type = pin_type;
            type.representation = representation;
                
            let sensorObject: any = {
                sensor_name: type?.sensor_name_abbr,
                representation,
                sensor_type: type,
                pin_type
            }

            if(sub_type) sensorObject.sub_type = sub_type;

            if(calibration_c !== undefined ) sensorObject.calibration_c = calibration_c;
            if(calibration_d !== undefined) sensorObject.calibration_d = calibration_d;

            if(calibration_a !== undefined ) sensorObject.calibration_a = calibration_a;
            if(calibration_b !== undefined) sensorObject.calibration_b = calibration_b;

            this.sensorArray.push(sensorObject);
        })
        
        Object.assign(this.state.sensorbox, { sensors: this.sensorArray })
        this.setState({ sensorbox: this.state.sensorbox })   
    }

    clickPinNumberOnBoard = (checked : any,item: any) => {
        const { sensorTypeList, pinConfigList, sensorbox } = this.state;
        let sensor_name = ''
        let sensorObject = {} as any;
        sensorTypeList.forEach((sensor_type: any) => {
            if(sensor_type.sensor_name_abbr === item.sensor_name_abbr){
                sensor_name = sensor_type.sensor_name_abbr;
                if(checked){
                    sensorObject = {
                        sensor_name: sensor_name,
                        sensor_type: sensor_type,
                    }
                    let pin_type = '', representation = '', sub_type = '';
                    if(sensor_type?.is_configurable.includes(0)){
                        pin_type = sensor_type?.type;
                        representation =  sensor_type?.represent;
                    }else if(sensor_type?.is_configurable.includes(1)) {
                        pin_type = Object.keys(pinConfigList['1'])[0]
                        representation = pinConfigList['1'][pin_type][0]?.representation
                        sub_type = pinConfigList['1'][pin_type][0]?.sub_type
                        sensorObject.calibration_c = 1
                        sensorObject.calibration_a = 0
                        sensorObject.calibration_b = 0
                        sensorObject.calibration_d = 0
                    }else if(!sensor_type?.is_configurable.includes(0) && !sensor_type?.is_configurable.includes(1)) {
                        const options = sensor_type?.is_configurable?.reduce((a: any, c: string) => (pinConfigList[c] ? [...a, ...pinConfigList[c] ] : a), []);
                        this.thPinCombination(sensor_type, options[0])
                        pin_type = options[0]?.type;
                        representation = options[0]?.representation;
                        sub_type = options[0]?.sub_type;
                    }
                    sensorObject.pin_type = pin_type;
                    sensorObject.representation = representation;
                    if(sub_type) sensorObject.sub_type = sub_type;

                    sensor_type.pin_type = pin_type;
                    sensor_type.representation = representation;
                    this.sensorArray.push(sensorObject);     
                }else{

                    let currentSensorArray = this.state.sensorbox && this.state.sensorbox.sensors ? this.state.sensorbox.sensors : [];
               
                    let current_array = currentSensorArray.filter((sensor_types: any) => {
                        let sensors: any = sensor_type;
                        return sensor_types.sensor_name !== sensors.sensor_name_abbr
                    });

                    if(sensorbox?.board_version  as number >= 6){
                        if(sensor_type.sensor_name_abbr === "th_sensor1_db"){

                            current_array = current_array.filter((sensor_types: any) => {
                                return sensor_types.sensor_name !== "th_sensor1_rh"
                            });
                
                            const { sensorTypeList } = this.state
                
                            sensorTypeList.forEach((sensor_type: any) => {
                                if(sensor_type.sensor_name_abbr === "th_sensor1_rh"){
                                    sensor_type.pin_type = '';
                                    sensor_type.sub_type = '';
                                    sensor_type.representation = '';
                
                                }      
                            })
                        }else if(sensor_type.sensor_name_abbr === "th_sensor2_db"){
                            current_array = current_array.filter((sensor_types: any) => {
                                return sensor_types.sensor_name !== "th_sensor2_rh"
                            });
                
                            const { sensorTypeList } = this.state
                
                            sensorTypeList.forEach((sensor_type: any) => {
                                if(sensor_type.sensor_name_abbr === "th_sensor2_rh"){
                                    sensor_type.pin_type = '';
                                    sensor_type.sub_type = '';
                                    sensor_type.representation = '';
                
                                }      
                            })
                
                        }else if(sensor_type.sensor_name_abbr === "th_sensor1_rh"){
                            current_array = current_array.filter((sensor_types: any) => {
                                return sensor_types.sensor_name !== "th_sensor1_db"
                            });
                
                            const { sensorTypeList } = this.state
                
                            sensorTypeList.forEach((sensor_type: any) => {
                                if(sensor_type.sensor_name_abbr === "th_sensor1_db"){
                                    sensor_type.pin_type = '';
                                    sensor_type.sub_type = '';
                                    sensor_type.representation = '';
                
                                }      
                            })
                
                        }else if(sensor_type.sensor_name_abbr === "th_sensor2_rh"){
                            current_array = current_array.filter((sensor_types: any) => {
                                return sensor_types.sensor_name !== "th_sensor2_db"
                            });
                
                            const { sensorTypeList } = this.state
                
                            sensorTypeList.forEach((sensor_type: any) => {
                                if(sensor_type.sensor_name_abbr === "th_sensor2_db"){
                                    sensor_type.pin_type = '';
                                    sensor_type.sub_type = '';
                                    sensor_type.representation = '';
                
                                }      
                            })
                
                        }
                    }

                    sensor_type.pin_type = '';
                    sensor_type.sub_type = '';
                    sensor_type.representation = '';

                    if (sensor_type.max !== undefined) {
                        sensor_type.max = '';
                    }

                    if (sensor_type.min !== undefined) {
                        sensor_type.min = '';
                    }

                    if (sensor_type.calibration_c !== undefined) {
                        sensor_type.calibration_c = 1;
                    }

                    if (sensor_type.calibration_d !== undefined) {
                        sensor_type.calibration_d = 0;
                    }

                    if (sensor_type.calibration_a !== undefined) {
                        sensor_type.calibration_a = 0;
                    }

                    if (sensor_type.calibration_b !== undefined) {
                        sensor_type.calibration_b = 0;
                    }

                    this.sensorArray = current_array
                    
                }  
            }  
        })
        
        Object.assign(this.state.sensorbox, { sensors: this.sensorArray })
        this.setState({ sensorbox: this.state.sensorbox })   
    }


    exportConfigHandler = async(sensorbox? : SensorBox) => {
        const { sensorTypeList } = this.state;
        const sensors = this.sensorArray.slice();
        if(!sensorbox?.sensor_box_name || !sensors?.length) return
        
        const excludedPins = ['sc', 'sh', 'blower_current']
        const filteredSensorTypeList = sensorTypeList.filter((sensor: any) => sensor.board_version?.includes(this.state.sensorbox?.board_version))
        const allPins = filteredSensorTypeList.map((type: any) => type?.sensor_name_abbr).filter(type => !excludedPins.includes(type))
        
        const pins = sensors.filter((sensor: any) => !excludedPins.includes(sensor.sensor_name) && this.state.sensorTypeList?.find((sensorType:any)=>(sensorType?.sensor_name_abbr === sensor?.sensor_name))?.board_version?.includes(this.state.sensorbox?.board_version))
        .map((sensor: any) => {
            let pin: any = { pin: sensor.sensor_name, type: sensor.pin_type };
            if(sensor?.sub_type) pin.sub_type = sensor?.sub_type

            if(sensor?.calibration_a !== undefined && sensor?.calibration_a !== null && sensor?.calibration_a !== "") pin.cb_mul1 = Number(sensor?.calibration_a)
            if(sensor?.calibration_b !== undefined && sensor?.calibration_b !== null && sensor?.calibration_b !== "") pin.cb_mul2 = Number(sensor?.calibration_b)
            if(sensor?.calibration_c !== undefined && sensor?.calibration_c !== null && sensor?.calibration_c !== "") pin.cb_mul3 = Number(sensor?.calibration_c)
            if(sensor?.calibration_d !== undefined && sensor?.calibration_d !== null && sensor?.calibration_d !== "") pin.cb_mul4 = Number(sensor?.calibration_d)
            return pin
        })
        
        const filter = allPins.filter(pin => !pins.some((s: any) => s.pin === pin));
    
        let jsonData: any = {};
        jsonData.config = {};
        jsonData.config.version = 'v3';
        jsonData.config.serial = sensorbox?.latest_config_version ? sensorbox?.latest_config_version : 0;
        jsonData.config.sys_name = sensorbox?.sensor_box_name;
        jsonData.config.board_version = sensorbox?.board_version;
        jsonData.config.devel = process.env.REACT_APP_ENV === 'PROD' ? 0 : 1;
        jsonData.config.unit_off_interval = 60.0;
        jsonData.config.unit_on_interval = 5.0;
        jsonData.config.read_interval = sensorbox?.read_interval ?? 1;
        jsonData.config.filter = filter;
        jsonData.config.pins = pins;

        let jsonString = JSON.stringify(jsonData, null, 2);
        const blob = new Blob([jsonString], { type: 'application/json' });
        const href = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = href;
        link.download = "config.json";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.closeForm()
    }

    customerDatas = (customerData : any) => {

        let filteredUsers = customerData.filter((customer : any) => {
            const role = customer.roles.filter((role : any) => role.role_type > 1)
            return role.length > 0
        })

        let result = filteredUsers?.filter((customer :any) =>customer._id!== '' && this.state.sensorbox?.consumer?.findIndex(x=> x._id == customer._id) == -1 &&
            this.state.sensorbox?.contractor?.findIndex(x=> x._id == customer._id) == -1)
        return result
    }

    handleUserAccessSelection(option: any) {

        if(!option.length){
            Object.assign(this.state.sensorbox, { user_access : [] }) // set assigned to state to null when role is empty
        }

        const optionValue = option.map((item :any) => item.value)

        Object.assign(this.state.sensorbox, {user_access : optionValue , userAccessObject : option })
        this.setState({sensorbox  : {...JSON.parse(JSON.stringify(this.state.sensorbox))}})

    } 

    async showSaveConfirmationModal(action? : any) {
        // note : temp solution for the org admin access to all org
        let currentPage = 0;
        if(this.state.sensorbox?.board_version != 7.1){
            await userService.updateSensorBox(this.state.sensorbox, action === 'send' ,this.props?.organisationId)
        this.setState({isLoading : true})
        await userService.updateSensorBox(this.state.sensorbox, action === 'send' ,this.props?.organisationId)
            this.setState({isLoading : false},()=>{
                if(action === 'export'){
                    this.exportConfigHandler(this.state.sensorbox)
                    this.props.updateSensorBoxList(currentPage, this.state.sensorbox?.sensor_box_name, this.state.sensorbox?.group_name)
                }else{
                    this.props.updateSensorBoxList(currentPage)
                    this.closeForm()
                }
            })
        }else{
            await userService.updateRev7aSensorBox(this.state.sensorbox, action === 'send' ,this.props?.organisationId)
            this.setState({isLoading : false},()=>{
                if(action === 'export'){
                    this.exportConfigHandler(this.state.sensorbox)
                    this.props.updateSensorBoxList(currentPage, this.state.sensorbox?.sensor_box_name, this.state.sensorbox?.group_name)
                }else{
                    this.props.updateSensorBoxList(currentPage)
                    this.closeForm()
                }
            })
            }
    }

    closeSaveConfirmationModal = () => {
        this.setState({ saveConfirmationModal: false , saveType : '' })
        Utility.showModal('sensorboxdetails')

    }
    convertDropdownData = (data:any, direction:string)=>{
        switch (direction){
            case 'f': return data?.map((i:any)=>({value:i._id,label:i.name}))?? []
            case 'r': return data?.map((i:any)=>({_id:i.value,name:i.label}))?? []
        }
    }
    onSensorsChange = (sensorbox)=>{
        Object.assign(this.state.sensorbox, { boards: sensorbox.boards })
        this.setState({ sensorbox: this.state.sensorbox })
    }
    renderSensorsTab = (boardVersionValue:number)=>{
        const editing = this.state.isEdit || !this.isUpdate()
        return boardVersionValue == 7.1 ?
            <Rev7aSensorsTab editMode={editing} sensorbox={this.state.sensorbox} onChange={this.onSensorsChange}/>
            : <Checkbox  name="sensor_type"
                   isDisabled = {this.isDisabled()}
                   data = {this.state.sensorTypeList}
                   pinConfig={this.state.pinConfigList}
                   sensor_name = {this.state.sensorbox && this.state.sensorbox.sensor_box_name? this.state.sensorbox.sensor_box_name : ''}
                   value={this.state.sensorbox && this.state.sensorbox.sensors ? this.state.sensorbox.sensors  : []}
                   onChange={(e:any,value : any)=>{this.updateSensorType(e,value)}}
                   updatePinType={this.updatePinType}
                   updatePinRepresentation={this.updatePinRepresentation}
                   errors={this.state.pinError}
                   onChangeTemplate={(value : any)=>{this.updateTemplateForBoardVersion(value)}}
                   isLegacy={this.state.sensorbox?.version === 1}
                   boardVersion={this.state.sensorbox?.board_version}
                   sensorBox = {this.state.sensorbox}
                   selectAll={this.selectAll}
                   deselectAll={this.deselectAll}
                   sensorTemplates = {this.state.sensorTemplates}
                   clickPinNumberOnBoardImage = {(checked:any,value : any)=>{this.clickPinNumberOnBoard(checked,value)}}
        />
    }
    render() {
        const demoCheck = (authStore?.currentUser?.active_role?.role_type === -1);
        const lockRouterCheck = (this.state.sensorbox?.device_type === 'lockrouter')
        const cyclicTestCheck = (this.state.sensorbox?.device_type === 'cyclictest')
        const boardVersionValue = boardVersionOptions.find((value:any)=>(value._id === this.state.sensorbox?.board_version)) 
        const deviceTypeValue = deviceTypeOptions.find((value:any)=>(value._id === this.state.sensorbox?.device_type)) 
        return (
            <div>
            <div className="modal fade bd-example-modal-lg"  id="sensorboxdetails" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg xxx">
                    <div className="modal-content">
                    <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">{ this.isUpdate() ? authStore?.currentUser?.access?.includes(view_only_partial_asset_sensorbox_details) ? 'View Sensor Box Details' : !authStore?.currentUser?.access?.includes(create_or_update_sensorboxes_assets)  ? this.state.sensorbox?.sensor_box_name : "Edit Sensor Box Details" : "Create Sensor Box Details" }</h5>
                     
                    <span aria-hidden="true" className="close close-modal" aria-label="Close" onClick={this.closeForm}>&times;</span>
                </div>
                
                
                <div className="modal-body modal-text">
            

                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item tab-item">
    
                    <a 
                        className={this.state.tabhomeactive ? "nav-link active show":"nav-link"} 
                        id="pills-home-tab" 
                        data-toggle="pill" 
                        href="#pills-home" 
                        role="tab" 
                        aria-controls="pills-home" 
                        onClick={() => this.setState({ activeTab: 'sensorbox' })}
                        aria-selected="true"
                    >
                        Sensor Box
                    </a>
                </li>
                <li className="nav-item tab-item">
      
                    <a className={this.state.tabactive ? "nav-link active show":"nav-link"} 
                        id="pills-profile-tab" 
                        data-toggle="pill"
                        href="#pills-profile" 
                        role="tab" 
                        aria-controls="pills-profile" 
                        onClick={() => this.setState({ activeTab: 'sensors' })} 
                        aria-selected="false"
                    >
                        Sensors
                    </a>
                </li>
                <li className="nav-item tab-item">
                    <a className={this.state.tabactive ? "nav-link active show":"nav-link"} 
                        id="pills-profile-tab" data-toggle="pill" 
                        href="#maintenance-checklist" role="tab" 
                        onClick={() => this.setState({ activeTab: 'maintenance' })}
                    >
                        Maintenance checklist
                    </a>
                </li>
                {this.state?.sensorbox?._id &&
                <li className="nav-item tab-item">
                    <a className={this.state.tabactive ? "nav-link active show":"nav-link"} 
                        id="pills-profile-tab" data-toggle="pill" 
                        href="#deployment-status" role="tab" 
                        onClick={() => this.setState({ activeTab: 'deployment' })}
                    >
                        Deployment Status
                    </a>
                </li>
                }

                {this.isUpdate() && !demoCheck && authStore?.currentUser?.access?.includes(create_or_update_sensorboxes_assets) && !authStore?.currentUser?.access?.includes(view_only_partial_asset_sensorbox_details) &&
                    <li className="edit-btn-positio ml-auto">                          
                        <button type="button" 
                            className="btn btn-edit" 
                            onClick={()=>this.toggleEdit()}>
                            {this.state.isEdit ? "Cancel Edit" : "Edit"}
                        </button>    
                    </li>
                } 
                </ul> 
        <div className="tab-content" id="pills-tabContent">
 
            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                <div className="row">
                    <div className="col-md-6 col-lg-3">
                       
                        <div className="form-group">
                                <label className="custom-label" >Sensor Box ID <span className="mand-star">*</span></label>
                                <input  type="text" className="form-control" 
                                        name="sensor_box_name" aria-describedby="emailHelp" 
                                        value={this.state.sensorbox && this.state.sensorbox.sensor_box_name ?this.state.sensorbox.sensor_box_name : ''}
                                        maxLength={20}
                                        onChange={(e)=>this.updateSystemName(e)}
                                        onBlur={(e)=>this.sensorBoxExist()}
                                        disabled={ this.isDisabledSensorBoxId()}
                                        placeholder="Enter sensor box id" />
                                <div className="error-msg">{this.state.error ? this.state.error.sensor_box_name : ''}</div>

                        </div>
                       
                    </div>

                    <div className="col-md-6 col-lg-3">
                        <div className="form-group">
                            <label className="custom-label">Board Version <span className="mand-star">*</span></label>
                            <Select name="board-version"                                     
                                         isDisabled = {this.isDisabled()}
                                         data = {boardVersionOptions} 
                                         value={boardVersionValue ? boardVersionValue : ''}  
                                         onChange={(value : any)=>this.updateBoardVersion(value)}
                            />
                             <div className="error-msg">{this.state.error && this.state.error.board_version? this.state.error.board_version : ''}</div>
                        </div>
                    </div>
                    
                    <div className="col-md-6 col-lg-3">
                       
                       <div className="form-group">
                               <label className="custom-label" >Sensor Box Name</label>
                               <input  type="text" className="form-control" 
                                       name="alias" aria-describedby="emailHelp" 
                                       value={this.state.sensorbox && this.state.sensorbox.alias ?this.state.sensorbox.alias : ''}
                                       maxLength={40}
                                       onChange={(e)=>this.updateAlias(e)}
                                       disabled={this.isDisabled()}
                                       placeholder="Enter sensor box name" />
                               <div className="error-msg">{this.state.error ? this.state.error.alias : ''}</div>

                       </div>
                      
                   </div>

                   <div className="col-md-6 col-lg-3">
                        <div className="form-group">
                            <label className="custom-label">Device Type <span className="mand-star">*</span></label>
                            <Select name="device_type"                                     
                                         isDisabled = {this.isDisabled()}
                                         data = {deviceTypeOptions} 
                                         value={deviceTypeValue ? deviceTypeValue : ''}  
                                         onChange={(value : any)=>this.updateDeviceType(value)}
                            />
                             <div className="error-msg">{this.state.error && this.state.error.device_type ?  this.state.error.device_type : ''}</div>
                        </div>
                    </div>
                </div>
                <div className="row">

                <div className="col-md-6 col-lg-3">
                       
                       <div className="form-group">
                               <label className="custom-label" >System Model</label>
                               <input type="text" className="form-control" id="system_model" aria-describedby="emailHelp" 
                               name="system_model" 
                               value={this.state.sensorbox && this.state.sensorbox.system_model ?this.state.sensorbox.system_model : ''}
                               onChange={(e)=>this.updateModelValue(e)}
                               disabled={this.isDisabled()}
                               maxLength={20}
                               placeholder="Enter system model" />
                       <div className="error-msg">{this.state.error ? this.state.error.system_model : ''}</div>
                       </div>
                      
                </div>

                <div className="col-md-6 col-lg-3">
                       
                       <div className="form-group">
                               <label className="custom-label" >Nominal Cooling Capacity</label>
                               <input type="text" className="form-control" id="nominal_cooling_capacity" aria-describedby="emailHelp"
                                name="nominal_cooling_capacity" 
                                value={this.state.sensorbox && this.state.sensorbox.nominal_cooling_capacity ?this.state.sensorbox.nominal_cooling_capacity : ''}
                                onChange={(e)=>this.updateCapacityValue(e)}
                                disabled={this.isDisabled()}
                                maxLength={8}
                               placeholder="Enter nominal cooling" />
                                <div className="error-msg">{this.state.error && this.state.error.nominal_cooling_capacity? this.state.error.nominal_cooling_capacity : ''}</div>
                           
                               
                       </div>
                      
                </div>

                <div className="col-md-6 col-lg-3">
                       
                       <div className="form-group">
                               <label className="custom-label" >Nominal Heating</label>
                               <input type="text" className="form-control" id="nominal_heating" aria-describedby="emailHelp"           name="nominal_heating" 
                               value={this.state.sensorbox && this.state.sensorbox.nominal_heating ?this.state.sensorbox.nominal_heating : ''}
                               onChange={(e)=>this.updateCapacityValue(e)}
                               disabled={this.isDisabled()} 
                               maxLength={8}
                               placeholder="Enter nominal heating" />
                                <div className="error-msg">{this.state.error && this.state.error.nominal_heating? this.state.error.nominal_heating : ''}</div>
                           
                       </div>
                      
                   </div>
                   
                <div className="col-md-6 col-lg-3">
                           
                            <div className="form-group">
                                    <label className="custom-label" >Compressor Model</label>
                                    <input type="text" className="form-control" id="compressor_model" aria-describedby="emailHelp" name="compressor_model" 
                                    value={this.state.sensorbox && this.state.sensorbox.compressor_model ?this.state.sensorbox.compressor_model : ''}
                                    onChange={(e)=>this.updateModelValue(e)}
                                    disabled={this.isDisabled()} 
                                    maxLength={20}
                                    placeholder="Enter compressor model" />
                                     <div className="error-msg">{this.state.error && this.state.error.compressor_model? this.state.error.compressor_model : ''}</div>
                            </div>
                           
                    </div>
                </div>
                <div className="row">

                <div className="col-md-6 col-lg-3">
                           
                           <div className="form-group">
                                   <label className="custom-label">Expansion Valve Model</label>
                                   <input type="text" className="form-control" id="expansion_valve_model"
                                   name="expansion_valve_model" 
                                   value={this.state.sensorbox && this.state.sensorbox.expansion_valve_model ?this.state.sensorbox.expansion_valve_model : ''}
                                   onChange={(e)=>this.updateModelValue(e)}
                                   disabled={this.isDisabled()} 
                                   maxLength={20}
                                   aria-describedby="emailHelp" placeholder="Enter expansion valve model" />
                                    <div className="error-msg">{this.state.error && this.state.error.expansion_valve_model ? this.state.error.expansion_valve_model : ''}</div>

                           </div>
                          
                </div>

                <div className="col-md-6 col-lg-3">
                           
                           <div className="form-group">
                                   <label className="custom-label" >Evaporator Motor Model</label>
                                   <input type="text" className="form-control" id="evaporator_motor_model" aria-describedby="emailHelp" name="evaporator_motor_model" 
                                   value={this.state.sensorbox && this.state.sensorbox.evaporator_motor_model ?this.state.sensorbox.evaporator_motor_model : ''}
                                   onChange={(e)=>this.updateModelValue(e)}
                                   disabled={this.isDisabled()} 
                                   maxLength={20}
                                   placeholder="Enter evaporator motor model" />
                                    <div className="error-msg">{this.state.error && this.state.error.evaporator_motor_model ? this.state.error.evaporator_motor_model : ''}</div>

                           </div>
                          
               </div>

                <div className="col-md-6 col-lg-3">
                           
                           <div className="form-group">
                                   <label className="custom-label" >Condenser Motor Model</label>
                                   <input type="text" className="form-control" id="condensor_motor_model" aria-describedby="emailHelp"
                                   name="condensor_motor_model" 
                                   value={this.state.sensorbox && this.state.sensorbox.condensor_motor_model ?this.state.sensorbox.condensor_motor_model : ''}
                                   onChange={(e)=>this.updateModelValue(e)}
                                   maxLength={20}
                                   disabled={this.isDisabled()}  placeholder="Enter condenser motor model" />
                                    <div className="error-msg">{this.state.error && this.state.error.condensor_motor_model  ? this.state.error.condensor_motor_model : ''}</div>

                           </div>
                          
                       </div>
                <div className="col-md-6 col-lg-3">
                       
                        <div className="form-group">
                                <label className="custom-label" >Airflow</label>
                                <input type="text" className="form-control" id="airflow" aria-describedby="emailHelp" 
                                name="airflow" 
                                value={this.state.sensorbox && this.state.sensorbox.airflow ?this.state.sensorbox.airflow : ''}
                                onChange={(e)=>this.updateAirflowValue(e)}
                                maxLength={6}
                                disabled={this.isDisabled()} placeholder="Enter airflow" />
                                   <div className="error-msg">{this.state.error && this.state.error.airflow  ? this.state.error.airflow : ''}</div>

                        </div>
                       
                    </div>
                </div>
                <div className="row">

                <div className="col-md-6 col-lg-3">

                       <div className="form-group">
                               <label className="custom-label" >Refrigerant</label>
                                 <Select name="refrigerent"
                                   isDisabled = {this.isDisabled()}
                                   data = {this.state.refrigerantList}
                                   value={this.state.sensorbox && this.state.sensorbox.refrigerent ? this.state.sensorbox.refrigerent : ''}
                                   onChange={(value : any)=>this.updateRefrigerant(value)}
                                   showClose={true}
                                   clearValue={()=>this.updateRefrigerant(null)}
                                   />
                       </div>

                </div>

                <div className="col-md-6 col-lg-3">
                    <div className="form-group">
                                <label className="custom-label" >Unit Serial Number</label>
                                <input type="text" className="form-control" id="unit_serial_number" aria-describedby="emailHelp"
                                name="unit_serial_number"
                                value={this.state.sensorbox && this.state.sensorbox.unit_serial_number ?this.state.sensorbox.unit_serial_number : ''}
                                onChange={(e)=>this.updateTextFieldValue(e)}
                                maxLength={30}
                                disabled={this.isDisabled()} placeholder="Enter unit serial number" />
                                <div className="error-msg">{this.state.error && this.state.error.unit_serial_number  ? this.state.error.unit_serial_number : ''}</div>
                        </div>
                    </div>

                    <div className="col-md-6 col-lg-3">
                        <div className="form-group">
                            <label className="custom-label">Filter Width</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Filter width"
                                value={this.state.sensorbox?.filter_width || ''}
                                pattern="[0-9]{0,2}(\.[0-9]{0,2})?"
                                onChange={this.updateFilterWidth}
                                onBlur={(e) => this.validateFilterSize('filter_width', Number(e.target.value))}
                                disabled={this.isDisabled()}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <div className="form-group">
                            <label className="custom-label">Filter Length</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Filter length"
                                value={this.state.sensorbox?.filter_length || ''}
                                pattern="[0-9]{0,2}(\.[0-9]{0,2})?"
                                onChange={this.updateFilterLength}
                                onBlur={(e) => this.validateFilterSize('filter_length', Number(e.target.value))}
                                disabled={this.isDisabled()}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">

                <div className="col-md-6 col-lg-3">
                        <div className="form-group">
                            <label className="custom-label">Filter Thickness</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Filter thickness"
                                value={this.state.sensorbox?.filter_thickness || ''}
                                pattern="[0-9]{0,1}(\.[0-9]{0,2})?"
                                onChange={this.updateFilterThickness}
                                onBlur={(e) => this.validateFilterSize('filter_thickness', Number(e.target.value))}
                                disabled={this.isDisabled()}
                            />
                        </div>
                </div>

                <div className="col-md-6 col-lg-3">
                        <div className="form-group">
                            <label className="custom-label">Filter Quantity</label>
                            <select
                                className="form-control"
                                disabled={this.isDisabled()}
                                value={this.state.sensorbox?.filter_quantity || ''}
                                onChange={this.updateFilterQuantity}
                            >
                                <option value="">Select</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                            </select>
                        </div>
                    </div>
                <div className="col-md-6 col-lg-3">
                       <div className="form-group">
                               <label className="custom-label" >System S/N</label>
                               <Select name="system"                                    
                                   isDisabled = {this.isDisabled()}
                                   data = {this.state.inspectionList} 
                                   value={this.inspectionValue()}  
                                   onChange={(value : any)=>this.updateInspection(value)}
                                   />
                       </div>
                </div>
                </div>
                <div className="row">
                  {!authStore?.currentUser?.access?.includes(view_only_partial_asset_sensorbox_details) &&

                    <div className="col-md-6 col-lg-6">


                        <div className="form-group">

                            <label className="custom-label" >Contractor and Team</label>

                            <Select
                                isMulti
                                name="contractor"
                                isDisabled = {this.isDisabled() || this.isGrouped() || authStore?.currentUser?.access?.includes(view_contractor_sensorboxes_assets) || authStore?.currentUser?.access?.includes(view_assigned_users_sensorboxes_assets)}
                                data = {this.state.contractorList}
                                value={demoCheck && this.isUpdate() ? [] : this.convertDropdownData(this.state.sensorbox?.contractor,'f') }
                                onChange={(value : any)=> {
                                    this.updateContractor(value)
                                }}
                                clearValue={()=>this.updateContractor([])}

                            />
                        </div>

                    </div>
                    }
                    <div className="col-md-6 col-lg-6">
                        <div className="form-group">

                            <label className="custom-label" >Consumer and Team </label>
                            {!(this.props?.organisationId ? authStore?.currentUser?.oid !== this.props?.organisationId : false) && (this.isUpdate() ? this.state.isEdit : true) && !this.isGrouped() &&
                                <span className="add-btn-c" onClick={()=>{this.showUserCreateForm()}}>
                                <i className="fa fa-plus" ></i>
                                </span>
                            }


                            <Select name="consumer"
                                    isDisabled = {this.isDisabled() || this.isGrouped()}
                                    data = {this.state.consumerList}
                                    value={demoCheck && this.isUpdate() ? [] : this.convertDropdownData(this.state.sensorbox?.consumer,'f')}
                                    onChange={(value : any)=>this.updateCustomer(value)}
                                    clearValue={()=>this.updateCustomer(null)}
                                    isMulti={true}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">

                <div className="col-md-6 col-lg-3">
                               
                               <div className="form-group">
                                       <label className="custom-label" >Address <span className=""></span></label>
                                       <textarea className="form-control resize-none" id="place" aria-describedby="emailHelp" 
                                       name="place" 
                                       value={demoCheck && this.isUpdate()  ? '-': this.state.sensorbox && this.state.sensorbox.place?this.state.sensorbox.place : ''}
                                       onChange={(e)=>this.updateAddressValue(e)}
                                       onBlur={(e)=>this.coordinates()}
                                       maxLength={100}
                                       disabled={this.isDisabled()}
                                       placeholder="Enter place" ></textarea>
                                       <div className="error-msg">{this.state.error ? this.state.error.place : ''}</div>
                               </div>
                              
                </div>

                <div className="col-md-6 col-lg-3">
                       
                       <div className="form-group">
                               <label className="custom-label" >Latitude</label>
                               <input type="text" className="form-control" id="latitude" aria-describedby="emailHelp" 
                               name="latitude"    
                               value={demoCheck && this.isUpdate() ? '-' : this.state.sensorbox && this.state.sensorbox.location && this.state.sensorbox.location.coordinates && this.state.sensorbox.location.coordinates[0]? this.state.sensorbox.location.coordinates[0] : ''}
                               onChange={(e)=>this.updateLatitude(e)} 
                               disabled={true} 
                               maxLength={20}
                               placeholder="Enter latitude" />
                               <div className="error-msg">{this.state.error && this.state.error.location? this.state.error.location : ''}</div>
                       </div>
                      
                </div>

                <div className="col-md-6 col-lg-3">
                       
                       <div className="form-group">
                               <label className="custom-label" >Longitude</label>
                               <input type="text" className="form-control" id="longitude" aria-describedby="emailHelp" 
                               name="longitude" 
                               // value={this.state.sensorbox && this.state.sensorbox.longitude ?this.state.sensorbox.longitude : ''}
                               value={demoCheck && this.isUpdate() ?  '-' :  this.state.sensorbox && this.state.sensorbox.location && this.state.sensorbox.location.coordinates && this.state.sensorbox.location.coordinates[1]? this.state.sensorbox.location.coordinates[1] : ''}
                               onChange={(e)=>this.updateLongitude(e)}                                                   
                               disabled={true} 
                               placeholder="Enter longitude" />
                               
                               <div className="error-msg">{this.state.error && this.state.error.location ? this.state.error.location: ''}</div>
                               {/* <div className="error-msg">{this.state.error && this.state.error.location? this.state.error.location.coordinates[1] : ''}</div> */}
                       </div>
                      
                </div>

                <div className="col-md-6 col-lg-3">
                       
                     
                       <div className="form-group">
                                   <label className="custom-label" >Time Zone</label>
                                   <input type="text" className="form-control"
                                       name="timezone" 
                                       value={this.state.sensorbox && this.state.sensorbox.timezone ? this.state.sensorbox.timezone : ''}                                               
                                       disabled={true} 
                                       placeholder="Enter time zone" />
                                   <div className="error-msg">{this.state.error && this.state.error.location? this.state.error.timezone : ''}</div>
                        </div>
                             
                </div>
             
                </div>

                <div className="row">
                <div className="col-md-6 col-lg-3">
                       
                     
                       <div className="form-group">
                                   <label className="custom-label" >Weather Station</label>
                                   <input type="text" className="form-control"
                                       name="weather_station" 
                                       value={this.state.sensorbox && this.state.sensorbox.weather_station ? this.state.sensorbox.weather_station : 'No Station'}                                               
                                       disabled={true} 
                                       placeholder="Enter weather  station" />
                               </div>
                </div>
                <div className="col-md-6 col-lg-3">
                       
                       <div className="form-group">
                               <label className="custom-label" >Category <span className="mand-star">*</span></label>
                                <Select name="category"                                      
                                         isDisabled = {this.isDisabled() || !(this.state.sensorbox?.device_type === 'general')}
                                         data = {categoryOptions} 
                                         value={this.state.sensorbox && this.state.sensorbox.category ? this.state.sensorbox?.category : ''}  
                                         onChange={(value : any)=>this.updateCategory(value)}
                                         showClose={true}
                                         clearValue={()=>this.updateCategory(null)}
                                />
                               <div className="error-msg">{this.state.error && this.state.error.category? this.state.error.category : ''}</div>
                       </div>
                     
                </div>

                    <div className="col-md-6 col-lg-3">
                       
                       <div className="form-group">
                               <label className="custom-label" >Notes</label>                                
                               <textarea  disabled={this.isDisabled()} className="form-control resize-none" id="notes" name="notes"  maxLength={150} placeholder="Enter notes (Max 150 characters)" 
                                value={this.state.sensorbox && this.state.sensorbox.notes? this.state.sensorbox.notes : '' }
                               onChange={(e)=>this.updateTextFieldValue(e)}  ></textarea>                         

                              
                               <div className="error-msg">{this.state.error && this.state.error.notes? this.state.error.notes : ''}</div>
                           
                       </div>
                      
                                            </div>
                                            <div className="col-md-6 col-lg-3">
                       
                       <div className="form-group">
                               <label className="custom-label" >Read Interval</label>                                
                               <input type='number' min={1} disabled={this.isDisabled()} className="form-control resize-none" id="read_interval" name="read_interval"  maxLength={150} placeholder="Read Interval (default=1)" 
                                value={this.state.sensorbox?.read_interval?? '' }
                               onChange={(e)=>this.updateReadInterval(e)}  ></input>
                               <div className="error-msg">{this.state?.error?.read_interval?? ''}</div>
                           
                       </div>
                      
                    </div>
                    {!(this.isGrouped()) && !authStore?.currentUser?.access?.includes(view_only_partial_asset_sensorbox_details) &&
                    <div className="col-md-6 col-lg-9">
                       

                       <div className="form-group">
                          
                               <label className="custom-label" >User Access </label>
                               
                                <Select
                                    isMulti
                                    name="consumer" 
                                    isDisabled= {this.isDisabled() || this.isGrouped()}
                                    data={this.customerDatas(this.state.consumerList)}
                                    value={this.isGrouped() ? '' :  this.state.sensorbox?.userAccessObject}
                                    onChange={(e:any)=>this.handleUserAccessSelection(e)}
                                    userAccess={true}
                                    maxOptions={15}
                                />
                       </div>
                      
                    </div>
                    }

                </div>
                            
                <div className="mb-3">
                    <label className="custom-label" htmlFor="">Heating provided by: <span className="mand-star">*</span> </label>
                    <div className="d-flex">
                        <div className="form-inline px-3">
                            <input
                                id="heat_provided_by_furnace"
                                type="radio"
                                className="mr-3"
                                name="heat_provided_by"
                                value="furnace"
                                checked={this.state.sensorbox?.heat_provided_by === 'furnace'}
                                disabled={this.isDisabled()}
                                onChange={this.updateFields}
                            />
                            <label htmlFor="heat_provided_by_furnace" className="custom-label" style={{ fontSize: '0.85rem' }}>
                                Furnace
                            </label>
                        </div>
                        <div className="form-inline px-3">
                            <input
                                id="heat_provided_by_heat_pump"
                                type="radio"
                                className="mr-3"
                                name="heat_provided_by"
                                value="heat-pump"
                                checked={this.state.sensorbox?.heat_provided_by === 'heat-pump'}
                                disabled={this.isDisabled()}
                                onChange={this.updateFields}
                            />
                            <label htmlFor="heat_provided_by_heat_pump" className="custom-label" style={{ fontSize: '0.85rem' }}>
                                Heat pump
                            </label>
                        </div>
                    </div>
                    {  this.state.error?.heat_provided_by &&
                    <div className="error-msg mt-2">{this.state.error ? this.state.error.heat_provided_by : ''}</div>
                    } 
                </div>
                {
                    this.state.sensorbox?.heat_provided_by === 'heat-pump' &&
                    <div className="mb-3">
                        <label className="custom-label" htmlFor="">Reverse valve energized in <span className="mand-star">*</span> </label>
                        <div className="d-flex">
                            <div className="form-inline px-3">
                                <input
                                    id="rv_mode_heating"
                                    type="radio"
                                    className="mr-3"
                                    name="rv_mode"
                                    value="heating"
                                    checked={this.state.sensorbox?.rv_mode === 'heating'}
                                    disabled={this.isDisabled()}
                                    onChange={this.updateFields}
                                />
                                <label htmlFor="rv_mode_heating" className="custom-label" style={{ fontSize: '0.85rem' }}>
                                    Heating
                                </label>
                            </div>
                            <div className="form-inline">
                                <input
                                    id="rv_mode_cooling"
                                    type="radio"
                                    className="mr-3"
                                    name="rv_mode"
                                    value="cooling"
                                    checked={this.state.sensorbox?.rv_mode === 'cooling'}
                                    disabled={this.isDisabled()}
                                    onChange={this.updateFields}
                                />
                                <label htmlFor="rv_mode_cooling" className="custom-label" style={{ fontSize: '0.85rem' }}>
                                    Cooling
                                </label>
                            </div>
                        </div>
                        { this.state.error?.rv_mode &&
                        <div className="error-msg mt-2">{this.state.error ? this.state.error.rv_mode : ''}</div>
                        }
                    </div>
                }

                {
                <div className="mb-3">
                    <label className="custom-label" htmlFor="">R Switch Condition </label>
                    <div className="d-flex">
                        <div className="form-inline px-3">
                            <input
                                id="r_switch_normal"
                                type="radio"
                                className="mr-3"
                                name="r_switch_condition"
                                value="normal"
                                checked={this.state.sensorbox?.r_switch_condition === 'normal'}
                                disabled={this.isDisabled()}
                                onChange={this.updateFields}
                            />
                            <label htmlFor="r_switch_normal" className="custom-label" style={{ fontSize: '0.85rem' }}>
                               {`Always (24 x 7)`} 
                            </label>
                        </div>
                        <div className="form-inline px-3">
                            <input
                                id="r_switch_timeframe"
                                type="radio"
                                className="mr-3"
                                name="r_switch_condition"
                                value="timeframe"
                                checked={this.state.sensorbox?.r_switch_condition === 'timeframe'}
                                disabled={this.isDisabled()}
                                onChange={this.updateFields}
                            />
                            <label htmlFor="r_switch_timeframe" className="custom-label" style={{ fontSize: '0.85rem' }}>
                                {`Business Hours (09 AM to 05 PM, Monday to Friday)`}
                            </label>
                        </div>
                    </div>
                </div>
                }


                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div className="form-inline">
                            <input 
                                type="checkbox"
                                className="mr-3"
                                checked={this.state.sensorbox?.contractor_alert_on || false}
                                disabled={this.isDisabled()}
                                onChange={this.updateContractorAlert}
                            />
                            <label className="custom-label" style={{ fontSize: '1rem' }}>
                                Enable alert for prop mgmt/service provider
                            </label>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="form-inline">
                            <input 
                                type="checkbox"
                                className="mr-3"
                                checked={this.state.sensorbox?.consumer_alert_on || false}
                                disabled={this.isDisabled()}
                                onChange={this.updateConsumerAlert}
                            />
                            <label className="custom-label" style={{ fontSize: '1rem' }}>
                                Enable alert for consumer
                            </label>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="form-inline">
                            <input 
                                type="checkbox"
                                className="mr-3"
                                checked={this.state.sensorbox?.offline_alert_on || false}
                                disabled={this.isDisabled()}
                                onChange={this.updateOfflineAlert}
                            />
                            <label className="custom-label" style={{ fontSize: '1rem' }}>
                                Enable alert when device is offline / online
                            </label>
                        </div>
                    </div>
                    { !this.isGrouped() &&
                    <div className="col-lg-4 col-md-6 mt-3">
                        <div className="form-inline">
                            <input 
                                type="checkbox"
                                className="mr-3"
                                checked={this.state.sensorbox?.show_power_calculation || false}
                                disabled={this.isDisabled()}
                                onChange={this.updatePowerCalculationPermission}
                            />
                            <label className="custom-label" style={{ fontSize: '1rem' }}>
                                Show power calculations
                            </label>
                        </div>
                    </div>
                    }
                </div>

            </div>
            
            <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
       
                <div className="error-msg">{this.state.error && this.state.error.sensors? this.state.error.sensors : ''}</div>
                 

                { this.renderSensorsTab(this.state.sensorbox?.board_version) }
                         
            </div>
            <div className="tab-pane fade" id="maintenance-checklist" role="tabpanel">
                <MaintenanceCheckList 
                    // selectedCheckLists={this.state?.sensorbox?.maintenance_checklists}
                    sensorBoxId={this.state?.sensorbox?._id}
                    editMode={this.state.isEdit}
                />
            </div>
            
            <div className="tab-pane fade" id="deployment-status" role="tabpanel">
                <DeploymentStatus 
                    sensorbox={this.state?.sensorbox}
                    onChangeDeploymentStatus={(date: any , type: any , action : any) => this.onChangeDeploymentStatus(date , type, action )}
                    disabled={this.isDisabled() || demoCheck}
                />
            </div>
            
        </div>


                </div>
                
                <div style={{color : 'red'}}>{this.state.responseError}</div>
                <div className="modal-footer d-flex-wrap">
                    <div>
                    {(this.state.sensorbox && this.state.sensorbox._id) && this.state.isEdit && authStore?.currentUser?.access?.includes(delete_sensorboxes_assets) &&
                            <button
                                type="button"
                                className="btn btn-rounded btn-icon"
                                onClick={e => this.state.sensorbox && this.state.sensorbox.is_deleted ? this.enableSensorbox() : this.showDeleteConfirm()}
                                >
                                {this.state.sensorbox.is_deleted ?
                                    <img src={trashRestore} className="trash-restore-icon" alt=''/>
                                    :    <i className="fa fa-trash-o fa-lg" />
                                }
                            </button>
                    }
                    {
                        this.state.sensorbox?._id && authStore?.currentUser?.active_role?.role_type === 0 && this.state.isEdit  && (this.state.activeTab === 'sensorbox' ) &&
                        <button type="button" disabled={this.isDisabled() || demoCheck} className="btn btn-dark-blue ml-3" onClick={()=> this.moveSensorBox()}>Move Sensorbox</button>
                    }
                    </div>
                    <div className="error-msg">{this.state.validRangeError ? this.state.validRangeError : ''}</div>
                    <div className="error-msg">{this.state.validCalibrationError ? this.state.validCalibrationError : ''}</div>
                  {
                      this.state.activeTab !== 'maintenance' && !authStore?.currentUser?.access?.includes(view_only_partial_asset_sensorbox_details) &&
                        <div className="ml-auto">
                            {
                                (this.state.sensorbox?.version >= 3 || !this.state.sensorbox?._id) && (this.state.activeTab === 'sensors' || (this.state.activeTab === 'sensorbox' && this.state.readIntervalChanged) ) && !lockRouterCheck && !cyclicTestCheck &&
                                <>
                                    <button type="button" disabled={(this.isDisabled() || !this.sensorArray.length || !this.state.sensorbox?.sensor_box_name || demoCheck || lockRouterCheck || cyclicTestCheck)} className="btn btn-dark-blue mr-3" onClick={()=> this.saveSensors('export')}>Save & Export</button>
                                    <button type="button" disabled={this.isDisabled() || !this.sensorArray.length ||  this.state.sensorbox?.device_config_status === 2 || demoCheck || lockRouterCheck || cyclicTestCheck} className="btn btn-dark-blue mr-3" onClick={()=>this.saveSensors('send')}>Save & Send</button>
                                </>
                            }
                            <button type="button" disabled={this.isDisabled() || demoCheck} className="btn btn-dark-blue" onClick={()=>this.saveSensors()}>Save</button>
                        </div>
                  }
              
                </div>

                    </div>
                </div>
                <div>
                    {this.state.isLoading ?
                    <LoadingWheel/>
                    :   null}
                </div>
            </div>
            
            <SaveConfirmationModal
                title={`Edit sensorbox details`}
                message={`Are you sure want to make changes to the sensorbox ${this.state.sensorbox?.sensor_box_name} `}
                visible = {this.state.saveConfirmationModal}
                confirm ={"YES"}
                action={() => (this.showSaveConfirmationModal(this.state?.saveType))}
                toggleVisibility={this.closeSaveConfirmationModal}
            />

            <ConfirmModal
                title="Delete sensor box"
                message={`${this.state.issueCount > 0 ? constants.message.deleteSensorboxWarning : this.isGrouped() ? `This sensor box is part of ${this.props?.sensorbox?.group_name}.
                Deleting this will also remove from the ${this.props?.sensorbox?.group_name}. Do you want to continue ?`
                : 'Do you want to delete the sensor box ?'}`}
                closeConfirmModal={this.closeConfirmModal}
                delete={this.deleteSensorbox}
                isLoading = {this.state.isLoading}
                isSuperAdmin = {authStore?.currentUser?.active_role?.role_type === 0 ? true : false}
            />
            <MoveSensorboxModal
               show={this.state.showMoveSensorboxModal}
               onClose={this.closeMoveSensorsModal}
               sensorBoxDetails={this.state.sensorbox}
               organisationList={this.props.organisationList}
               closeModal ={(orgName: any) => this.closeMoveSensorboxModal(orgName)}
               isLoading = {this.state.isLoading}
            />
            { this.state.roleList.length > 1 &&
            <UserModal checkSensor={true} updateCustomerList={this.getCustomerList} roleList ={this.state.roleList} organisationId={this.props?.organisationId ? this.props?.organisationId : authStore?.currentUser?.oid}/>
            }
              

        </div>




        );
    }
}
 
export default CreateSensorBox;
