export type SoapSensorData = {
    advId: number;
    advCode: number;
    timestamp: string;
    state: string;
    source: string;
    text: string;
    alarm: boolean;
    notice: boolean;
    fail: boolean;
    acked: boolean;
    reset: boolean;
    rtn: boolean;
    ackUser: string | null;
    ackTimestamp: string;
    priority: number;
    rtnTimestamp: string;
    reportValue: string | null;
    limit: string | null;
    engUnits: string;
}

class soapSensorStore {
    sensorData: Array<SoapSensorData>
    constructor() {
        this.sensorData =[]
    }

}

export default new soapSensorStore()