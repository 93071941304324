import React from 'react';
import { Input } from 'antd';
import './sensorData.css';
import { Dropdown } from "react-bootstrap";
import { withRouter, RouteComponentProps } from 'react-router';
import Constants from '../../config/constants'
import Pagination from "react-js-pagination";
import {Organization} from '../../interface'
import {SensorBox} from '../../interface'
import sensorService from '../../services/sensorService'
import SensorBoxForm from '../../components/sensormodal'
import constants from '../../config/constants';
import toast from '../../components/toast'
import LoadingWheel from '../../components/loadingwheel'
import SensorData from '../../components/sensordata'
import Utility from '../../helpers/Utility';
import ExportModal from '../../components/exportsensordata'
import userService from '../../services/userService';
import eventStore from '../../store/eventStore';
import authStore from '../../store/authStore';
import Select from '../../components/select';
import ResetHardeleteSensorbox from '../../components/resetHarddeleteModal'
import Utils from '../../helpers/Utility';
import CreateGroupModal from '../../components/creategroupmodal/CreateGroupModal';
import groupIcn from '../../assets/group.png';
import sensorIcn from '../../assets/sensorbox.png';
import organizationService from '../../services/organizationService';
import GraphModal from '../../components/graphmodal'
import GraphModalGroup from '../../components/graphModalgroup/GraphModalGroup'
import MaintenanceModal from '../../components/maintenancemodal';
import AlertLogsModal from '../../components/AlertLogsModal';
import LogDataModal from "../../components/LogDataModal"
import Routes from '../../routing/routes'
import PowerGraphModal from '../../components/powerModal';
import Permissions from '../../config/permssions';
import DeviceConfigLogModal from '../../components/device-config-log-modal/DeviceConfigLogModal';
import moment from 'moment'
import InvalidRangeAlertModal from "../../components/InvalidRangeAlertModal/InvaliRangeAlertModal";

const { Search } = Input;

const {
    super_admin_access,
    view_all_sensorboxes_assets,
    view_assigned_users_sensorboxes_assets,
    create_or_update_sensorboxes_assets
  } = Permissions

const deploymentStatusType = [
    { _id: '', name: 'All' },
    { _id: 'commissioned', name: 'Commissioned' },
    { _id: 'decommissioned', name: 'Decommissioned' },
    { _id: 'tested', name: 'Tested' },
    { _id: 'testing', name: 'Testing' }
]

export const deviceExamplesForDemo = new Map<string,string[]>([
    ["chiller-larger",['61782ba5128d3a2d981ab42d']],
    ["chiller-smaller",['']],
    ["RTU",['617838e0128d3a2d981b04b4']],
    ["Water-Heater",['6411dfd76f94297136f4fb13','6411de6c6f94297136f4e2a0']],
    ["Freezer",[]],
    ["Cooler",[]],
    ["MRI",[]],
    ["paw",[]],
    ["Indoor",[]],
    ["Outdoor",[]],
    ["O2-generator",[]],
    ["Chromalox",[]],
    ["boiler",[]],
    ["water pump",['6480e1131518cf6b17ee4229',]],
])

export interface SensorProps extends RouteComponentProps { }
export interface SensorState {
    sensorBoxList: Array<any>
    currentPage: number;
    visible         : boolean;
    selectedSystemName : string;
    organizationList:   Array<Organization>;
    organizationFilter? : any;
    sensorBoxCount: number;
    searchFilter: any;
    isLoading: boolean;
    selectedDeploymentStatus? : any;
    selectedSensorBox?   :   SensorBox;
    selectedSensorBoxName: string;
    selectedGroupId : string;
    selecetedSensorBox: any;
    contractorList: Array<any>
    contractor: any;
    showSensorDataModal: boolean;
    showGraphModal: boolean;
    showGraphModalForGroup: boolean;
    showMaintenanceModal: boolean;
    showExportModal: boolean;
    showAlertLogsModal: boolean;
    showInvalidRangeAlertModal:boolean;
    showPowerConsumptionModal: boolean;
    deviceSort: string;
    statusSort: string;
    isLogDataVisible:boolean;
    isInvalidRangeDataVisible:boolean;
    createGroupModalVisible: boolean;
    showResetSensorBoxModal: boolean;
    selectedLog:any;
    invalidRangeAlertData:any;
    DeviceConfigLogModalVisible: boolean;
    lastTab:string
}

let system_id:any
let status:any
let org_id:any
let deployment_status: any

class UserList extends React.Component<SensorProps, SensorState> {

    

    searchSensorBoxes: any
    constructor(props: any) {
        super(props);

        const { location } = this.props
        const urlParams = new URLSearchParams(location?.search);
        system_id = urlParams.get('system_id')
        status = urlParams.get('status')
        org_id = urlParams.get('org_id')
        deployment_status = urlParams.get('deployment_status')


        this.state = {
            selectedSensorBox   :   undefined,
            sensorBoxList: [],
            selectedSystemName: '',
            visible         :   false,
            currentPage: 1,
            organizationList    : [],
            organizationFilter : {},
            sensorBoxCount: 1,
            searchFilter: '',
            isLoading: true,
            selectedSensorBoxName: '',
            selecetedSensorBox: {},
            selectedDeploymentStatus : deploymentStatusType[0]?._id,
            selectedGroupId:'',
            contractorList: [],
            contractor: '',
            showGraphModal: false,
            showGraphModalForGroup: false,
            showMaintenanceModal: false,
            showSensorDataModal: false,
            showExportModal: false,
            showAlertLogsModal: false,
            showInvalidRangeAlertModal:false,
            createGroupModalVisible: false,
            showPowerConsumptionModal: false,
            DeviceConfigLogModalVisible: false,
            deviceSort: 'group', // group or sensorbox
            statusSort: 'desc', // asc or desc,
            isLogDataVisible:false,
            isInvalidRangeDataVisible:false,
            showResetSensorBoxModal: false,
            selectedLog:{},
            invalidRangeAlertData:{},
            lastTab:'status'
        }
        this.searchSensorBoxes = Utility.debounce(this.getSensorBoxes, 700);
        
    }

    async componentDidMount() {

        // note : temp solution for the org admin access to all org
        if(authStore?.currentUser?.active_role?.role_type === 0 || (authStore?.currentUser?.active_role?.role_type === 1 && authStore.currentUser.is_default)){
            await this.getOrganisationsSensorData();
        }
        if(authStore?.currentUser?.access?.includes(view_all_sensorboxes_assets)){
            await this.getContractorList()
        }
        await this.getSensorBoxes(1);
        this.listenAndUpdateSensordata()
    }



    getSensorBoxes: any = async (pageNumber: number = 0) => {
        pageNumber = pageNumber === 0 ? this.state.currentPage : pageNumber;
        try {
            this.setState(state=>({...state,isLoading:true}));
            let sensorBoxList:any;
            const demoCheck = (authStore?.currentUser?.active_role?.role_type === -1);
            const getSensorBoxes = demoCheck ? sensorService.getSensorBoxesForDemo : sensorService.getSensorBoxes

            sensorBoxList = await getSensorBoxes(pageNumber, constants.maxLimit, this.state.contractor, this.state.searchFilter, this.state.deviceSort, this.state.statusSort,org_id ? org_id : this.state.organizationFilter._id, system_id ? system_id : null , status ?  status : null, true, deployment_status ? deployment_status : this.state?.selectedDeploymentStatus) as any
            if(demoCheck){
                this.redact(sensorBoxList.result);
            }
            this.setState(state=>({...state, sensorBoxList: sensorBoxList.result, sensorBoxCount: sensorBoxList.count, currentPage: pageNumber, isLoading: false }))
        } catch (error) {            
            if (error.message !== "Operation canceled due to new request.") {
                this.setState({ isLoading: false })
                toast.error(error.response?.data?.message)
            }
        }
    }

    updateOfflineStatus(event_data: any){
        let sensorBoxes = this.state.sensorBoxList;        
        if(event_data?.groupId) {
            let deviceIndex = sensorBoxes?.findIndex((device: any) => device?._id === event_data?.groupId);
            if(deviceIndex >= 0 ) {
                let childIndex = sensorBoxes[deviceIndex]?.sensorboxes.findIndex((sb: any) => sb?.sensor_box_name === event_data.sensor_box_name);
                if(childIndex >= 0) {
                    if(sensorBoxes[deviceIndex].sensorboxes[childIndex].is_online !== event_data?.data?.is_online){
                        sensorBoxes[deviceIndex].sensorboxes[childIndex].is_online = event_data?.data?.is_online;
                        this.setState({ sensorBoxList: sensorBoxes })
                    }

                }
            }
        }else {
            let sensorboxIndex = sensorBoxes?.findIndex((sb: any) => sb?.sensor_box_name === event_data.sensor_box_name);
            if(sensorboxIndex >= 0) {
                if(sensorBoxes[sensorboxIndex].is_online !== event_data?.data?.is_online){
                    sensorBoxes[sensorboxIndex].is_online = event_data?.data?.is_online;
                    this.setState({ sensorBoxList: sensorBoxes })
                }
            }
        } 
    }

    updateConfigStatus(eventdata : any){

        const sensorboxes = this.state.sensorBoxList.slice();
        if(eventdata?.groupId) {
          let deviceIndex = sensorboxes?.findIndex((device: any) => device?._id === eventdata?.groupId);
          if(deviceIndex >= 0 ) {
            let childIndex = sensorboxes[deviceIndex]?.sensorboxes.findIndex((sb: any) => sb?.sensor_box_name === eventdata?.sensor_box_name);
            if(childIndex >= 0) {
              sensorboxes[deviceIndex].sensorboxes[childIndex].device_config_status = eventdata?.data?.status;
            }
          }
        }else {
          let index = sensorboxes.findIndex(s => s?.sensor_box_name === eventdata?.sensor_box_name);
          if(index >= 0) {
            sensorboxes[index].device_config_status = eventdata?.data?.status;
          }
        }
        this.setState({ sensorBoxList: sensorboxes });
  
        if(this.state.selectedSensorBox?.sensor_box_name === eventdata?.sensor_box_name) {
          const sensorbox = { ...this.state.selectedSensorBox, device_config_status: eventdata?.data?.status } as SensorBox
          this.setState({ selectedSensorBox: sensorbox })
        }

    }

    listenAndUpdateSensordata = () => {
        if (eventStore.eventSource) {
            let that = this
            eventStore.eventSource.onmessage = function (event) {
                if (event.data !== "CONNECTED") {
                    let event_data = JSON.parse(event.data);
                    if(event_data?.type === "OFFLINE_STATUS_UPDATE") {
                        that.updateOfflineStatus(event_data)
                        return
                    }

                    if(event_data?.type === "CONFIG_UPDATE") {
                        that.updateConfigStatus(event_data)
                        return
                    }

                    let newSensorBoxList = Utility.updateSensoBoxData(that.state.sensorBoxList, event_data)
                    that.setState({ sensorBoxList: newSensorBoxList })
                }
            }
        }
    }

    setSearchFilter(value: string) {
        this.setState({ searchFilter: value, currentPage: 1 }, () => {
            this.searchSensorBoxes()
        })
    }

    getOrganisationsSensorData :any = async () => {
        try {
                const response = await organizationService.getOrganizations()
                this.setState({organizationList : response.data.organizations , organizationFilter : response.data.organizations[0]})
        } catch (error) {
                toast.error(error.response.data.message)
                this.setState({isLoading : false})
        }
    }

    clearSelect = () => {
        this.setState({selectedSensorBox : {} as SensorBox})
    }

    showResetSensorBoxModal(item : any) {
        this.setState({ selectedSensorBox : item ,showResetSensorBoxModal :true })
    }

    handleFilterOrganization(option: any){
        this.setState({organizationFilter : option},()=>{
            // note : temp solution for the org admin access to all org
           this.getContractorList()
           this.getSensorBoxes(1);
        })
    }

    handleDeploymentStatus(option: any){
        this.setState({selectedDeploymentStatus : option?._id},()=>{
            // note : temp solution for the org admin access to all org
           this.getSensorBoxes(1);
        })
    }

    shouldPageChange = (isAdd: boolean) => {
        let currentPage = 0;
        if (isAdd) {
          currentPage = Math.ceil( (this.state.sensorBoxCount + 1)/constants.maxLimit );
        } else if(this.state.sensorBoxList.length === 1 && this.state.currentPage > 1) {
          currentPage = this.state.currentPage -1
        }
    
        return currentPage;
      }
    

    showSensordataModal(sensorDetails: any) {
        this.setState({ selecetedSensorBox: sensorDetails , showSensorDataModal :true })
        Utility.showModal('sensordetails')
    }

    closeSensordataModal = () => {
        this.setState({ selecetedSensorBox: {}  , showSensorDataModal :false})
        Utility.closeModal('sensordetails')
    }

    closeResetSensorBoxModal = () => {
        this.setState({ showResetSensorBoxModal: false })
    }

    showExportModal(exportData: any) {
        
        if(!exportData.group){
            this.setState({ selectedSensorBoxName: exportData.sensor_box_name, selecetedSensorBox: exportData, showExportModal :true})
        }else{
            this.setState({ selectedGroupId: exportData._id, selecetedSensorBox: exportData, showExportModal :true })
        }
    }

    closeExportModal = () => {
        this.setState({ selectedSensorBoxName: '', selecetedSensorBox: '', selectedGroupId:'', showExportModal :false })
    }

    showGraphModal(sensorDetails: any) {
        this.setState({
            selectedSensorBoxName: sensorDetails.sensor_box_name,
            selecetedSensorBox: sensorDetails,
            showGraphModal: true
        })
        document.querySelector('body')?.classList.add('modal-open');
    }
    showGraphModalForGroup(sensorDetails: any) {
        this.setState({
            selectedSensorBoxName: sensorDetails.sensor_box_name,
            selecetedSensorBox: sensorDetails,
            showGraphModalForGroup: true
        })
        document.querySelector('body')?.classList.add('modal-open');
    }
    closeGraphModal = () => {
        this.setState({
            selectedSensorBoxName: '',
            selecetedSensorBox: {},
            showGraphModal: false
        })
        document.querySelector('body')?.classList.remove('modal-open');
    }
    closeGroupGraphModal = () => {
        this.setState({
            selectedSensorBoxName: '',
            selecetedSensorBox: {},
            showGraphModalForGroup: false
        })
        document.querySelector('body')?.classList.remove('modal-open');
    }

    showMaintenanceModal = (sensorDetails: any) => {
        this.setState({
            selectedSensorBoxName: sensorDetails.sensor_box_name,
            selecetedSensorBox: sensorDetails,
            showMaintenanceModal: true
        })
    }

    closeMaintenanceModal = () => {
        this.setState({
            showMaintenanceModal: false
        })
    }


    async getContractorList() {
        try {

            let contractorList = await userService.getContractorList(this.state.organizationFilter._id)
            if(authStore?.currentUser?.active_role?.role_type === -1) {
                contractorList = contractorList.map((item: any, index :any) => ({...item, name: `Contractor ${index+1}`}))
            }

            this.setState({ contractorList: contractorList });

        } catch (error) {
            toast.error(error.response.data.message)
        }
    }

    updateContractor = async (contractor: any) => {
        this.setState({ contractor: contractor }, () => {
            this.getSensorBoxes(1)
        });
    }

    sortDevice = () => {
        const { deviceSort } = this.state;
        this.setState({ deviceSort: deviceSort === 'group' ? 'sensorbox' : 'group' }, () => {
          this.getSensorBoxes(1);
        })
      }
    
      sortStatus = () => {
        const { statusSort } = this.state;
        this.setState({ statusSort: statusSort === 'desc' ? 'asc' : 'desc' }, () => {
          this.getSensorBoxes(1);
        })
      }


    showAlertLogsModal = (sensorDetails: any) => {
        this.setState({
            selectedSensorBoxName: sensorDetails.sensor_box_name,
            selecetedSensorBox: sensorDetails,
            showAlertLogsModal: true,
            lastTab:'status'
        })
    }


    showIssueCreationPage = (sensorDetails: any) => {

        let orgid = org_id ? org_id : this.state.organizationFilter._id

        let params = `?system_id=${sensorDetails?._id}&status=${sensorDetails?.status}`

        if(authStore?.currentUser?.active_role?.role_type === 0 || (authStore?.currentUser?.active_role?.role_type === 1 && authStore.currentUser.is_default)){
            params += `&org_id=${orgid}`
        }

        if(sensorDetails?.alert_datas.length){

            if(sensorDetails?.alert_datas[0]?.description){
                params += `&alert_description=${sensorDetails?.alert_datas[0]?.description}`
            }

            if(sensorDetails?.alert_datas[0]?.diagnostics_code){
                params += `&alert_code=${sensorDetails?.alert_datas[0]?.diagnostics_code}`
            }
        }

        this.props.history.push({
            pathname: `${Routes.issuetrackerdetails}`,
            search: params,
          });
    }


    setVisible = () => {
        this.setState({visible:false});
    }

    closeAlertLogsModal = () => {
        this.setState({
            showAlertLogsModal: false,
            selectedSensorBoxName: '',
            selecetedSensorBox: {},
        })
    }

    showLogDataModal = (logData: any) => {
        this.setState({ selectedLog: logData, isLogDataVisible: true, showAlertLogsModal: false })
    }
    showInvalidRangeAlertModal = (logData: any) => {
        this.setState((state)=>({...state, invalidRangeAlertData: logData, isInvalidRangeDataVisible: true, showAlertLogsModal: false }))
    }

    closeLogDataModal = () => {
        this.setState({ selectedLog: {}, isLogDataVisible: false, showAlertLogsModal: true ,lastTab: 'status'})
    }
    closeInvalidRangeAlertModal = () => {
        this.setState({ invalidRangeAlertData: {}, isInvalidRangeDataVisible: false, showAlertLogsModal: true,lastTab: 'invalid-range' })
    }

    onAddBtnClick = (eventKey: string | null) => {
        if(eventKey === 'group') {
          this.setState({ createGroupModalVisible: true })
        }else if(eventKey === 'sensorbox') {
          this.showSensorBoxForm()
        }
    }
renderCreateSensorboxForm(){
        return <SensorBoxForm
            sensorbox = {this.state.selectedSensorBox} updateSensorBoxList = {this.getSensorBoxes}
            clearSelectedSensorBox = {this.clearSelect} shouldPageChange={this.shouldPageChange}
            setVisibleClose={this.setVisible}
            updateEventSourceListener={this.listenAndUpdateSensordata}
            organisationId={org_id ? org_id : this.state.organizationFilter ? this.state.organizationFilter._id : ''}
            organisationList = {this.state.organizationList ? this.state.organizationList : []}/>
}
    showSensorBoxForm(item? : SensorBox) {

        const regularUser = ((
          item?.contractor?.[0]?._id === authStore?.currentUser?.uid
            || ( authStore?.currentUser?.access.includes(view_assigned_users_sensorboxes_assets) ? item?.contractor?.[0]?._id === authStore?.currentUser?.assigned_to : false)
            || authStore?.currentUser?.active_role?.role_type < 2) 
        )
    
          if(item?.group) {
            const list = this.state.sensorBoxList.slice();
            let index = list.findIndex(d => item?._id === d?._id);
            list[index].open = !list[index].open;
            this.setState({
              sensorBoxList: list
            })
            return
          }
    
            if(item?.is_deleted){
              this.showResetSensorBoxModal(item)
            }else{
              if(item?.user_access?.includes(authStore?.currentUser?.uid ) && !regularUser){
                return
              }
              
              this.setState({selectedSensorBox : item, visible:true},()=>{
                Utility.showModal('sensorboxdetails')
              })
            }
    }

    resetSensorBox = async (item : any ,data : any) => {
        try {
            if(data === 'hardelete'){
                await userService.hardDeleteSensorBox(item?._id)
            }else{
                await userService.resetSensorBox(item._id)
            }
          this.setState({ showResetSensorBoxModal: false })
          await this.getSensorBoxes(this.state.currentPage)
        } catch (error) {
            toast.error(error.response.data.message)
        }
    }

    onClickShowAll =() => {
        this.props.history.push(Routes.systems);
        this.props.history.go(0)
    }

    showPowerConsumptionModal = (sensorDetails: any) => {
        if(!sensorDetails.group){
            this.setState({ selectedSensorBoxName: sensorDetails.sensor_box_name, showPowerConsumptionModal :true, selectedSystemName : sensorDetails?.alias ? sensorDetails?.alias : sensorDetails?.sensor_box_name})
        }else{
            this.setState({ selectedGroupId: sensorDetails._id, showPowerConsumptionModal :true, selectedSystemName : sensorDetails?.name })
        }
    }

    closePowerConsumptionModal = () => {
        this.setState({ selectedSensorBoxName: '', selectedGroupId:'', showPowerConsumptionModal :false, selectedSystemName : '' })
    }

    showDeviceLog = (item: SensorBox) => {
        this.setState({selectedSensorBox : item, DeviceConfigLogModalVisible:true});
    }

    redact = (list) => {
        try{
            const clients = ['Kuehne Nagel','First Co'];
            for (const sb of list) {
                if (sb.group) {
                    for(const box of sb.sensorboxes){
                        if (box.alias) {
                            clients?.forEach(client => {
                                box.alias = box.alias.replace(client, '')
                            })
                        }

                    }
                }
            }
        }catch (e){
            console.log(e)
        }

    }
    render() {
        const demoCheck = (authStore?.currentUser?.active_role?.role_type === -1);
        const headerClassName = ['']
        if(authStore?.currentUser?.active_role?.role_type === 1 && authStore?.currentUser?.is_default && !system_id && !status ){
            headerClassName.push(' col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-4')
        }
        else if(authStore?.currentUser?.active_role?.role_type > 1 && !system_id && !status){
            headerClassName.push(' col-md-10')
        }else if(authStore?.currentUser?.active_role?.role_type === 0 && !system_id && !status){
            headerClassName.push('col-xs-12 col-sm-12 col-md-4 col-lg-6 col-xl-6')
        }
        else{
            headerClassName.push('col-xs-12 col-sm-12 col-md-4 col-lg-6 col-xl-8')
        }

        let devicesWidth = '20%'
        let lastUpdatedWidth = '17%'
        if(authStore?.currentUser?.active_role?.role_type !== 4) {
            devicesWidth = '17%'
            lastUpdatedWidth = '13%'
        }

        if(authStore?.currentUser?.active_role?.role_type === 1) {
            devicesWidth = '13%'
            lastUpdatedWidth = '10%'
        }

        const isSuperAdmin = authStore?.currentUser?.active_role?.role_type === 0 ? true : false

        const deploymentStatusValue = deploymentStatusType.find((status:any)=>(status?._id === this.state?.selectedDeploymentStatus))

        return (

            <div>
                <div className="row mb-25">
                    <div className={headerClassName.join(' ')}>
                        <p className="table-title media-padding">Details</p>
                    </div>                     
                        {(authStore?.currentUser?.active_role?.role_type === 0 || (authStore?.currentUser?.active_role?.role_type === 1 && authStore.currentUser.is_default)) && !system_id && !status &&
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-2 media-margin-tp">
                                <div className="filter-select">
                                    <label className="custom-label">Organization</label>
                                        <Select name="typeFilter"
                                                data={this.state.organizationList}
                                                value={this.state.organizationFilter}
                                                onChange={(e:any)=>this.handleFilterOrganization(e)}
                                        />
                                </div>
                            </div>
                        }
                        {(authStore?.currentUser?.active_role?.role_type === 0 || (authStore?.currentUser?.active_role?.role_type === 1 && authStore.currentUser.is_default)) && !system_id && !status &&
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-2 media-margin-tp">
                                <div className="filter-select">
                                    <label className="custom-label">Deployment Status</label>
                                        <Select name="typeFilter"
                                                data={deploymentStatusType}
                                                value={deploymentStatusValue}
                                                onChange={(e:any)=>this.handleDeploymentStatus(e)}
                                        />
                                </div>
                            </div>
                        }
                        {!authStore?.currentUser?.access?.includes(super_admin_access) && authStore?.currentUser?.access?.includes(view_all_sensorboxes_assets) && !system_id && !status &&
                            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-2 media-margin-tp">
                                <div className="filter-select">
                                    <div className="form-group mb-0 select-clos-btn">
                                        <label className="custom-label">Contractor</label>
                                            <Select name="contractor"
                                                isDisabled={false}
                                                data={this.state.contractorList}
                                                value={this.state.contractor ? this.state.contractor : ''}
                                                onChange={(value: any) => this.updateContractor(value)}
                                                showClose={this.state.contractor && Object.keys(this.state.contractor).length !== 0}
                                                clearValue={() => this.updateContractor(null)}
                                            />
                                    </div>
                                </div>
                            </div>
                        }    
                        {  !system_id  && !status &&                 
                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-2 media-margin-tp mt-auto">
                              <Search
                                placeholder="Search"
                                value={this.state.searchFilter ? this.state.searchFilter : ''}
                                size="large"
                                onChange={(e) => this.setSearchFilter(e.target.value)}
                                allowClear
                                enterButton />
                        </div>
                        }
                        {(system_id || status) &&
                            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-4 media-margin-tp mt-auto text-right">
                                <button type="button" 
                                    disabled={false} 
                                    className="btn btn-primary"
                                    onClick={this.onClickShowAll}
                                >Show All
                                </button>
                            </div>
                        }

                </div>

                {this.state.sensorBoxList.length !== 0 ?
                    <div className="table-wrap">
                        <table className="table table-custom custom-table table-style table-hover" style={{ tableLayout: 'fixed' }}>
                            <thead className="table-header-bg">
                                <tr>
                                    <th style={{ width: '2%' }} className="" scope="col"></th>
                                    <th style={{ width: devicesWidth }} className="cursor-pointer" scope="col" onClick={this.sortDevice}>
                                        Devices
                                        <i className={`fa ml-3 ${this.state.deviceSort === 'group' ? 'fa-caret-up': 'fa-caret-down'}`}></i>
                                    </th>
                                    <th style={{ width: '10%' }} className="" scope="col">Consumer</th>
                                    <th style={{ width: '9%' }} className="" scope="col">Address</th>
                                    <th style={{ width: lastUpdatedWidth }} className="" scope="col">Last Update</th>
                                    <th style={{ width: '7%' }} className="text-center cursor-pointer" scope="col"  onClick={this.sortStatus}>
                                        Status
                                        <i className={`fa ml-3 ${this.state.statusSort === 'desc' ? 'fa-caret-up': 'fa-caret-down'}`}></i>
                                    </th>
                                    <th style={{ width: '7%' }} className="text-center" scope="col">View Details</th>
                                    <th style={{ width: '7%' }} className="text-center" scope="col">Export</th>
                                    <th style={{ width: '7%' }} className="text-center" scope="col">View Graph</th>
                                    <th style={{ width: '7%' }} className="text-center" scope="col">Service History</th>
                                    <th style={{ width: '7%' }} className="text-center" scope="col">Alert Logs</th>
                                    {authStore?.currentUser?.active_role?.role_type === 1 &&
                                    <th style={{ width: '7%' }} className="text-center" scope="col">Issue Tracker</th>
                                    }
                                    {authStore?.currentUser?.active_role?.role_type !== 4 &&
                                    <th style={{ width: '7%' }} className="text-left" scope="col">Config</th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.sensorBoxList ?
                                    this.state.sensorBoxList.map((item: any, index) => {
                                        const tableClassName = ['cursor-pointer']
                                        if(item?.is_deleted){
                                            tableClassName.push('sensordata-grey-tr')
                                        }
                                        const consumerName = demoCheck ?
                                        `Consumer ${index+1}` :
                                        ( item?.consumer?.[0]?.name ?? '')

                                        const place = demoCheck ?
                                        `-` :
                                        (item?.group === 0 ? item?.place || '' : item?.sensorboxes[0]?.place)
                                        return (
                                            <>
                                            <tr className={tableClassName.join(' ')} key={index}  onClick={(e : any) => {
                                                if(item?.group) {
                                                    const list = this.state.sensorBoxList.slice();
                                                    let index = list.findIndex(d => item?._id === d?._id);
                                                    list[index].open = !list[index].open;
                                                    this.setState({sensorBoxList: list})
                                                    return
                                                }else{                                                   
                                                    this.showSensorBoxForm(item)
                                                }
                                            }} >
                                                <th className="" scope="row">
                                                    {/* {(constants.maxLimit * (this.state.currentPage - 1)) + index + 1} */}
                                                    {
                                                        item?.group === 1 ?
                                                        <i style={{fontSize: 12}} className={ item?.open ? 'fa fa-chevron-down' : 'fa fa-chevron-right'}aria-hidden="true"></i>
                                                        :
                                                        <i title={item?.is_online ? 'Online' : 'Offline'} className={ item?.is_online ? 'fa fa-signal online-status' : 'fa fa-ban offline-status'} aria-hidden="true"></i>
                                                    }
                                                </th>
                                                <td className="text-truncate" title={
                                                    item && item.group ? item.name : item && item.sensor_box_name
                                                }>
                                                    {
                                                        demoCheck ?
                                                        item && item.group  ? item.name : item.alias ||item.sensor_box_name :
                                                        (item && item.group ? item.name : item && item.alias ? item.alias : item.sensor_box_name)
                                                    }
                                                </td>
                                                <td className="text-truncate" title={consumerName}>
                                                    {
                                                     consumerName
                                                    }
                                                </td>
                                                <td className="text-truncate" title={place}>
                                                    {
                                                     place
                                                    }
                                                </td>
                                                <td className="text-truncate" title = {item?.last_updated_time ? moment(item?.last_updated_time).format('MM/DD/YYYY, HH:mm:ss') : '-'}>
                                                    {item?.last_updated_time ? moment(item?.last_updated_time).format('MM/DD/YYYY, HH:mm:ss') : '-'}
                                                </td>
                                                <td className="text-center" title = {item.status === -1 ? 'Algorthm not defined' : ''}>
                                                    <i className={`fa fa-circle ${Utils.getSensorStatus(item.status)} px-3`} aria-hidden="true"></i>
                                                </td>
                                                <td className="text-center">
                                                    {
                                                    (item?.device_type === 'general' || item?.group === 1) &&
                                                        <i className="fa fa-eye cursor-pointer px-3" onClick={(e: any) =>{  e.stopPropagation(); this.showSensordataModal(item); }} aria-hidden="true"></i>
                                                    }
                                                </td>
                                                <td className="text-center">
                                                    {
                                                        // item?.group === 0 &&
                                                        <i className="fa fa-download cursor-pointer px-3" onClick={(e : any) => { e.stopPropagation(); this.showExportModal(item); }} aria-hidden="true"></i>
                                                    }
                                                </td>
                                                <td className="text-center">
                                                    {
                                                     ((item?.device_type === 'general' && item?.group === 0) || (item?.group === 1 && item?.sensorboxes?.length > 1)) &&
                                                        <i className="fa fa-line-chart px-3" onClick={(e: any) =>{ e.stopPropagation();item?.group === 0?this.showGraphModal(item): this.showGraphModalForGroup(item);}} aria-hidden="true"></i>
                                                    }
                                                </td>
                                                <td className="text-center">
                                                    {
                                                        item?.group === 0 &&
                                                        <i className="fa fa-wrench px-3" onClick={(e : any) =>{ e.stopPropagation(); this.showMaintenanceModal(item);}} aria-hidden="true"></i>
                                                    }
                                                </td>
                                                <td className="text-center">
                                                    {
                                                        item?.group === 0 &&
                                                        <i className="fa fa-file-text-o cursor-pointer px-3" onClick={(e: any) => {e.stopPropagation(); this.showAlertLogsModal(item);}} aria-hidden="true"></i>
                                                    }
                                                </td>
                                                {authStore?.currentUser?.active_role?.role_type === 1 &&
                                                <td className="text-center">
                                                    {item?.group === 0 && authStore?.currentUser?.active_role?.role_type === 1 &&
                                                    <i className="fa fa-flag cursor-pointer px-3" onClick={(e : any) => { e.stopPropagation(); this.showIssueCreationPage(item);}} aria-hidden="true"></i>
                                                    }                                           
                                                </td>
                                                }
                                                {authStore?.currentUser?.active_role?.role_type !== 4 &&
                                                <td className="text-left">
                                                    {item?.device_config_status &&
                                                    <span className="btn-link td-data" onClick={(e) => {
                                                        e.stopPropagation();
                                                        this.showDeviceLog(item);
                                                    }}>
                                                      {constants.deviceStatus.get(item?.device_config_status)?.text || ''}
                                                    </span>
                                                  }
                                                </td>
                                                }
                                            </tr>
                                            {
                                                item?.open &&
                                                <>
                                                    {
                                                        item?.sensorboxes?.map((box: any, i: number) => (
                                                            <tr className="cursor-pointer" onClick={()=>{this.showSensorBoxForm(box)}}>
                                                                <th className="" scope="row">
                                                                {
                                                                    <i title={box?.is_online ? 'Online' : 'Offline'} className={ box?.is_online ? 'fa fa-signal online-status' : 'fa fa-ban offline-status'} aria-hidden="true"></i>
                                                                }                                                                
                                                                </th>
                                                                
                                                                <td className="text-truncate" title={box?.sensor_box_name}>
                                                                    {
                                                                        demoCheck ?
                                                                        box?.alias || box?.sensor_box_name :
                                                                        (box?.alias ? box?.alias : box?.sensor_box_name)
                                                                    }
                                                                </td>
                                                                <td className="text-truncate">
                                                                    {
                                                                        demoCheck ?
                                                                        `Consumer ${index+1}` :
                                                                        (box?.consumer?.[0]?.name ?? '')
                                                                    }                                   
                                                                </td>
                                                                <td className="text-truncate">
                                                                    {
                                                                        demoCheck ?
                                                                        `-` :
                                                                        (box.place ? box.place : "" )
                                                                        }
                                                                </td>
                                                                <td className="text-truncate">
                                                                    {box?.last_updated_time ? moment(box?.last_updated_time).format('MM/DD/YYYY, HH:mm:ss') : '-'}
                                                                </td>
                                                                <td className="text-center" title = {box.status === -1 ? 'Algorthm not defined' : ''}>
                                                                    <i className={`fa fa-circle ${Utils.getSensorStatus(box.status)} px-3`} aria-hidden="true"></i>
                                                                </td>
                                                                <td className="text-center">
                                                                {box?.device_type === 'general' &&
                                                                    <i className="fa fa-eye cursor-pointer px-3" onClick={(e : any) => { e.stopPropagation(); this.showSensordataModal(box)}} aria-hidden="true"></i>
                                                                }
                                                                </td>
                                                                <td className="text-center">
                                                                    <i className="fa fa-download cursor-pointer px-3" onClick={(e : any) => { e.stopPropagation(); this.showExportModal(box);}} aria-hidden="true"></i>
                                                                </td>
                                                                <td className="text-center">
                                                                    <i className="fa fa-line-chart px-3" onClick={(e : any) =>{ e.stopPropagation(); this.showGraphModal(box);}} aria-hidden="true"></i>
                                                                </td>
                                                                <td className="text-center">
                                                                    <i className="fa fa-wrench px-3" onClick={(e : any) => { e.stopPropagation(); this.showMaintenanceModal(box);}} aria-hidden="true"></i>
                                                                </td>
                                                                <td className="text-center">
                                                                <i className="fa fa-file-text-o cursor-pointer px-3" onClick={(e : any) => { e.stopPropagation(); this.showAlertLogsModal(box);}} aria-hidden="true"></i>
                                                                </td>
                                                                {authStore?.currentUser?.active_role?.role_type === 1 &&
                                                                <td className="text-center">
                                                                { authStore?.currentUser?.active_role?.role_type === 1 &&
                                                                <i className="fa fa-flag cursor-pointer px-3" onClick={(e : any) => { e.stopPropagation(); this.showIssueCreationPage(box);}} aria-hidden="true"></i>
                                                                }
                                                                </td>
                                                                }

                                                                {authStore?.currentUser?.active_role?.role_type !== 4 &&
                                                                <td className="text-left">
                                                                {box?.device_config_status &&
                                                                    <span className="btn-link td-data" onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        this.showDeviceLog(box);
                                                                    }}>
                                                                    {constants.deviceStatus.get(box?.device_config_status)?.text || ''}
                                                                    </span>
                                                                }
                                                                </td>
                                                                }
                                                            </tr>
                                                        ))
                                                    }
                                                </>
                                            }
                                            </>
                                        )

                                    })
                                    : null
                                }
                            </tbody>
                        </table>
                    </div>
                    :
                    <div className="text-center w-100 no-card-ph">
                        <div className="no-result-container">
                            {!this.state.isLoading ?
                                <p className="no-cards"> {this.state.searchFilter === '' ? "No data found!" : "No results found!"}</p>
                                : ''}
                        </div>
                    </div>
                }

                <div className="pagination-container">

                    {Math.ceil(this.state.sensorBoxCount / Constants.maxLimit) > 1 ?

                        <Pagination
                            hideNavigation
                            activePage={this.state.currentPage}
                            itemsCountPerPage={Constants.maxLimit}
                            totalItemsCount={this.state.sensorBoxCount}
                            itemClass={"item-class"}
                            activeClass={"active-class"}
                            pageRangeDisplayed={5}
                            onChange={(pageNumber) => {
                                this.setState({ isLoading: true }, () => {
                                    this.getSensorBoxes(pageNumber, system_id , status)
                                })
                            }}
                        />

                        : null
                    }
                </div>


                {this.state.isLoading ?
                    <LoadingWheel />
                    : null}
                <SensorData 
                    show={this.state.showSensorDataModal}
                    closeModal={this.closeSensordataModal} 
                    sensorBox={this.state.selecetedSensorBox}/>
                <ExportModal
                    show={this.state.showExportModal}
                    closeModal={this.closeExportModal}
                    SensorBoxName={this.state.selectedSensorBoxName}
                    GroupId={this.state.selectedGroupId}
                    orgId = {this.state.organizationFilter?._id}
                />
                {this.state.showGraphModal
                    ? <GraphModal
                        SensorBoxName={this.state.selectedSensorBoxName}
                        closeGraphModal={this.closeGraphModal}
                        sensorBox={this.state.selecetedSensorBox}
                    />
                    : null}
                {this.state.showGraphModalForGroup
                    ? <GraphModalGroup
                        sensorGroupName={this.state.selecetedSensorBox.name}
                        closeGraphModal={this.closeGroupGraphModal}
                        sensorGroup={this.state.selecetedSensorBox}
                    />
                    : null}
                <MaintenanceModal
                    show={this.state.showMaintenanceModal}
                    onClose={this.closeMaintenanceModal}
                    sensorBoxName={this.state.selectedSensorBoxName}
                    sensorBox={this.state.selecetedSensorBox}
                />
                <AlertLogsModal
                    show={this.state.showAlertLogsModal}
                    onClose={this.closeAlertLogsModal}
                    sensorBoxName={this.state.selectedSensorBoxName}
                    sensorBox={this.state.selecetedSensorBox}
                    showLogDataModal={(logData:any) => this.showLogDataModal(logData)}
                    showInvalidRangeAlertModal={(logData:any) => this.showInvalidRangeAlertModal(logData)}
                    lastTab={this.state.lastTab}
                />
                <PowerGraphModal
                    show={this.state.showPowerConsumptionModal}
                    onClose={this.closePowerConsumptionModal}
                    systemId={this.state.selectedSensorBoxName ? this.state.selectedSensorBoxName : this.state.selectedGroupId}
                    type={this.state.selectedGroupId ? 'group' : 'single'}
                    SystemName = {this.state.selectedSystemName}
                />
                <LogDataModal 
                    logData={this.state.selectedLog.values} 
                    sensorBoxName = {this.state.selectedLog.sensorbox_name} 
                    onClose={this.closeLogDataModal}
                    isVisible={this.state.isLogDataVisible}
                />
                <InvalidRangeAlertModal
                    logData={this.state.invalidRangeAlertData}
                    sensorBoxName = {this.state.invalidRangeAlertData.sensorbox_name}
                    onClose={this.closeInvalidRangeAlertModal}
                    isVisible={this.state.isInvalidRangeDataVisible}
                />

                <CreateGroupModal
                        show={this.state.createGroupModalVisible}
                        onClose={()=> this.setState({ createGroupModalVisible: false })}
                        // note : temp solution for the org admin access to all org
                        organisationId={this.state.organizationFilter ? this.state.organizationFilter._id : ''}
                        onSave={() => {
                            this.setState({isLoading : true}, () => {
                            this.getSensorBoxes(1)
                            })
                        }}
                />

                <DeviceConfigLogModal
                        show={this.state.DeviceConfigLogModalVisible}
                        onClose={()=> this.setState({ DeviceConfigLogModalVisible: false })}
                        sensorbox={this.state.selectedSensorBox}
                />

                {this.state.visible ?
                        this.renderCreateSensorboxForm()
                        : null }

                <ResetHardeleteSensorbox
                            title={isSuperAdmin ? "Reset / Hard Delete Sensorbox" : 'Reset Sensorbox'}
                            message={isSuperAdmin ? "Do you want to" : "Are you sure want to reset this sensorbox"}
                            visible = {this.state.showResetSensorBoxModal}
                            confirm ={"YES"}
                            action={(data? : any) => (this.resetSensorBox(this.state.selectedSensorBox , data))}
                            toggleVisibility={this.closeResetSensorBoxModal}
                            isSuperAdmin = {isSuperAdmin}
                />

                {authStore?.currentUser?.access?.includes(create_or_update_sensorboxes_assets) && !system_id && !status &&
                    <div className="">
                        <Dropdown drop="up" onSelect={this.onAddBtnClick}>
                        <Dropdown.Toggle
                            className="add-icon-dashboard"
                            bsPrefix={'add'}
                        >
                            <i className="fa fa-plus" aria-hidden="true"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dashboard-add-menu">
                            <Dropdown.Item eventKey="sensorbox" className="dashboard-add-menu-item">
                            <span>
                                <img className="mr-2" src={sensorIcn} height={12} alt=''/>
                                Add sensor box
                            </span>
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="group" className="dashboard-add-menu-item">
                            <span>
                                <img className="mr-2" src={groupIcn} height={12} alt=''/>
                                Create asset
                            </span>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                        </Dropdown>
                    </div>
                }
                </div>
        )
    }
}

export default withRouter(UserList)

