import React, {Fragment, useEffect, useRef, useState} from "react";

import Rev7aBoardDiagram from './Rev7aBoardDiagram'
import './Rev7aSensorsTab.css'
import '../../components/checkbox/checkbox.css'
import {CabledRHConfigForm, OnboardRHConfigForm, SensorConfigForm, ThermocoupleConfigForm} from './ComponentListItem'
import toast from "../toast";
import sensorService from "../../services/sensorService";
import {Tab, Tabs} from "react-bootstrap";
import {Button} from "antd";
import {CloseOutlined} from "@ant-design/icons";
import _ from "lodash";
import {PORT_TYPE, PORTS_CONFIG, SENSING_ELEMENT} from "../../config/constants";


let TYPES_COMPONENTS_MAPPING = {}


const SENSOR_TEMPLATE = {
    is_calculated: false,
    is_deleted: false,
    sensor_id: 0,
    alias: '',
    seqNum: 1,
    comp_type: "select",
    type: "select",
    sub: 'select',
    port_type: PORT_TYPE.ETHERNET,
    port: 1,
    pin: 1,
    sensing_element: SENSING_ELEMENT.default,

}
export const RH_SENSOR_TEMPLATE = {
    is_calculated: false,
    is_deleted: false,
    sensor_id: 0,
    alias: '',
    seqNum: 1,
    comp_type: "select",
    type: "select",
    sub: 'select',
    port_type: PORT_TYPE.ONBOARD,
    min: '',
    max: '',
    sensing_element: SENSING_ELEMENT.default,
    calibration: {
        a: '',
        b: '',
        c: '',
        d: ''
    }
}

const BOARDS = {
    seqNum: 1,
    is_master: true,
    daisy_chained: false,
    sensors: [
        {...SENSOR_TEMPLATE}
    ]
}

export const Rev7aSensorsTab = (props: any) => {

    const [boards, setBoards] = useState<any>([])
    const [loading, setLoadng] = useState(false);
    const [activeKey, setActiveKey] = useState(() => 1);
    const newTabIndex = useRef(() => 0);
    useEffect(() => {
        fetchTypesComponentsMapping().then(() => {
            setLoadng(false)
        })
        setBoards(_.cloneDeep(props.sensorbox.boards) ?? [BOARDS])
    }, []);
    useEffect(() => {
        setBoards(_.cloneDeep(props.sensorbox.boards) ?? [BOARDS])
    }, [props.sensorbox]);
    useEffect(() => {
        console.log(boards)
        props.onChange({...props.sensorbox, boards})
    }, [boards]);
    const fetchTypesComponentsMapping = async () => {
        TYPES_COMPONENTS_MAPPING = await sensorService.getRev7aComponentsTypesMapping()
    }
    const addBoard = () => {
        setBoards((prevBoards: any[]) => {
            const prev = [...prevBoards]
            if (prev.length > 0)
                prev[prev.length - 1].daisy_chained = true
            return [...prev, {
                is_master: false,
                daisy_chained: false,
                seqNum: prevBoards.length + 1,
                sensors: [
                    {...SENSOR_TEMPLATE}
                ]
            }]
        })
        setActiveKey(boards.length + 1)
    };
    const deleteBoard = (key: any) => {
        if (key > 1) {
            setBoards((prev: any[]) => {
                const boards = [...prev],
                    index = key - 1
                boards.splice(index, 1)
                for (let i = index - 1; i < boards.length; i++) {
                    boards[i].seqNum = i + 1
                }
                boards[boards.length - 1].daisy_chained = false
                return boards
            })
            setTimeout(() => {
                setActiveKey(key)
            }, 300)
        }
    }
    const addCabledRHSensor = () => {
        setBoards((prev: any) => {
            let boardIndex = activeKey - 1
            const boards = [...prev];
            const existingCabledRhConnections: any[] = boards[boardIndex].sensors.filter((x: {
                port: number;
                port_type: PORT_TYPE;
            }) => x.port == 2 && x.port_type == PORT_TYPE.ONBOARD)
            if (existingCabledRhConnections.length == 8)
                return prev
            if (!validateRev7aSensorState(existingCabledRhConnections))
                return prev
            if (existingCabledRhConnections.some((x: any) => x.pin == 0)) {
                toast.error('incomplete sensor configuration exists, select a valid pin!')
                return prev
            }
            const conf = {
                ...RH_SENSOR_TEMPLATE,
                port: 2,
                pin: 0,
                seqNum: 1,
            }
            boards[boardIndex].sensors = [conf, ...boards[boardIndex].sensors]
            return boards
        })
    }
    const addEthernetPin = (sensor: any) => {
        let conf: any = {...SENSOR_TEMPLATE}
        if (!(sensor.port === 1 || sensor.port === 7)) {
            conf = {
                ...conf,
                calibration: {
                    a: '',
                    b: '',
                    c: '',
                    d: ''
                },
                min: '',
                max: ''
            }
        }
        if (boards?.map((x: { sensors: any[]; }) => x.sensors)
            .map((x: any) => validateRev7aSensorState(x))
            .every((x: boolean) => x)) {
            const index = boards?.findIndex((x: { seqNum: number; }) => x.seqNum == activeKey)
            if (index > -1 && !boards[index].sensors.includes((x: {
                pin: number;
                port: number;
            }) => x.pin === sensor.pin && x.port === sensor.port) && props.editMode) {
                if (sensor.port == 2) {
                    if (sensor.pin == 1 && boards[index + 1] == undefined)
                        addBoard()
                    else if (!(boards?.length > index + 1))
                        addCabledRHSensor()
                    return
                }
                setBoards((prev: any) => {
                    const boards = [...prev];
                    boards[index].sensors = [conf, ...boards[index].sensors]
                    return boards
                })
            }
        }
    }
    const configureEthernetPin = (sensor: any, sensor_index: number) => {
        setBoards((prev: any) => {
                const pos = prev?.findIndex((x: { seqNum: number; }) => x.seqNum == activeKey)
                if (pos > -1) {
                    let index;
                    if (sensor.port_type == PORT_TYPE.ONBOARD) {
                        index = sensor_index
                        const sensor_id = createSensorId(sensor, activeKey)
                        if (boards[pos].sensors.filter((x: any, index: number) => x.port == 2 && x.port_type == PORT_TYPE.ONBOARD && index != sensor_index)
                            .some((x: any) => x.sensor_id == sensor_id)) {
                            toast.error('configuration already exists!')
                            return prev
                        }
                        toast.success('changes applied!')
                    } else {
                        index = prev[pos]?.sensors?.findIndex((x: {
                            port: number;
                            pin: number;
                        }) => x.port === sensor.port && x.pin === sensor.pin && sensor.port_type == PORT_TYPE.ETHERNET)

                    }
                    if (index > -1) {
                        sensor.sensor_id = createSensorId(sensor, activeKey)
                        if (sensor.comp_type === 'thermostat')
                            sensor.seqNum = 1
                        const boards = [...prev]
                        boards[pos].sensors[index] = sensor
                        return boards
                    }
                }
                return prev
            }
        )
    }
    const onRemove = (sensor: any, sensor_index: number) => {
        setBoards((prev: any) => {
                const pos = prev?.findIndex((x: { seqNum: number; }) => x.seqNum == activeKey)
                if (pos > -1) {
                    let index
                    if (sensor_index != undefined && sensor.port_type == PORT_TYPE.ONBOARD)
                        index = sensor_index
                    else
                        index = prev[pos]?.sensors?.findIndex((x: any) => x.port === sensor.port && x.pin === sensor.pin && x.port_type === PORT_TYPE.ETHERNET)
                    if (index > -1) {
                        const boards = [...prev]
                        boards[pos].sensors.splice(index, 1)
                        toast.success('Sensor removed!')
                        return boards
                    }
                }
                return prev
            }
        )
    }

    const addOnboardSensor = (sensor: any) => {
        if (!props.editMode) return
        const pos = boards?.findIndex(x => x.seqNum == activeKey)

        if(pos > -1 ){
            const onboardSensors = boards[pos].sensors.filter((x:any) => x.pin === sensor.pin && x.port === sensor.port && x.port_type == PORT_TYPE.ONBOARD)
            if(onboardSensors.length == 2) {
                toast.error('both pins are already configured!')
                return;
            }
        }
        if (boards?.map((x: { sensors: any[]; }) => x.sensors)
            .map((x: any) => validateRev7aSensorState(x))
            .every((x: boolean) => x)){
            setBoards((prev: any[]) => {
                    if (pos > -1) {
                        const boards = [...prev]
                        const conf = {
                            ...RH_SENSOR_TEMPLATE,
                            pin: sensor.pin,
                            port: sensor.port
                        }
                        boards[pos].sensors = [conf, ...boards[pos].sensors]
                        return boards
                    }
                    return prev
                }
            )
        }
        setTimeout(() => {
            const item = document.getElementById(`pin-config-list-item-obs-${sensor.port}-${sensor.pin}`)
            item?.scrollIntoView()
        }, 500)

    }
    const editOnboardSensor = (sensor: any,index) => {
        if (!props.editMode)
            return
        setBoards((prev: any) => {
            const pos = prev?.findIndex((x: { seqNum: number; }) => x.seqNum == activeKey)
            if (pos > -1) {
                    sensor.sensor_id = createSensorId(sensor, activeKey)
                    const boards = [...prev]
                    boards[pos].sensors[index] = sensor
                    return boards

            }
            return prev
        })
    }
    const validateThermocouplesForm = () => {
        const pos = boards?.findIndex((x: { seqNum: number; }) => x.seqNum == activeKey)
        if (pos > -1) {
            let thermocouples = boards[pos].sensors.filter(x => x.port == 1 && x.pin >= 40 && x.pin < 44 && x.port_type == PORT_TYPE.ONBOARD)
            if (thermocouples.some((x: any) => Object.values(x).includes('select'))) {
                toast.error('incomplete sensor configuration exists!')
                return false
            }
        }
        return true
    }
    const addThermocouple = (sensor: any) => {
        if (!props.editMode || !validateThermocouplesForm()) return
        if(boards?.map((x: { sensors: any[]; }) => x.sensors)
            .map((x: any) => validateRev7aSensorState(x))
            .every((x: boolean) => x)){
            setBoards((prev: any) => {
                const pos = prev?.findIndex((x: { seqNum: number; }) => x.seqNum == activeKey)
                if (pos > -1) {
                    const boards = [...prev]
                    const conf = {
                        ...SENSOR_TEMPLATE,
                        port: sensor.port,
                        pin: sensor.pin,
                        port_type: PORT_TYPE.ONBOARD,
                        calibration: {
                            a: '',
                            b: '',
                            c: '',
                            d: ''
                        },
                        min: '',
                        max: ''
                    }
                    boards[pos].sensors = [conf, ...boards[pos].sensors]
                    return boards
                }
                return prev
            })
        }
    }
    const removeThermocouples = (sensor: any) => {
        setBoards((prev: any) => {
            const pos = prev?.findIndex((x: { seqNum: number; }) => x.seqNum == activeKey)
            if (pos > -1) {
                const boards = [...prev]
                const index = boards[pos].sensors.findIndex((x: any) => x.port_type == PORT_TYPE.ONBOARD && x.port == sensor.port && x.pin == sensor.pin)
                if (index > -1) {
                    boards[pos].sensors.splice(index, 1)
                    toast.success('Thermocouple removed!')
                    return boards
                }
            }
            return prev
        })
    }
    const renderSensors = (sensor: any, board_id: number, index: number) => {
        if (sensor.port_type == PORT_TYPE.ONBOARD && sensor.port == 2) {
            const connections = boards[board_id - 1]?.sensors.filter((x: any) => x.port == 2 && x.port_type == PORT_TYPE.ONBOARD && x.sensor_id != 0)
            return <CabledRHConfigForm
                editMode={props.editMode}
                onChange={configureEthernetPin}
                sensor={sensor}
                onRemove={onRemove}
                sensorType={PORTS_CONFIG[1]}
                typesComponentsMapping={TYPES_COMPONENTS_MAPPING}
                connectionsCount={connections.length}
                connections={connections}
                addCabledRH={addCabledRHSensor}
                activeKey={activeKey}
                index={index}
            />
        } else if (sensor.port_type == PORT_TYPE.ONBOARD && sensor.port == 1) {
            if (sensor.pin >= 40 && sensor.pin < 44) {
                return <ThermocoupleConfigForm
                    editMode={props.editMode}
                    onChange={editOnboardSensor}
                    sensor={sensor}
                    sensorType={'thermistor'}
                    onRemove={removeThermocouples}
                    typesComponentsMapping={TYPES_COMPONENTS_MAPPING}
                    index={index}
                />
            } else if (sensor.pin > 0 && sensor.pin < 4) {
                return <OnboardRHConfigForm
                    editMode={props.editMode}
                    onChange={editOnboardSensor}
                    sensor={sensor}
                    onRemove={onRemove}
                    sensorType={PORTS_CONFIG[1]}
                    typesComponentsMapping={TYPES_COMPONENTS_MAPPING}
                    index={index}
                />
            }
        } else return <SensorConfigForm
            editMode={props.editMode}
            onChange={configureEthernetPin}
            sensor={sensor}
            sensorType={PORTS_CONFIG[sensor.port - 1]}
            onRemove={onRemove}
            typesComponentsMapping={TYPES_COMPONENTS_MAPPING}
        />
    }
    return (<Tabs
        id="board-config-tabs"
        activeKey={activeKey}
        onSelect={(k) => setActiveKey(parseInt(k ?? '1'))}
        className="mb-3"
        variant="tabs">
        {
            boards?.map((board: any, index: number) => {
                return <Tab
                    title={<div className="d-flex">
                        {board.is_master ?
                            <span>{`Master board #${board.seqNum}`}</span> :
                            <span>{`Extension board #${board.seqNum}`}</span>
                        }
                        {activeKey == board.seqNum && !board.is_master &&
                            <Button size="small" type="text" danger onClick={() => {
                                deleteBoard(board.seqNum)
                            }}>
                                <CloseOutlined/>
                            </Button>}
                    </div>
                    }
                    eventKey={board.seqNum}>
                    {activeKey == board.seqNum &&
                        <div className="root-container col-12 flex-sm-column flex-lg-row">
                            <div className="col-md-6 col-sm-12 overflow-auto fit-content">
                                <Rev7aBoardDiagram
                                    editMode={props.editMode}
                                    configurePin={addEthernetPin}
                                    connectedPins={() => board.sensors.filter(x=>x.port_type == PORT_TYPE.ETHERNET)??[]}
                                    isDaisyChained={board.daisy_chained}
                                    configureOnboardSensors={addOnboardSensor}
                                    addThermocouple={addThermocouple}
                                >
                                </Rev7aBoardDiagram>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                {board.sensors.map((sensor: any, index: number) => renderSensors(sensor, board.seqNum, index))}
                            </div>
                        </div>
                    }
                </Tab>
            })
        }
    </Tabs>)
}
export const createSensorId = (sensor, boardSeqNum) => {
    // [board#1d][port-type#1d][port#2d][pin#2d][sensing#1d]
    const boardNum = boardSeqNum
    const portType = sensor.port_type
    const sensingElemId = sensor.sensing_element
    const countDigits = (num: number) => num.toString().length
    const portNum = countDigits(sensor.port) == 1 ? `0${sensor.port}` : sensor.port
    const pinNum = countDigits(sensor.pin) == 1 ? `0${sensor.pin}` : sensor.pin
    let sensorId
    switch (portType) {
        case PORT_TYPE.ETHERNET:
            sensorId = Number.parseInt(`${boardNum}${portType}${portNum}${pinNum}${sensingElemId}`)
            break;
        case PORT_TYPE.ONBOARD: {
            sensorId = Number.parseInt(`${boardNum}${portType}${portNum}${pinNum}${sensingElemId}`)
            break;
        }
        case PORT_TYPE.DERIVATIVE :
            sensorId = Number.parseInt(`${boardNum}${portType}${portNum}${pinNum}${sensingElemId}`)
            break;
    }
    return sensorId;
}
export  const validateRev7aSensorState = (sensor: any) => {
    if (Array.isArray(sensor) && sensor.some(x => Object.values(x).includes('select'))) {
        toast.error('incomplete sensor configuration exists!')
        return false
    } else if (sensor && Object.values(sensor).includes('select')) {
        toast.error('incomplete sensor configuration exists!')
        return false
    }
    return true
}