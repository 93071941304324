import Api from '../api';
import authStore from '../store/authStore';
import userStore from '../store/userStore';
import constants from '../config/constants';

class UserService {
    async getUsers(currentPage : number, limit : number ,search :number ,orgid? :any ,roleid? :any){
        
        let response;
        let searchText = encodeURIComponent(search)
        let queryParams=`/get-users?skip=${(currentPage - 1) * limit}&limit=${limit}&filter=${JSON.stringify({search : searchText})}`;
            if(orgid && roleid){
                queryParams +=`&orgid=${orgid}&roleid=${(roleid)}`
            }else if(roleid){
                queryParams +=`&roleid=${(roleid)}`
            }else if(orgid){
                queryParams +=`&orgid=${(orgid)}`
            }
            
        response = await Api.get(queryParams)
        return response.data
    }

    getParentId(){        
        
        if(authStore.currentUser.role === constants.role["Contractor"]){
            return authStore.currentUser.uid    //Contractor can see users under him, Parent id will be his id
        }else if(authStore.currentUser.role > constants.role["Contractor"]){
            return userStore.profileDetials ? userStore.profileDetials.assigned_to?._id : '' //Field Technician can see users under contractor in which he is assigned to, Parent id 
        }else{
            return ' '
        }

    }
  
    async getProfileDetails(){

        let response = await Api.get(`/users/${authStore.currentUser.uid}`)
        return response.data.result
    }

    async getAssigneeList(roles : Array<any>, orgid? : any){
        // note : temp solution for the org admin access to all org
        let url = `/assigneelist?${roles.map((n, index) => `roles[${index}]=${n}`).join('&')}`
        if(orgid){
            url += `&orgid=${orgid}`
        }
        let response = await Api.get(url) // to pass array as query params
        return response.data.result
           
    }

    async updateUser(userDetails : any , orgid? : any){
        // note : temp solution for the org admin access to all org
  
        let url = `/users/${userDetails._id}`
        let response = await Api.put(url,userDetails)
        return response.data.message
        
    }

    async createUser(userDetails : any, orgid? : any){
        // note : temp solution for the org admin access to all org
        let url = `/users/`
        let response = await Api.post(url,userDetails)
        return response.data.message
       
    }

    async deleteUser(id : string, new_contractor?: any){
        let url = `/users/${id}`
        let response = await Api.delete(url, { data: { new_contractor }})
        return response.data.message
    }

    async sendResetEmail(email : string){
        let response = await Api.post('/forgotpassword',{email})
        return response.data.message
    }

    async getCustomerList(orgid? :string){

        let queryParams = `/users/no-admin-users`
        if(orgid){
            queryParams += `?orgid=${orgid}`
        }

        let response = await Api.get(queryParams)
        return response.data.consumers

    }

    async getCustomers(orgid? : string){
        let url = `/get-users`
        if(orgid){
            url += `?orgid=${orgid}`
        }
        let response = await Api.get(url)
        return response.data.result
    }

    async getContractorList(orgid? : string){

        let roleType  = constants.role.Contractor;
        let queryParams = `/get-users?role_type=${roleType}`
        if(orgid){
            queryParams += `&orgid=${orgid}`
        }
        let response = await Api.get(queryParams)
        return response.data.result

    }

    async getUserBasedOnRoleType(orgid? : any, roleType? : number, technicianList?: boolean){

        let queryParams = `/get-users?filter=${JSON.stringify({search : ''})}`

        if(roleType){
            queryParams += `&role_type=${roleType}`
        }

        if(orgid){
            queryParams += `&orgid=${orgid}`
        }

        if(technicianList){
            queryParams += `&technicianList=${true}`
        }
         
        let response = await Api.get(queryParams)

        return technicianList ? response.data : response.data?.result
    }

    
    async updateSensorBox(sensorDetails : any, isSend: boolean, orgid? : string){
        // note : temp solution for the org admin access to all org
        let url = `/sensorboxes/${sensorDetails._id}?send=${isSend}`
        if(orgid){
            url += `&orgid=${orgid}`
        }
        let response = await Api.put(url, sensorDetails)
        return response.data.message
       
    }
    async updateRev7aSensorBox(sensorDetails : any, isSend: boolean, orgid? : string){
        // note : temp solution for the org admin access to all org
        let url = `/sensorboxes/rev7a/${sensorDetails._id}?send=${isSend}`
        if(orgid){
            url += `&orgid=${orgid}`
        }
        let response = await Api.put(url, sensorDetails)
        return response.data.message

    }

    async createSensorBox(sensorDetails : any, isSend: boolean ,orgid? : string){
        // note : temp solution for the org admin access to all org
        let url = `/sensorboxes?send=${isSend}`
        if(orgid){
            url += `&orgid=${orgid}`
        }
        let response = await Api.post(url, sensorDetails)
        return response.data.message
    }
    async createRev7aSensorBox(sensorDetails : any, isSend: boolean ,orgid? : string){
        // note : temp solution for the org admin access to all org
        let url = `/sensorboxes/rev7a?send=${isSend}`
        if(orgid){
            url += `&orgid=${orgid}`
        }
        let response = await Api.post(url, sensorDetails)
        return response.data.message
    }

    async getSensorTypeList() {
        let response = await Api.get(`/sensortypes`)
        return response.data.result
    }

    async getSensorTemplates() {
        let response = await Api.get(`/sensortemplates`)
        return response.data.result
    }

    async getSensorBoxList(id : string) {
        let response = await Api.get(`/sensorboxes/${id}`)
        return response.data.result
    }

    async getUsersBySensors(id : string) {
        let response = await Api.get(`/users/sensors/${id}`)
        return response.data.result
    }
   
    async getSensorBoxes(currentPage : number, limit : number,search :any, deviceSort: string, statusSort: string,orgid : string, alerts_on? : boolean, deployment_status? : any){
        // let response = await Api.get(`/sensorboxes`)
        let searchText = encodeURIComponent(search)
        let queryParams = `/sensorboxes-list-new?page=${currentPage}&limit=${limit}&deviceSort=${deviceSort}&statusSort=${statusSort}&filter=${JSON.stringify({search : searchText})}`
        if(orgid){
            queryParams += `&orgid=${orgid}`
        }
        if(alerts_on){
            queryParams += `&alerts_on=${alerts_on}`
        }

        if(deployment_status){
            queryParams += `&deployment_status=${deployment_status}`
        }

        let response = await Api.get(queryParams)
        return response.data
    }


    async getSystemOverview(orgid ? :any, deployment_status? : any){
        let params = `/system-overview?`
        if(orgid){
            params += `orgid=${orgid}`
        }

        if(deployment_status){
            params += `&deployment_status=${deployment_status}`
        }
        
        let response = await Api.get(params)
        return response.data
    }

    async getSensorBoxesForMap(orgid ? :any){
        let params = `/sensorboxes/parent/${this.getParentId()}?`
        if(orgid){
            params += `orgid=${orgid}`
        }
        let response = await Api.get(params)
        return response.data
    }

    async getSensorBoxesForMaps(orgid ? :any, deployment_status? : any){
        let params = `/sensorboxes-list-map`
        if(orgid){
            params += `?orgid=${orgid}`
        }

        if(deployment_status){
            params += `&deployment_status=${deployment_status}`
        }

        let response = await Api.get(params)
        return response.data
    }

    async getCoordinatesList(place: string){
        
        let geoCodingAPI    = "https://maps.googleapis.com/maps/api/geocode/json?address="+place+"&key="+process.env.REACT_APP_GOOGLE_MAP_API_KEY;
        let result          = await fetch(geoCodingAPI) as any;
        let locationArray = [];
        let res = await result.json();
        if(res.status === "OK"){
            let latitude = res.results[0].geometry.location.lat;
            let longitude = res.results[0].geometry.location.lng;
            locationArray[0] = latitude;
            locationArray[1] = longitude;
        }
        return locationArray;
    }

    async getTimeZone(location : Array<any>){
        let timeZoneAPI    = `https://maps.googleapis.com/maps/api/timezone/json?location=${location[0]},${location[1]}&timestamp=1331161200&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`
        let result         = await fetch(timeZoneAPI) as any;
        let res = await result.json();
        if(res.status === "OK"){
            return res.timeZoneId
        }
        
    }
    async deleteSensorBox(id:string){
        let response = await Api.delete(`/sensorboxes/${id}`)
        return response.data.message
    }

    async hardDeleteSensorBox(id:string){
        let response = await Api.delete(`/hard-delete-sensorboxes/${id}`)
        return response.data.message
    }

    async getRefrigerentList(){
        let response = await Api.get(`/refrigerants`)
        return response.data.result
    }

    async getNewSensorBoxName(){
        let response = await Api.get(`/sensorboxname`)
        return response.data.result
    }

    async getCurrentPassword(currentpassword : string){
        let response = await Api.get(`/checkpassword/${currentpassword}`)
        return response.data.status
    }

    async setChangePassword(password:string,confirmpassword:string) {
        let passwordObj ={
            password    :password,
            confirmPassword :confirmpassword
        }
        let response = await Api.post(`/changePassword`,passwordObj)
        return response.data.message
    }

    async getCustomerAddressDetails(consumerId:string) {
        let response = await Api.get(`/getAddress/${consumerId}`)
        return response.data.result
    }

    async checkEmailExist(id:string,emailId :string ,orgid? : any) {
        let url = `/checkEmail/${id}/${emailId}`
        let response = await Api.get(url)
        return response.data.status
    }

    async resendEmail(id:string) {
        let response = await Api.get(`/resend-email/${id}`)
        return response.data.message
    }


    resetSensorBox = async (id: String) => {
        let response = await Api.post(`/sensorboxes/${id}`)
        return response.data.message
    }


    async getWeatherInformation(coordinates? : any) {
        try{
            let weatherLink = `${process.env.REACT_APP_WEATHER_GRIDPOINT_LINK}/${coordinates[0]},${coordinates[1]}`
            let weather_data : any = {}
            await fetch(weatherLink)
            .then((response : any) => response.json())
            .then(async(data : any)=> {
                weather_data.forecast_link = data.properties.forecast
                weather_data.grid_id = data.properties.gridId
                weather_data.grid_x  = data.properties.gridX
                weather_data.grid_y  = data.properties.gridY
                let stationInfoLink  = data.properties.observationStations;
                await fetch(stationInfoLink)
                .then((response : any) => response.json())
                .then(async(data : any)=> {
                    weather_data.station_id = data?.features[0]?.properties?.stationIdentifier
                });
            });
            return weather_data
        }catch{
            return null
        }
    }



} 

const userService = new UserService()
export default userService