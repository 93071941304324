import React from 'react';
import { Input } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router';
import Constants from '../../config/constants'
import Pagination from "react-js-pagination";
import toast from '../../components/toast'
import LoadingWheel from '../../components/loadingwheel'
import sensorService from '../../services/sensorService';
import soapSensorStore, { SoapSensorData } from '../../store/soapSensorStore';
import RawDataModal from '../../components/rawdatamodal';
import authStore from '../../store/authStore';
import Select from '../../components/select';
import {Organization} from '../../interface'
import organizationService from '../../services/organizationService';
import "./SensorData.css"
import Utils from '../../helpers/Utility';

const { Search } = Input;
export interface SoapSensorProps extends RouteComponentProps {}
export interface SoapSensorState {
    soapSensorData      :   Array<SoapSensorData>
    isLoading       :   Boolean,
    sensorFilter    :   string;
    organizationList:   Array<Organization>;
    organizationFilter? : any;
    pageNumber      :   number;
    showModal       :   boolean;
    modalData       :   string;
}

class soapSensorDataList extends React.Component<SoapSensorProps, SoapSensorState> {

    searchSensorRaw: any
    constructor(props : any){
        super(props);

        this.state = {
            soapSensorData  : [],
            sensorFilter    : 'SYS00000648',
            organizationList: [],
            organizationFilter : {},
            isLoading       : true,
            pageNumber      : 1,
            showModal       : false,
            modalData       : '',
        }

    this.searchSensorRaw = Utils.debounce(this.getSensorData, 700);
    }

    async componentDidMount(){
        // note : temp solution for the org admin access to all org
        if(authStore?.currentUser?.active_role?.role_type === 0 || (authStore?.currentUser?.active_role?.role_type === 1 && authStore.currentUser.is_default)){
            await this.getOrganisations();
        }
        soapSensorStore.sensorData = [];
        await this.getSensorData(this.state.sensorFilter);
    }

    showModal = (data: string) =>{
        this.setState({modalData: data},()=>{
            Utils.showModal('rawData')
        })
    }

    getSensorData = async(sensorBoxName: string) => {
        try { 
            this.setState({isLoading: true, sensorFilter: sensorBoxName })
            let SoapSensorData = await sensorService.getSensorRawData(sensorBoxName, this.state.organizationFilter._id)

            const parsedInput = JSON.parse(SoapSensorData[0].value);
            parsedInput.data = JSON.parse(parsedInput.data);
            console.log(parsedInput.data)
            const validJsonString = JSON.stringify(parsedInput, null, 2);
            console.log(validJsonString);

            soapSensorStore.sensorData = parsedInput.data.data; // latestRawData.value;
            this.setState({
                soapSensorData: soapSensorStore.sensorData.slice( 0, Constants.maxLimit),
                isLoading : false,
                pageNumber: 1
            })
        }catch(error){
            this.setState({isLoading : false})
            toast.error(error.response.data.message)
        }
    }

    getOrganisations :any = async () => {
        try {
                const response = await organizationService.getOrganizations()
                this.setState({organizationList : response.data.organizations , organizationFilter : response.data.organizations[0]})
        } catch (error) {
                toast.error(error.response.data.message)
                this.setState({isLoading : false})
        }
    }

    handleFilterOrganization(option: any){
        this.setState({organizationFilter : option},()=>{
            this.getSensorData(this.state.sensorFilter);
        })
    }

    onPageChange = (pageNumber: number) => {
        const skip  = Constants.maxLimit * (pageNumber - 1);
        const limit = Constants.maxLimit * (pageNumber - 1) + Constants.maxLimit;
        this.setState({
            soapSensorData: soapSensorStore.sensorData.slice( skip, limit ),
            isLoading : false,
            pageNumber
        })
    }

    setSearchFilter(value: string) {

        this.setState({ sensorFilter: value }, () => {
            this.searchSensorRaw( value )
        })

    }

    cleanEscapedCharacters(jsonString: string): string {
        return jsonString
          .replace(/\\b/g, '')
          .replace(/\\f/g, '')
          .replace(/\\n/g, '')
          .replace(/\\r/g, '')
          .replace(/\\t/g, '')
          .replace(/\\"/g, '"')
          .replace(/\\\\/g, '\\');
    }

    render(){
        return (

            <div className="padding-b50">
              <div className="row mb-25">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-8">
                        <p className="table-title media-padding">Raw Data</p>
                  </div>
                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-2 media-margin-tp">
                    {// note : temp solution for the org admin access to all org
                    (authStore?.currentUser?.active_role?.role_type === 0 || (authStore?.currentUser?.active_role?.role_type === 1 && authStore.currentUser.is_default)) &&
                            <div className="filter-select">
                                <label className="custom-label">Organization</label>
                                    <Select name = "typeFilter"
                                            data = {this.state.organizationList}
                                            value = {this.state.organizationFilter}
                                            onChange = {(e:any)=>this.handleFilterOrganization(e)}
                                    />
                            </div>
                            }
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-3 col-xl-2 media-margin-tp  mt-auto">
                        <Search
                            placeholder="Search"
                            value={this.state.sensorFilter}
                            size="large"
                            onChange={(e) => this.setSearchFilter(e.target.value)}
                            allowClear
                            enterButton />
                    </div>
               </div>
               {this.state.soapSensorData?.length !== 0 ?
                <div className="row no-gutters table-wrap">
                    <table className="table custom-table table-style">
                        <thead className="table-header-bg">
                            <tr>
                            <th scope="col">#</th>
                            <th scope="col">advId</th>
                            <th scope="col">advCode</th>
                            <th scope="col">timestamp</th>
                            <th scope="col">state</th>
                            <th scope="col">source</th>
                            <th scope="col">text</th>
                            <th scope="col">alarm</th>
                            <th scope="col">notice</th>
                            <th scope="col">fail</th>
                            <th scope="col">acked</th>
                            <th scope="col">reset</th>
                            <th scope="col">rtn</th>
                            <th scope="col">priority</th>
                            <th scope="col">rtnTimestamp</th>
                            {/* <th scope="col">ackUser</th>
                            <th scope="col">ackTimestamp</th>
                            <th scope="col">reportValue</th>
                            <th scope="col">limit</th>
                            <th scope="col">engUnits</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.soapSensorData ?
                                this.state.soapSensorData.map((item : SoapSensorData,index) =>{
                                    return(
                                        <tr className='click-icon' key={index} onClick={()=>this.showModal(JSON.stringify(item))}>
                                        <th scope="row">{(Constants.maxLimit * (this.state.pageNumber - 1)) + index + 1}</th>
                                        <td>{item.advId}</td>
                                        <td>{item.advCode}</td>
                                        <td>{item.timestamp}</td>
                                        <td>{item.state}</td>
                                        <td>{item.source}</td>
                                        <td>{item.text}</td>
                                        <td>{item.alarm.toString()}</td>
                                        <td>{item.notice.toString()}</td>
                                        <td>{item.fail.toString()}</td>
                                        <td>{item.acked.toString()}</td>
                                        <td>{item.reset.toString()}</td>
                                        <td>{item.rtn.toString()}</td>
                                        <td>{item.priority}</td>
                                        <td>{item.rtnTimestamp}</td>
                                        {/* <td>{item.ackUser}</td>
                                        <td>{item.ackTimestamp}</td>
                                        <td>{item.reportValue}</td>
                                        <td>{item.limit}</td>
                                        <td>{item.engUnits}</td> */}
                                        </tr>
                                    )

                                })
                                : null 
                            }
                        </tbody>
                        </table>
                </div>  
                : <>
                    {
                    soapSensorStore.sensorData?.length === 0 && !this.state.isLoading && (
                        <div className="text-center w-100 no-card-ph">
                            <div className="no-result-container">
                                <p className="no-cards"> No data found!</p>
                            </div>
                        </div> 
                       )
                    }
                    </>
                }        
               <div className="pagination-container">
               {Math.ceil(soapSensorStore.sensorData.length/Constants.maxLimit ) > 1?
   
                        <Pagination
                            hideNavigation
                            activePage={this.state.pageNumber}
                            itemsCountPerPage={Constants.maxLimit}
                            totalItemsCount={soapSensorStore.sensorData.length}
                            itemClass={"item-class"}
                            activeClass={"active-class"}
                            pageRangeDisplayed={5}
                            onChange={this.onPageChange}
                            />
                    
                    : null
                }
                </div> 
             {this.state.isLoading ? 
                <LoadingWheel/>
                :  null}
            <RawDataModal rawData={this.state.modalData}/>
          </div> 
        )
    }
}

export default withRouter(soapSensorDataList)