import React, {Fragment, useEffect, useRef, useState} from "react";
import Select from "../select";
import './ComponentListItem.css'
import {Button, Checkbox, Divider, Popconfirm, Popover} from "antd";
import {PORT_TYPE, SENSING_ELEMENT} from "../../config/constants";
import {PopConfirm} from "./PopConfirm";
import {PlusSquareOutlined, SaveOutlined} from "@ant-design/icons";
import {createSensorId, RH_SENSOR_TEMPLATE} from "./Rev7aSensorsTab";
import _ from 'lodash'
import toast from "../toast";


const SENSORS = {
    analog_24_vdc: ['switch'],
    thermistor: ['temperature'],
    analog_0_5_vdc: ["current", "pressure", "voltage", "vibrations"],
    voc: ['voc'],
    rh: ["temperature", "humidity"],
    thermocouple: ['temperature']
}
const validate = (sensor: any) => {
    return !(sensor && Object.values(sensor).includes('select'));
}
const highlightInvalidFieldValue = {
    control: (provided: any, state: any) => ({
        ...provided,
        ...(state.hasValue && state.getValue()[0]?.value != 'select' && state.getValue()[0]?.value?.length > 0) ? {} : {...(state.isDisabled ? {} : {border: `1px solid red`})}
    })
}
export const SensorConfigForm = (props: any) => {
    const [deletePopup, setDeletePopup] = useState(() => false)
    const togglePopup = () => {
        props.editMode && setDeletePopup(prev => !prev)
    }
    useEffect(() => {

    }, [props.board]);
    const updateSensor = (field: any) => {
        props.onChange({...props.sensor, ...field})
    }
    const getSubtypes = () => {
        const subs = props.typesComponentsMapping[props.sensor.comp_type]?.find(x => x.type == props.sensor.type)?.subs?.filter(x => x.length > 0)
        return subs?.length > 0 ? subs : ['none']
    }
    const getTypes = (comp_type: React.ReactText) => {
        // @ts-ignore
        const supportedTypes = SENSORS[props.sensorType];
        const types = props.typesComponentsMapping[comp_type]?.filter(x => supportedTypes.includes(x.type)).map(x => x.type)
        return types?.length > 0 ? types : ['select']
    }
    const getComponents = () => {
        // @ts-ignore
        const supportedTypes = SENSORS[props.sensorType];
        return Object.keys(props.typesComponentsMapping).map(key => {
            const types = props.typesComponentsMapping[key]?.filter(comp => supportedTypes.includes(comp.type))
            return types?.length > 0 ? key : ''
        }).filter(x => x.length > 0).sort() ?? ['select']
    }

    const renderForm = () => <div className="pl-4 pr-4">
        <div className="row mb-4">
            <div className="col-md-6 col-sm-6 col-12 mt-2">
                <label>Component </label>
                <Select name="ComponentSelect"
                        value={{_id: props.sensor.comp_type, name: props.sensor.comp_type}}
                        onChange={(value: any) => {
                            const config = {comp_type: value._id, type: 'select', sub: 'select'}
                            let types = getTypes(value._id)
                            if (types.length == 1) {
                                config['type'] = types[0]
                            }
                            updateSensor(config)
                        }}
                        data={getComponents().map(c => ({_id: c, name: c}))}
                        isDisabled={!props.editMode}
                        customStyles={highlightInvalidFieldValue}
                >
                </Select>
            </div>
            <div className="col-md-6 col-sm-6 col-12 mt-2">
                <label>Sequence Number </label>
                <Select name="SeqNumSelect"
                        value={{_id: props.sensor.seqNum, name: props.sensor.seqNum}}
                        onChange={(value: any) => {
                            const config = {seqNum: value._id}
                            updateSensor(config)
                        }}
                        data={[1, 2, 3, 4, 5].map((i) => ({_id: i, name: i}))}
                        isDisabled={!props.editMode}
                >
                </Select>
            </div>
            <div className="col-md-6 col-sm-6 col-12 mt-2">
                <label>type </label>
                <Select name="typeSelect"
                        value={{_id: props.sensor.type, name: props.sensor.type}}
                        onChange={(value: any) => {
                            const config = {type: value._id}
                            if (Object.getOwnPropertyNames(props.sensor).includes('sub')) { // @ts-ignore
                                config['sub'] = 'select'
                            }
                            updateSensor(config)
                        }}
                        data={getTypes(props.sensor.comp_type).map(c => ({_id: c, name: c}))}
                        isDisabled={!props.editMode}
                        customStyles={highlightInvalidFieldValue}
                >
                </Select>
            </div>

            {getSubtypes().length > 0 &&
                <div className="col-md-6 col-sm-6 col-12 mt-2">
                    <label>Sub </label>
                    <Select name="subtypeSelect"
                            value={{_id: props.sensor.sub, name: props.sensor.sub}}
                            onChange={(value: any) => {
                                updateSensor({sub: value._id})
                            }}
                            data={getSubtypes()?.map(c => ({
                                _id: c,
                                name: c
                            }))}
                            isDisabled={!props.editMode}
                            customStyles={highlightInvalidFieldValue}
                    >
                    </Select>
                </div>}
            {Object.getOwnPropertyNames(props.sensor).includes('min') &&
                <div className="col-md-6 col-sm-6 col-12 mt-2">
                    <label className="fs-12">Valid Range</label>
                    <div className='row pr-3'>
                        <div className='col-6 d-flex pr-0  mb-2 align-items-center'>
                            <input type="text" className="form-control min-max" aria-describedby="min"
                                   name="min"
                                   value={props.sensor.min ?? ''}
                                   onChange={(e) => {
                                       updateSensor({min: e.target.value})
                                   }}
                                   disabled={!props.editMode}
                                   maxLength={8}
                                   placeholder="enter min value"/>
                        </div>
                        <div className='col-6 d-flex pr-0 mb-2 align-items-center'>
                            <input type="text" className="form-control min-max" aria-describedby="max"
                                   name="max"
                                   value={props.sensor.max ?? ''}
                                   onChange={(e) => {
                                       updateSensor({max: e.target.value})
                                   }}
                                   disabled={!props.editMode}
                                   maxLength={8}
                                   placeholder="enter max value"/>
                        </div>
                    </div>
                </div>}
            {Object.getOwnPropertyNames(props.sensor).includes('calibration') &&
                <div className="col-md-6 col-sm-6 col-12 mt-2">
                    <label className="fs-12">Calibration</label>
                    <div className='row m-0'>
                        <div className='col-6 d-flex pr-0  mb-2 align-items-center'>
                            <label className="fs-12 pr-3 mb-0">a</label>
                            <input type="number" className="form-control calibration" aria-describedby="calibration"
                                   name="calibration_a"
                                   value={props.sensor.calibration?.a ?? ''}
                                   onChange={(e) => {
                                       updateSensor({calibration: {...props.sensor.calibration, a: e.target.value}})
                                   }}
                                   disabled={!props.editMode}
                                   placeholder="calibration factor"/>
                        </div>
                        <div className='col-6 d-flex pr-0 mb-2 align-items-center'>
                            <label className="fs-12 pr-3 mb-0">b</label>
                            <input type="number" className="form-control calibration" aria-describedby="calibration"
                                   name="calibration_b"
                                   value={props.sensor.calibration?.b ?? ''}
                                   onChange={(e) => {
                                       updateSensor({calibration: {...props.sensor.calibration, b: e.target.value}})

                                   }}
                                   disabled={!props.editMode}
                                   placeholder="calibration factor"/>
                        </div>
                        <div className='col-6 d-flex pr-0  mb-2 align-items-center'>
                            <label className="fs-12 pr-3 mb-0">c</label>
                            <input type="number" className="form-control calibration" aria-describedby="calibration"
                                   name="calibration_c"
                                   value={props.sensor.calibration?.c ?? ''}
                                   onChange={(e) => {
                                       updateSensor({calibration: {...props.sensor.calibration, c: e.target.value}})
                                   }}
                                   disabled={!props.editMode}
                                   placeholder="calibration factor"/>
                        </div>
                        <div className='col-6 d-flex pr-0  mb-2 align-items-center'>
                            <label className="fs-12 pr-3 mb-0">d</label>
                            <input type="number" className="form-control calibration" aria-describedby="calibration"
                                   name="calibration_d"
                                   value={props.sensor.calibration?.d ?? ''}
                                   onChange={(e) => {
                                       updateSensor({calibration: {...props.sensor.calibration, d: e.target.value}})
                                   }}
                                   disabled={!props.editMode}
                                   placeholder="calibration factor"/>
                        </div>
                    </div>
                </div>
            }
            <div className="col-md-6 col-sm-6 col-12 mt-2">
                <label>Sensor Alias</label>
                <input type="text" className="form-control calibration" aria-describedby="alias"
                       name="alias"
                       value={props.sensor.alias}
                       onChange={(e) => {
                           updateSensor({alias: e.target.value})
                       }}
                       disabled={!props.editMode}
                       placeholder="enter alias"/>
            </div>
        </div>
    </div>

    return (
        <div id={`pin-config-list-item-${props.sensor.port}-${props.sensor.pin}`}
             className={`pin-config-root border listItem mb-2 ${validate(props.sensor) ? '' : 'mb-3 listItem-invalid'}`}>
            <div
                className={`col-12 ins_tab_head_bg d-flex ${validate(props.sensor) ? '' : 'text-color-white bg-dark'}`}>
                <label className="container-checkbox font-w-600 fs-18" style={{marginBottom: 4, marginTop: 3}}>
                    {props.sensor.comp_type !== 'select' ?
                        `${props.sensor.alias.length > 0 ? props.sensor.alias :
                            props.sensor.comp_type} :#${props.sensor.seqNum ?? 1}` : 'Select Component'}
                    <input type="checkbox"
                           className="chk-box"
                           disabled={!props.editMode}
                           name={props.sensor.comp_type}
                           checked={true}
                    />
                    <span className="checkmark mt-1 text-color-white">{props.sensor.pin}</span>
                </label>
                <div onClick={() => {
                    togglePopup()
                }}
                     className="checkmark port  mr-3 text-color-black" style={{marginTop: 5}}>
                    <i className="fa fa-trash-o fa-lg"/>
                    <PopConfirm show={deletePopup} hide={togglePopup} action={() => props.onRemove(props.sensor)}/>
                </div>
                <span className="checkmark port  mr-5 text-color-black"
                      style={{marginTop: 5}}>{props.sensor.port}</span>
                <label className="port-label font-w-600 fs-14"
                       style={{
                           position: 'absolute',
                           right: '75px', paddingRight: 5, paddingTop: 5
                       }}>Port
                </label>
            </div>
            {renderForm()}
        </div>)
}
export const CabledRHConfigForm = (props: any) => {
    const [deletePopup, setDeletePopup] = useState(() => false)
    const [sensor, setSensor] = useState(() => _.cloneDeep(RH_SENSOR_TEMPLATE))
    const [dirtyFlag, setDirtyFlag] = useState(false)
    useEffect(() => {
        console.log(props)
        setSensor(_.cloneDeep(props.sensor))
    }, [props.sensor])
    const togglePopup = () => {
        props.editMode && setDeletePopup(prev => !prev)
    }
    const updateSensor = (field: any) => {
        setSensor(prev => ({...prev, ...field}))
        setDirtyFlag(true)
    }
    const saveChanges = () => {
        props.onChange({...sensor}, props.index)
        setDirtyFlag(false)
    }
    const getSubtypes = (comp_type: React.ReactText, type: any) => {
        const subs = props.typesComponentsMapping[comp_type]?.find(x => x.type == type)?.subs?.filter(x => x.length > 0)
        return subs?.length > 0 ? subs : ['none']
    }
    const getTypes = (comp_type: React.ReactText) => {
        // @ts-ignore
        const supportedTypes = SENSORS[props.sensorType] ?? [];
        const types = props.typesComponentsMapping[comp_type]?.filter(x => supportedTypes.includes(x.type)).map(x => x.type)
        return types?.length > 0 ? types : ['select']
    }
    const getComponents = () => {
        // @ts-ignore
        const supportedTypes = SENSORS[props.sensorType];
        return Object.keys(props.typesComponentsMapping).map(key => {
            const types = props.typesComponentsMapping[key]?.filter((comp: {
                type: any;
            }) => supportedTypes.includes(comp.type))
            return types?.length > 0 ? key : ''
        }).filter(x => x.length > 0).sort() ?? ['select']
    }
    const addRHSensor = (sensor) => {
        props.addCabledRH(sensor)
    }
    const renderForm = (rh: any) =>
        <div className="pl-4 pr-4">
            <div className="row mb-4">
                <Divider type='horizontal'>pin {rh.pin} | {rh.type} {rh.alias ? `: ${rh.alias}` : ''}</Divider>
                <div className="col-md-6 col-sm-6 col-12 mt-2">
                    <label>Pin</label>
                    <Select name="pinSequenceSelect"
                            value={{_id: rh.pin, name: rh.pin}}
                            onChange={(value: any) => {
                                const config = {pin: value._id}
                                updateSensor(config)
                            }}
                            customStyles={{
                                control: (provided: any, state: any) => ({
                                    ...provided,
                                    ...(state.hasValue && state.getValue()[0]?.value != 0) ? {} : {...(state.isDisabled ? {} : {border: `1px solid red`})}
                                })
                            }}
                            data={[1, 2, 3, 4].map((i) => ({_id: i, name: i}))}
                            isDisabled={!props.editMode}
                    />
                </div>
                <div className="col-md-6 col-sm-6 col-12 mt-2">
                    <label>Component </label>
                    <Select name="ComponentSelect"
                            value={{_id: rh.comp_type, name: rh.comp_type}}
                            onChange={(value: any) => {
                                const config = {comp_type: value._id, type: 'select', sub: 'select'}
                                let types = getTypes(value._id)
                                if (types.length == 1) {
                                    config['type'] = types[0]
                                }
                                updateSensor(config)
                            }}
                            data={getComponents().map(c => ({_id: c, name: c}))}
                            isDisabled={!props.editMode || rh.pin == 0}
                            customStyles={highlightInvalidFieldValue}
                    />
                </div>
                <div className="col-md-6 col-sm-6 col-12 mt-2">
                    <label>Sequence Number </label>
                    <Select name="SeqNumSelect"
                            value={{_id: rh.seqNum, name: rh.seqNum}}
                            onChange={(value: any) => {
                                const config = {seqNum: value._id}
                                updateSensor(config)
                            }}
                            data={[1, 2, 3, 4, 5].map((i) => ({_id: i, name: i}))}
                            isDisabled={!props.editMode || rh.pin == 0}
                    />
                </div>
                <div className="col-md-6 col-sm-6 col-12 mt-2">
                    <label>Type</label>
                    <Select name="typeSelect"
                            value={{_id: rh.type, name: rh.type}}
                            onChange={(value: any) => {
                                const config = {type: value._id, sensing_element: SENSING_ELEMENT[value._id]}
                                if (Object.getOwnPropertyNames(props.sensor).includes('sub')) { // @ts-ignore
                                    config['sub'] = 'select'
                                }
                                updateSensor(config)
                            }}
                            data={getTypes(rh.comp_type).map(c => ({_id: c, name: c}))}
                            customStyles={highlightInvalidFieldValue}
                            isDisabled={!props.editMode || rh.pin == 0}
                    />
                </div>
                <div className="col-md-6 col-sm-6 col-12 mt-2">
                    <label>Sub </label>
                    <Select name="subtypeSelect"
                            value={{_id: rh.sub, name: rh.sub}}
                            onChange={(value: any) => {
                                updateSensor({sub: value._id})
                            }}
                            data={getSubtypes(rh.comp_type, rh.type)?.map(c => ({
                                _id: c,
                                name: c
                            }))}
                            customStyles={highlightInvalidFieldValue}
                            isDisabled={!props.editMode || rh.pin == 0}
                    />
                </div>
                <div className="col-md-6 col-sm-6 col-12 mt-2">
                    <label className="fs-12">Valid Range</label>
                    <div className='row pr-3'>
                        <div className='col-6 d-flex pr-0  mb-2 align-items-center'>
                            <input type="text" className="form-control min-max" aria-describedby="min"
                                   name="min"
                                   value={rh.min ?? ''}
                                   onChange={(e) => {
                                       updateSensor({min: e.target.value})
                                   }}
                                   maxLength={8}
                                   placeholder="enter min value"
                                   disabled={!props.editMode || rh.pin == 0}
                            />
                        </div>
                        <div className='col-6 d-flex pr-0 mb-2 align-items-center'>
                            <input type="text" className="form-control min-max" aria-describedby="max"
                                   name="max"
                                   value={rh.max ?? ''}
                                   onChange={(e) => {
                                       updateSensor({max: e.target.value})
                                   }}
                                   maxLength={8}
                                   placeholder="enter max value"
                                   disabled={!props.editMode || rh.pin == 0}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 col-12 mt-2">
                    <label className="fs-12">Calibration</label>
                    <div className='row m-0'>
                        <div className='col-6 d-flex pr-0  mb-2 align-items-center'>
                            <label className="fs-12 pr-3 mb-0">a</label>
                            <input type="text" className="form-control calibration" aria-describedby="emailHelp"
                                   name="calibration_a"
                                   value={rh.calibration.a}
                                   onChange={(e) => {
                                       updateSensor({calibration: {...props.sensor.calibration, a: e.target.value}})
                                   }}
                                   placeholder="calibration factor"
                                   disabled={!props.editMode || rh.pin == 0}
                            />
                        </div>
                        <div className='col-6 d-flex pr-0 mb-2 align-items-center'>
                            <label className="fs-12 pr-3 mb-0">b</label>
                            <input type="text" className="form-control calibration" aria-describedby="emailHelp"
                                   name="calibration_b"
                                   value={rh.calibration.b}
                                   onChange={(e) => {
                                       updateSensor({calibration: {...props.sensor.calibration, b: e.target.value}})
                                   }}
                                   placeholder="calibration factor"
                                   disabled={!props.editMode || rh.pin == 0}
                            />
                        </div>
                        <div className='col-6 d-flex pr-0  mb-2 align-items-center'>
                            <label className="fs-12 pr-3 mb-0">c</label>
                            <input type="text" className="form-control calibration" aria-describedby="emailHelp"
                                   name="calibration_c"
                                   value={rh.calibration.c}
                                   onChange={(e) => {
                                       updateSensor({calibration: {...props.sensor.calibration, c: e.target.value}})
                                   }}
                                   placeholder="calibration factor"
                                   disabled={!props.editMode || rh.pin == 0}
                            />
                        </div>
                        <div className='col-6 d-flex pr-0  mb-2 align-items-center'>
                            <label className="fs-12 pr-3 mb-0">d</label>
                            <input type="text" className="form-control calibration" aria-describedby="emailHelp"
                                   name="calibration_d"
                                   value={rh.calibration.d}
                                   onChange={(e) => {
                                       updateSensor({calibration: {...props.sensor.calibration, d: e.target.value}})
                                   }}
                                   placeholder="calibration factor"
                                   disabled={!props.editMode || rh.pin == 0}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 col-12 mt-2">
                    <label>Sensor Alias</label>
                    <input type="text" className="form-control calibration" aria-describedby="alias"
                           name="alias"
                           value={rh.alias}
                           onChange={(e) => {
                               updateSensor({alias: e.target.value})
                           }}
                           placeholder="enter alias"
                           disabled={!props.editMode || rh.pin == 0}
                    />
                </div>
            </div>
        </div>

    return (
        <div id={`pin-config-list-item-${props.sensor.id}`}
             className={`pin-config-root border listItem mb-2 ${validate(props.sensor) ? '' : ' listItem-invalid'}`}>
            <div
                className={`col-12 ins_tab_head_bg d-flex ${validate(props.sensor) ? '' : 'text-color-white bg-dark'}`}>
                <label className="container-checkbox font-w-600 fs-18"
                       style={{
                           marginBottom: 4,
                           marginTop: 3
                       }}>Cabled RH : {props.connectionsCount ?? 0} connection(s)
                    <span className="checkmark mt-1 text-color-black">{props.sensor.pin}</span>
                </label>

                <div onClick={() => {
                    togglePopup()
                }}
                     className="checkmark port  mr-3 text-color-black" style={{marginTop: 5}}>
                    <i className="fa fa-trash-o fa-lg"/>
                    <PopConfirm show={deletePopup} hide={togglePopup}
                                action={() => props.onRemove(props.sensor, props.index)}/>
                </div>
                <span className="checkmark port  mr-5 text-color-black"
                      style={{marginTop: 5}}>{props.sensor.port}</span>
                <label className="port-label font-w-600 fs-14"
                       style={{
                           position: 'absolute',
                           right: '75px', paddingRight: 5, paddingTop: 5
                       }}>Port
                </label>
            </div>
            {renderForm(sensor)}
            {
                dirtyFlag ?
                    <div className="d-flex col-12 justify-content-center mb-2"><Button
                        type='dashed'
                        size='middle'
                        onClick={() => {
                            saveChanges()
                        }}
                        icon={<SaveOutlined/>}
                        disabled={!props.editMode}
                    > apply changes
                    </Button>
                    </div>
                    : <div className="d-flex col-12 justify-content-center mb-2"><Button
                        type='primary'
                        size='middle'
                        onClick={() => {
                            addRHSensor(props.sensor)
                        }}
                        icon={<PlusSquareOutlined/>}
                        disabled={!props.editMode}
                    > Add Cabled RH Sensor
                    </Button>
                    </div>
            }
        </div>)
}
export const ThermocoupleConfigForm = (props: any) => {
    const [deletePopup, setDeletePopup] = useState(() => false)
    const togglePopup = () => {
        props.editMode && setDeletePopup(prev => !prev)
    }
    const updateSensor = (field: any) => {
        props.onChange({...props.sensor, ...field},props.index)
    }
    const getSubtypes = () => {
        const subs = props.typesComponentsMapping[props.sensor.comp_type]?.find(x => x.type == props.sensor.type)?.subs?.filter(x => x.length > 0)
        return subs?.length > 0 ? subs : ['none']
    }
    const getTypes = (comp_type) => {
        // @ts-ignore
        const supportedTypes = SENSORS[props.sensorType];
        const types = props.typesComponentsMapping[comp_type]?.filter(x => supportedTypes.includes(x.type)).map(x => x.type)
        return types?.length > 0 ? types : ['select']
    }
    const getComponents = () => {
        // @ts-ignore
        const supportedTypes = SENSORS[props.sensorType];
        return Object.keys(props.typesComponentsMapping).map(key => {
            const types = props.typesComponentsMapping[key]?.filter(comp => supportedTypes.includes(comp.type))
            return types?.length > 0 ? key : ''
        }).filter(x => x.length > 0).sort() ?? ['select']
    }
    const renderForm = () => <div className="pl-4 pr-4">
        <div className="row mb-4">
            <div className="col-md-6 col-sm-6 col-12 mt-2">
                <label>Component </label>
                <Select name="ComponentSelect"
                        value={{_id: props.sensor.comp_type, name: props.sensor.comp_type}}
                        onChange={(value: any) => {
                            const config = {comp_type: value._id, type: 'select'}
                            if (Object.getOwnPropertyNames(props.sensor).includes('sub')) { // @ts-ignore
                                config['sub'] = 'select'
                            }
                            let types = getTypes(value._id)
                            if (types.length == 1) {
                                config['type'] = types[0]
                            }
                            updateSensor(config)
                        }}
                        data={getComponents().map(c => ({_id: c, name: c}))}
                        isDisabled={!props.editMode}
                        customStyles={highlightInvalidFieldValue}
                >
                </Select>
            </div>
            <div className="col-md-6 col-sm-6 col-12 mt-2">
                <label>Sequence Number </label>
                <Select name="SeqNumSelect"
                        value={{_id: props.sensor.seqNum, name: props.sensor.seqNum}}
                        onChange={(value: any) => {
                            const config = {seqNum: value._id}
                            updateSensor(config)
                        }}
                        data={[1, 2, 3, 4, 5].map((i) => ({_id: i, name: i}))}
                        isDisabled={!props.editMode}
                >
                </Select>
            </div>
            <div className="col-md-6 col-sm-6 col-12 mt-2">
                <label>type </label>
                <Select name="typeSelect"
                        value={{_id: props.sensor.type, name: props.sensor.type}}
                        onChange={(value: any) => {
                            const config = {type: value._id}
                            if (Object.getOwnPropertyNames(props.sensor).includes('sub')) { // @ts-ignore
                                config['sub'] = 'select'
                            }
                            updateSensor(config)
                        }}
                        data={getTypes(props.sensor.comp_type).map(c => ({_id: c, name: c}))}
                        isDisabled={!props.editMode}
                        customStyles={highlightInvalidFieldValue}
                >
                </Select>
            </div>

            {getSubtypes().length > 0 &&
                <div className="col-md-6 col-sm-6 col-12 mt-2">
                    <label>Sub </label>
                    <Select name="subtypeSelect"
                            value={{_id: props.sensor.sub, name: props.sensor.sub}}
                            onChange={(value: any) => {
                                updateSensor({sub: value._id})
                            }}
                            data={getSubtypes()?.map(c => ({
                                _id: c,
                                name: c
                            }))}
                            isDisabled={!props.editMode}
                            customStyles={highlightInvalidFieldValue}
                    >
                    </Select>
                </div>}
            <div className="col-md-6 col-sm-6 col-12 mt-2">
                <label className="fs-12">Valid Range</label>
                <div className='row pr-3'>
                    <div className='col-6 d-flex pr-0  mb-2 align-items-center'>
                        <input type="text" className="form-control min-max" aria-describedby="min"
                               name="min"
                               value={props.sensor.min ?? ''}
                               onChange={(e) => {
                                   updateSensor({min: e.target.value})
                               }}
                               disabled={!props.editMode}
                               maxLength={8}
                               placeholder="enter min value"/>
                    </div>
                    <div className='col-6 d-flex pr-0 mb-2 align-items-center'>
                        <input type="text" className="form-control min-max" aria-describedby="max"
                               name="max"
                               value={props.sensor.max ?? ''}
                               onChange={(e) => {
                                   updateSensor({max: e.target.value})
                               }}
                               disabled={!props.editMode}
                               maxLength={8}
                               placeholder="enter max value"/>
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-sm-6 col-12 mt-2">
                <label className="fs-12">Calibration</label>
                <div className='row m-0'>
                    <div className='col-6 d-flex pr-0  mb-2 align-items-center'>
                        <label className="fs-12 pr-3 mb-0">a</label>
                        <input type="text" className="form-control calibration" aria-describedby="emailHelp"
                               name="calibration_a"
                               value={props.sensor.calibration.a}
                               onChange={(e) => {
                                   updateSensor({calibration: {...props.sensor.calibration, a: e.target.value}})

                               }}
                               disabled={!props.editMode}
                               placeholder="calibration factor"/>
                    </div>
                    <div className='col-6 d-flex pr-0 mb-2 align-items-center'>
                        <label className="fs-12 pr-3 mb-0">b</label>
                        <input type="text" className="form-control calibration" aria-describedby="emailHelp"
                               name="calibration_b"
                               value={props.sensor.calibration.b}
                               onChange={(e) => {
                                   updateSensor({calibration: {...props.sensor.calibration, b: e.target.value}})

                               }}
                               disabled={!props.editMode}
                               placeholder="calibration factor"/>
                    </div>
                    <div className='col-6 d-flex pr-0  mb-2 align-items-center'>
                        <label className="fs-12 pr-3 mb-0">c</label>
                        <input type="text" className="form-control calibration" aria-describedby="emailHelp"
                               name="calibration_c"
                               value={props.sensor.calibration.c}
                               onChange={(e) => {
                                   updateSensor({calibration: {...props.sensor.calibration, c: e.target.value}})

                               }}
                               disabled={!props.editMode}
                               placeholder="calibration factor"/>
                    </div>
                    <div className='col-6 d-flex pr-0  mb-2 align-items-center'>
                        <label className="fs-12 pr-3 mb-0">d</label>
                        <input type="text" className="form-control calibration" aria-describedby="emailHelp"
                               name="calibration_d"
                               value={props.sensor.calibration.d}
                               onChange={(e) => {
                                   updateSensor({calibration: {...props.sensor.calibration, d: e.target.value}})
                               }}
                               disabled={!props.editMode}
                               placeholder="calibration factor"/>
                    </div>
                </div>
            </div>
            <div className="col-md-6 col-sm-6 col-12 mt-2">
                <label>Sensor Alias</label>
                <input type="text" className="form-control calibration" aria-describedby="alias"
                       name="alias"
                       value={props.sensor.alias}
                       onChange={(e) => {
                           updateSensor({alias: e.target.value})
                       }}
                       disabled={!props.editMode}
                       placeholder="enter alias"/>
            </div>
        </div>
    </div>
    return (<div id={`pin-config-list-item-obs-${props.sensor.port}-${props.sensor.pin}`}
             className={`pin-config-root border listItem mb-2  ${validate(props.sensor) ? '' : 'mb-3 listItem-invalid'}`}>
            <div
                className={`col-12 ins_tab_head_bg d-flex text-color-white  ${validate(props.sensor) ? 'thermocouple' : 'bg-dark'}`}>
                <label className="container-checkbox font-w-600 fs-18"
                       style={{marginBottom: 4, marginTop: 3}}>
                    {props.sensor.comp_type !== 'select' ? `${props.sensor.alias.length > 0 ? props.sensor.alias :
                        props.sensor.comp_type} :#${props.sensor.seqNum ?? 1}` : 'Select Component'}
                    <input type="checkbox"
                           className="chk-box"
                           disabled={!props.editMode}
                           name={props.sensor.comp_type}
                           checked={true}
                    />
                </label>
                <div onClick={() => {
                    togglePopup()
                }}
                     className="checkmark port  mr-3 text-color-black" style={{marginTop: 5}}>
                    <i className="fa fa-trash-o fa-lg"/>
                    <PopConfirm show={deletePopup} hide={togglePopup} action={() => props.onRemove(props.sensor)}/>
                </div>
            </div>
            {renderForm()}
        </div>)
}
export const OnboardRHConfigForm = (props: any) => {
    const [deletePopup, setDeletePopup] = useState(() => false)
    const togglePopup = () => {
        props.editMode && setDeletePopup(prev => !prev)
    }
    const updateSensor = (field: any) => {
        props.onChange({...props.sensor, ...field},props.index)
    }
    const getSubtypes = (comp_type, type) => {
        const subs = props.typesComponentsMapping[comp_type]?.find(x => x.type == type)?.subs?.filter(x => x.length > 0)
        return subs?.length > 0 ? subs : ['none']
    }
    const getTypes = (comp_type) => {
        // @ts-ignore
        const supportedTypes = SENSORS[props.sensorType] ?? [];
        const types = props.typesComponentsMapping[comp_type]?.filter(x => supportedTypes.includes(x.type)).map(x => x.type)
        return types?.length > 0 ? types : ['select']
    }
    const getComponents = (sensorType: string) => {
        // @ts-ignore
        const supportedTypes = SENSORS[sensorType] ?? [];
        return Object.keys(props.typesComponentsMapping).map(key => {
            const types = props.typesComponentsMapping[key]?.filter(comp => supportedTypes.includes(comp.type))
            return types?.length > 0 ? key : ''
        }).filter(x => x.length > 0).sort() ?? ['select']
    }
    const renderForm = (rh: any,sensorType:string) =>
        <div className="pl-4 pr-4">
            <div className="row mb-4">
                <div className="col-md-6 col-sm-6 col-12 mt-2">
                    <label>Component </label>
                    <Select name="ComponentSelect"
                            value={{_id: rh.comp_type, name: rh.comp_type}}
                            onChange={(value: any) => {
                                const config = {comp_type: value._id, type: 'select', sub: 'select'}
                                let types = getTypes(value._id)
                                if (types.length == 1) {
                                    config['type'] = types[0]
                                }
                                updateSensor(config)
                            }}
                            data={getComponents(sensorType).map(c => ({_id: c, name: c}))}
                            isDisabled={!props.editMode}
                            customStyles={highlightInvalidFieldValue}
                    />
                </div>
                <div className="col-md-6 col-sm-6 col-12 mt-2">
                    <label>Sequence Number </label>
                    <Select name="SeqNumSelect"
                            value={{_id: rh.seqNum, name: rh.seqNum}}
                            onChange={(value: any) => {
                                const config = {seqNum: value._id}
                                updateSensor(config)
                            }}
                            data={[1, 2, 3, 4, 5].map((i) => ({_id: i, name: i}))}
                            isDisabled={!props.editMode }
                    />
                </div>
                <div className="col-md-6 col-sm-6 col-12 mt-2">
                    <label>Type</label>
                    <Select name="typeSelect"
                            value={{_id: rh.type, name: rh.type}}
                            onChange={(value: any) => {
                                const config = { type: value._id, sensing_element: SENSING_ELEMENT[value._id] }
                                if (Object.getOwnPropertyNames(props.sensor).includes('sub')) { // @ts-ignore
                                    config['sub'] = 'select'
                                }
                                updateSensor(config)
                            }}
                            data={getTypes(rh.comp_type).map(c => ({_id: c, name: c}))}
                            customStyles={highlightInvalidFieldValue}
                            isDisabled={!props.editMode }
                    />
                </div>
                <div className="col-md-6 col-sm-6 col-12 mt-2">
                    <label>Sub </label>
                    <Select name="subtypeSelect"
                            value={{_id: rh.sub, name: rh.sub}}
                            onChange={(value: any) => {
                                updateSensor({sub: value._id})
                            }}
                            data={getSubtypes(rh.comp_type, rh.type)?.map(c => ({
                                _id: c,
                                name: c
                            }))}
                            customStyles={highlightInvalidFieldValue}
                            isDisabled={!props.editMode}
                    />
                </div>
                <div className="col-md-6 col-sm-6 col-12 mt-2">
                    <label className="fs-12">Valid Range</label>
                    <div className='row pr-3'>
                        <div className='col-6 d-flex pr-0  mb-2 align-items-center'>
                            <input type="text" className="form-control min-max" aria-describedby="min"
                                   name="min"
                                   value={rh.min ?? ''}
                                   onChange={(e) => {
                                       updateSensor({min: e.target.value})
                                   }}
                                   maxLength={8}
                                   placeholder="enter min value"
                                   disabled={!props.editMode }
                            />
                        </div>
                        <div className='col-6 d-flex pr-0 mb-2 align-items-center'>
                            <input type="text" className="form-control min-max" aria-describedby="max"
                                   name="max"
                                   value={rh.max ?? ''}
                                   onChange={(e) => {
                                       updateSensor({max: e.target.value})
                                   }}
                                   maxLength={8}
                                   placeholder="enter max value"
                                   disabled={!props.editMode }
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 col-12 mt-2">
                    <label className="fs-12">Calibration</label>
                    <div className='row m-0'>
                        <div className='col-6 d-flex pr-0  mb-2 align-items-center'>
                            <label className="fs-12 pr-3 mb-0">a</label>
                            <input type="text" className="form-control calibration" aria-describedby="emailHelp"
                                   name="calibration_a"
                                   value={rh.calibration.a}
                                   onChange={(e) => {
                                       updateSensor({calibration: {...props.sensor.calibration, a: e.target.value}})
                                   }}
                                   placeholder="calibration factor"
                                   disabled={!props.editMode }
                            />
                        </div>
                        <div className='col-6 d-flex pr-0 mb-2 align-items-center'>
                            <label className="fs-12 pr-3 mb-0">b</label>
                            <input type="text" className="form-control calibration" aria-describedby="emailHelp"
                                   name="calibration_b"
                                   value={rh.calibration.b}
                                   onChange={(e) => {
                                       updateSensor({calibration: {...props.sensor.calibration, b: e.target.value}})
                                   }}
                                   placeholder="calibration factor"
                                   disabled={!props.editMode}
                            />
                        </div>
                        <div className='col-6 d-flex pr-0  mb-2 align-items-center'>
                            <label className="fs-12 pr-3 mb-0">c</label>
                            <input type="text" className="form-control calibration" aria-describedby="emailHelp"
                                   name="calibration_c"
                                   value={rh.calibration.c}
                                   onChange={(e) => {
                                       updateSensor({calibration: {...props.sensor.calibration, c: e.target.value}})
                                   }}
                                   placeholder="calibration factor"
                                   disabled={!props.editMode }
                            />
                        </div>
                        <div className='col-6 d-flex pr-0  mb-2 align-items-center'>
                            <label className="fs-12 pr-3 mb-0">d</label>
                            <input type="text" className="form-control calibration" aria-describedby="emailHelp"
                                   name="calibration_d"
                                   value={rh.calibration.d}
                                   onChange={(e) => {
                                       updateSensor({calibration: {...props.sensor.calibration, d: e.target.value}})
                                   }}
                                   placeholder="calibration factor"
                                   disabled={!props.editMode}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 col-12 mt-2">
                    <label>Sensor Alias</label>
                    <input type="text" className="form-control calibration" aria-describedby="alias"
                           name="alias"
                           value={rh.alias}
                           onChange={(e) => {
                               updateSensor({alias: e.target.value})
                           }}
                           placeholder="enter alias"
                           disabled={!props.editMode}
                    />
                </div>
            </div>
        </div>
    const getSensorName = (pin) => {
        let name
        switch (pin){
            case 1 : name = 'OBS_VOC';break;
            case 2 : name = 'OBS_RH1';break;
            case 3 : name = 'OBS_RH2';break;
        }
        return name
    }
    return (
        <div id={`pin-config-list-item-obs-${props.sensor.port}-${props.sensor.pin}`}
             className={`pin-config-root border listItem mb-2 mb-3`}>
            <div className={`col-12 ins_tab_head_bg d-flex text-color-white on-board-sensor`}>
                <label className="container-checkbox font-w-600 fs-18"
                       style={{
                           marginBottom: 4,
                           marginTop: 3,
                           left: 2
                       }}>{getSensorName(props.sensor.pin)} : {props.sensor.comp_type !== 'select' ?
                    `${props.sensor.alias.length > 0 ? props.sensor.alias :
                        props.sensor.comp_type} :#${props.sensor.seqNum ?? 1}` : 'Select Component'}
                </label>

                <div onClick={() => { togglePopup() }}
                     className="checkmark port  mr-3 text-color-black" style={{marginTop: 5}}>
                    <i className="fa fa-trash-o fa-lg"/>
                    <PopConfirm show={deletePopup} hide={togglePopup} action={() => props.onRemove(props.sensor,props.index)}/>
                </div>
            </div>
            {
                renderForm(props.sensor,props.sensor.pin!=1?props.sensorType:'voc')
            }
        </div>)
}




